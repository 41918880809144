import { Directive, ElementRef , HostListener, Input } from '@angular/core';
import {UserService} from '../_services/user.service';
import { Router} from '@angular/router';
import { environment } from '../../environments/environment';
import { TextSetting } from '../textsetting';

declare var $:any;	
declare var bootbox:any;

@Directive({
	selector: '[appShortlist]',
	// providers: [UserService]
})
export class ShortlistDirective {
	@Input('currentIndex') currentIndex: string;
	@Input('shortlistUserSlug') shortlistUserSlug: string;
	@Input('userDetail') userDetail: any;
	@Input('pageName') pageName: any;
	
	TextSetting 						=   TextSetting;

constructor(private el: ElementRef,public router: Router,public userService: UserService) { }
  

@HostListener('click') onClick() {
	this.shortlistMember();
}


	/**This function are used for shortlist the  **/
	private shortlistMember() {
		let userSlug	=	this.userService.getCurrentUser().slug;
		let that	=	this;
		bootbox.confirm(that.TextSetting.SHORTLIST_MEMBER_MESSAGE,
			function(result){
				if(result){
					blockUI();
					that.userService.shortListMember(userSlug,that.shortlistUserSlug).then((res:any)=>{
						if(res.data.success == true){
							showSuccessMessageTopRight(res.data.message);
							if(that.pageName = 'user_page'){
								that.userDetail[that.currentIndex]['short_listed']	=	1;
							}else{
								that.userDetail['data'][that.currentIndex]['short_listed']	=	1;
							}
						}else{
							showErrorMessageTopRight(res.data.message);
						}
					});
					unblockUI();
				}
			}
		);
	}
}
