import { Directive, ElementRef , HostListener, Input } from '@angular/core';
import { Router} from '@angular/router';

declare var $:any;	
declare var bootbox:any;

@Directive({
	selector: '[appInvitemember]',
})
export class InvitememberDirective {

	@Input('currentIndex') currentIndex: string;
	@Input('pageName') pageName: any;
	@Input('inviteMemberSlug') inviteMemberSlug: string;
	@Input() invitedMemberIndex: string;
	@Input() invitedMemberSlug: string;

constructor(private el: ElementRef,public router: Router) { }
  

@HostListener('click') onClick() {
	this.inviteMember();
}

	public inviteMember(){
		if(this.pageName	==	'user_page'){
			this.invitedMemberIndex	=	this.currentIndex;
			this.invitedMemberSlug	=	this.inviteMemberSlug;
			$('#invitationPopup').modal('show');
			$('.invitedMemberSlug').val(this.invitedMemberSlug);
		}else{
			this.invitedMemberSlug	=	this.inviteMemberSlug;
			$('#invitationPopup').modal('show');
			$('.invitedMemberSlug').val(this.invitedMemberSlug);
		}
	}//inviteMember();

}
