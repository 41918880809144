import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidationService } from '../../../../_services/index';
import {CommonFunctionsService} from '../../../../_services/index';
import { environment } from '../../../../../environments/environment';
import { UserService } from "../../../../_services/user.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import 'rxjs/add/operator/switchMap';
import { NewUserProfileDesktopComponent } from "../../../userprofile/newuserprofile.component"

declare var bootbox : any;

@Component({
    selector: 'app-photo-protect-password',
    templateUrl: './photo-protect-password.component.html'
})

export class PhotoProtectPasswordComponent implements OnInit, OnDestroy{

   private ngUnsubscribe: Subject<void> = new Subject<void>();	

    photoProtectForm   : FormGroup;
    photoProtectError  : any = [];
    accessToPhotoUsers : any = [];
    env                = environment;
    SITE_IMAGE_URL     :   string = "";

    @Input() requestedUserSlug : any = '';
    @Input() currentUserSlug   : any = '';
    @Output() acessToUserPhotoList = new EventEmitter();
    @Output() resendPasswordRequest = new EventEmitter()

    constructor(
        private formBuilder : FormBuilder,
        private commonFunctionServices : CommonFunctionsService,
        private userService : UserService
        ) {
        this.SITE_IMAGE_URL = this.env.SITE_IMAGE_URL;
    }

    ngOnInit(): void {
        this.createProtectPasswordForm();
    }

    public createProtectPasswordForm(){
        this.photoProtectForm = this.formBuilder.group({
            photo_password : ['',Validators.required]
        })
    }
    
    public submitProtectPhotoForm(){
        this.commonFunctionServices.isBrowser.subscribe((isBrowser)=>{
            if(isBrowser){
                if(this.photoProtectForm.valid){
					this.photoProtectForm.value.requested_user_slug			=	this.requestedUserSlug;
					this.photoProtectForm.value.current_user_slug			=	this.currentUserSlug;
					this.userService.submitPhotoAccessPassword(this.photoProtectForm.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe( (res:any) => {
                        if(res.data.success){
                            this.photoProtectForm.reset();
                            $('#photo_protect_password_modal').modal('hide');
                            this.accessToPhotoUsers = []
                           // this.accessToPhotoUsers = localStorage.getItem('accessToPhotoUsers');
                            if(localStorage.getItem('accessToPhotoUsers')== null ){
                                this.accessToPhotoUsers.push(this.requestedUserSlug);
                                console.log(this.accessToPhotoUsers)
                                localStorage.setItem('accessToPhotoUsers', JSON.stringify(this.accessToPhotoUsers));
                            }else{
                                this.accessToPhotoUsers = JSON.parse(localStorage.getItem('accessToPhotoUsers'));
                                if(!this.accessToPhotoUsers.includes(this.requestedUserSlug)){
                                    this.accessToPhotoUsers.push(this.requestedUserSlug);
                                    localStorage.setItem('accessToPhotoUsers', JSON.stringify(this.accessToPhotoUsers));
                                }
                            }
                            this.acessToUserPhotoList.emit();
                        }else{
                            this.photoProtectError = res.data.errors;
                        }
                    })
                }else{
                    ValidationService.validateAllFormFields(this.photoProtectForm);
                }
            }
        })
    }

    public resentPhotoPasswordRequest(){
	this.commonFunctionServices.isBrowser.subscribe((isBrowser)=>{
            if(isBrowser){
				$('#photo_protect_password_modal').modal('hide');
				bootbox.confirm('Are you sure you want to resend the request for photo access?',(result)=>{
				if(result){
					let that	=	this;
					that.userService.forgotProtectPhotoPassword(this.currentUserSlug,this.requestedUserSlug).then((res:any)=>{
						if(res.data.success == true){
							showSuccessMessageTopRight(res.data.message);
							that.resendPasswordRequest.emit();
						}else{
							showErrorMessageTopRight(res.data.message);
						}
					});
				}else{
					$('#photo_protect_password_modal').modal('show');
				}
			})
		  }
        })
    }


	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}