import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { Select2Module } from 'ng2-select2';
import {Ng2TelInputModule} from 'ng2-tel-input';
import {
	   HeaderComponent,
	   FooterComponent,
	   LoginComponent,
	   ForgotComponent,
	   ResetpasswordComponent,
	   SignupComponent,
	   EmailSubscribeForm,
	   HomePageSearchComponent,
	   DashboardQuickSearchComponent,
	   VerificationComponent,
	   AdvertisementComponent,
	   MobileVerificationComponent,
	   RecentUpdateComponent,
	   UnlockTrustComponent,
	   SuccessStoryComponent,
	   RightNewMatchesComponent,
	   MatchResultComponent,
	   ChatComponent,
	   EditMobileVerificationComponent
} from './layout/index';

import { ControlMessagesComponent } from '../../control-messages.component';
import { KeysPipe,DisplayDatePipe,ReverseKeysPipe,DisplayPricePipe,SafeUrlPipe,SafeHtmlPipe } from '../../_pipes/index';
import { LogoutDirective } from '../../directive/logout.directive';
import { ShortlistDirective } from '../../directive/shortlist.directive';
import { RejectinvitationDirective } from '../../directive/rejectinvitation.directive';
import { RemoveinvitationDirective } from '../../directive/removeinvitation.directive';
import { RestoreinvitationDirective } from '../../directive/restoreinvitation.directive';
import { RejectrequestDirective } from '../../directive/rejectrequest.directive';
import { InvitememberDirective } from '../../directive/invitemember.directive';
import { RejectmemberDirective } from '../../directive/rejectmember.directive';
import { UnblockmemberDirective } from '../../directive/unblockmember.directive';
import { PhotoProtectPasswordComponent } from "./layout/elements/photo-protect-password.component"

/***********layout routing ************/
 const layoutRouting = RouterModule.forChild([
	{
		path: 'account-verification/:validatestring',
		component: VerificationComponent
   	}
 ]);

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule,
	Select2Module,
	Ng2TelInputModule,
	layoutRouting
	
  ],
  declarations: [
	  HeaderComponent,
	  FooterComponent,
	  EmailSubscribeForm,
	  ControlMessagesComponent,
	  HomePageSearchComponent,
	  DashboardQuickSearchComponent,
	  LoginComponent,
	  ForgotComponent,
	  SignupComponent,
	  ResetpasswordComponent,
	  VerificationComponent,
	  MobileVerificationComponent,
	  RecentUpdateComponent,
	  UnlockTrustComponent,
	  SuccessStoryComponent,
	  RightNewMatchesComponent,
	  KeysPipe,
	  DisplayDatePipe,
	  ReverseKeysPipe,
	  DisplayPricePipe,
	  LogoutDirective,
	  SafeUrlPipe,
	  SafeHtmlPipe,
	  AdvertisementComponent,
	  ShortlistDirective,
	  RejectinvitationDirective,
	  RemoveinvitationDirective,
	  RestoreinvitationDirective,
	  RejectrequestDirective,
	  InvitememberDirective,
	  RejectmemberDirective,
	  UnblockmemberDirective,
	  MatchResultComponent,
	  ChatComponent,
	  EditMobileVerificationComponent,
	  PhotoProtectPasswordComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule,
    HeaderComponent,
    FooterComponent,
	LoginComponent,
	ForgotComponent,
	ResetpasswordComponent,
	EmailSubscribeForm,
	ControlMessagesComponent,
	HomePageSearchComponent,
	DashboardQuickSearchComponent,
	SignupComponent,
	VerificationComponent,
	MobileVerificationComponent,
	RecentUpdateComponent,
	UnlockTrustComponent,
	SuccessStoryComponent,
	RightNewMatchesComponent,
	LogoutDirective,
	KeysPipe,
	DisplayDatePipe,
	ReverseKeysPipe,
	DisplayPricePipe,
	SafeUrlPipe,
	SafeHtmlPipe,
	AdvertisementComponent,
	ShortlistDirective,
	RejectinvitationDirective,
	RemoveinvitationDirective,
	RestoreinvitationDirective,
	RejectrequestDirective,
	InvitememberDirective,
	RejectmemberDirective,
	UnblockmemberDirective,
	MatchResultComponent,
	ChatComponent,
	EditMobileVerificationComponent,
	PhotoProtectPasswordComponent
  ],
  providers: [],
})
export class SharedModule { }
