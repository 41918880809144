import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router,ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HomeService } from './service/home.service';
import { SeoService } from '../../_services/seo.service';
import {CommonFunctionsService} from '../../_services/index';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TextSetting } from '../../textsetting';
declare var $:any;

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
	providers: [HomeService,CommonFunctionsService,SeoService]
})

export class HomeComponent implements OnInit, OnDestroy {

    /**This is used to prevent memory leaks **/
		private ngUnsubscribe: Subject<void> = new Subject<void>();	
	/**This is used to prevent memory leaks **/
  
    env						=	environment;
	SITE_IMAGE_URL			:   string = "";
	TextSetting 			=   TextSetting;
	homeBlockDataStatus		:	any			=	'';
	homeBlockData			:	any			=	{};
	SUCCESS_CONSTANT  		:   any		=	'';
	ERROR_CONSTANT			:	any		=	'';
	validateString			:	string  =	'';
	successStroyData		:	any 	=	[];
	sliderData				:	any		=	[];
	seoData					:	any		=	[];
	seoDataStatus			:	any		=	'';
	footerBlockDataForSeo	 :	 any		=	"";
	footerBlockDataSeoStatus :   any		=	"";
	
	
	constructor(public HomeService: HomeService, private commonFunctionsService:CommonFunctionsService,private seo: SeoService,public router: Router,private route: ActivatedRoute,) {
		this.SITE_IMAGE_URL = this.env.SITE_IMAGE_URL;
		this.SUCCESS_CONSTANT = this.env.SUCCESS_CONSTANT;
		this.ERROR_CONSTANT = this.env.ERROR_CONSTANT;
    }

	ngOnInit() {
			
			/**This function are used for set the title of home page **/
			this.commonFunctionsService.getSocialLinksUrl().then((res: any) => {
					this.seoDataStatus		=	res.data.status;
					if(this.seoDataStatus == this.SUCCESS_CONSTANT){	
						this.seoData		=	res.data.data;
						/**This function are used for set the title of home page **/
						this.seo.generateTags({
							title: this.seoData.home_page_title,
							description: this.seoData.home_page_description,
						});
					}
			});
			
		    this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
				if(isBrowser){
				let that	=	this;
				
				let scroled=0;
				
			    /**This function are used for get blocks for how it works **/
				 this.getHowItWorksBlock('how-it-works');	
				 this.getFooterBlockDataForSEO('footer-block-for-seo');
				 
				 
                this.validateString = this.route.snapshot.params['validatestring'];
                if(this.validateString != undefined){
                    blockUI();
                    setTimeout(function(){
                        unblockUI();
                        $("#validate_string_val").val(that.validateString)
                        $("#reset_password").modal('show');
                    },1000);
				 }
				 

			   /* This Webservice are used for fetch the data of success Story */
				 $( window ).scroll(function() {
					if(scroled==0){
					  that.HomeService.successStroyFetchData().then((res: any) => {
							that.successStroyData = res.data;
								setTimeout(()=>{
									if($('.item').length > 0)
									{
										that.getFacility();
									}
								},1000);
								unblockUI();
						});
						/**This function are used for get blocks for how it works **/
							
						scroled += 1;
					}
				 });
				 
				/**This function ae used for get the slider **/
				this.getSlider();
				
				}
		    });
	}
	

	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
	}
	
	/**This function are used for get the facility slider**/
	public getFacility(){
	  let  owl = $('.facility');
		  owl.owlCarousel({
			margin:130,
			loop: true,
			autoplay:false,
			center:true,
			nav:true,
			responsive: {
			  0: {
				items: 1
			  },
			  640: {
				items: 1
			  },
			  768: {
				items: 3
			  },
			  992: {
				items:3
			  },
			   1024: {
				items: 3
			  }
			   
			}
		  });
	}
	
	
	/**This function are used for get blocks for how it works**/
	public getHowItWorksBlock(slug){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
				   this.commonFunctionsService.getBlock(slug).then((res: any) => {
					   this.homeBlockDataStatus		=	res.data.status;
					   if(this.homeBlockDataStatus == this.SUCCESS_CONSTANT){	
						   this.homeBlockData		=	res.data.data;
						}else{
						   showSuccessCenter(res.data.message);
						   this.router.navigate(['/']);
					   }
				   });
			   }
		});
	}
	
	
     /**function used to get footer block data from Api */
	public getFooterBlockDataForSEO(slug) {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
				  
				   this.commonFunctionsService.getBlock(slug).then((res: any) => {
					   this.footerBlockDataSeoStatus		=	res.data.status;
					   if(this.footerBlockDataSeoStatus == this.SUCCESS_CONSTANT){	
						   this.footerBlockDataForSeo		=	res.data.data;
					  }else{
						   showSuccessCenter(res.data.message);
						   this.router.navigate(['/']);
					   }
				   });
			   }
		});
   }

   /**This function are used for get the slider **/
   public getSlider(){
	   this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
				   this.HomeService.getSliderData().then((res: any) => {	
						   this.sliderData		=	res.data; 
						   console.log(this.sliderData); 	
					});
			   }
		});
   }
		
	
	
	
 }	

