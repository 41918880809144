import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService } from '../../../../_services/index';
import { UserService } from '../../../../_services/user.service';
import {GlobalApp} from '../../../../_helpers/index';
import { isPlatformBrowser } from '@angular/common';
import { TextSetting } from '../../../../textsetting';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SeoService } from '../../../../_services/seo.service';
import {CommonFunctionsService} from '../../../../_services/index';
declare var $:any;


@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  providers : [SeoService,CommonFunctionsService,ValidationService,UserService]
})
export class ForgotComponent implements OnInit,OnDestroy {

   private ngUnsubscribe:Subject<any> = new Subject();	
	
 /*Dclear the variable constant */
	forgotForm						:	FormGroup;	
	ForgotError					:	any		=	{};
	forgotFormStatus			:	any		=	{};
	ForgotFormErrorMsg			:	any		=	{};
	forgotFormSuccessMessage	:	any		=	{};
	SITE_IMAGE_URL				:   string 	= 	"";
	env							=	environment;	
	
	
	constructor(private route: ActivatedRoute,public router: Router,private formBuilder: FormBuilder,public commonFunctionsService:CommonFunctionsService) { 
			this.SITE_IMAGE_URL 	= 	this.env.SITE_IMAGE_URL;
	}

	 

	 ngOnInit() {
			/**This code are used for create forgot form **/
			this.createForgotForm();
	  }
	
	/**This code are used for create forgot form **/
	  public createForgotForm(){
			/*********** This is used for validate the login form ************/	
			this.forgotForm			=	this.formBuilder.group({
				'email'  		: 	[null, [Validators.required, Validators.email]],
			})	
	  }
  
  
  ngOnDestroy() {
        this.ngUnsubscribe.next();
   }
  
  /**function for submit forgot password*/
	submitForgotForm() {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				if (this.forgotForm.valid) {
				this.commonFunctionsService.forgotPassword(this.forgotForm.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {	  
					this.forgotFormStatus	= res.data.status;
					if(this.forgotFormStatus == this.env.SUCCESS_CONSTANT)
					{
						this.ForgotError			=	'';
						this.forgotForm.reset();
						this.forgotFormSuccessMessage		=	res.data.message;
						$('body').removeClass('modal-open');
						$('#forgot').modal('hide');
						showSuccessMessageTopRight(this.forgotFormSuccessMessage);
					}else{
						this.ForgotError 	=  res.data.errors;
						if(this.ForgotError == 'errors'){
							this.ForgotError			=	'';
							this.forgotForm.reset();
							this.ForgotFormErrorMsg	= 	res.data.message;
							showErrorMessageTopRight(this.ForgotFormErrorMsg);
							$('body').removeClass('modal-open');
							$('#forgot').modal('hide');
						}
					}
					unblockUI();
				});
				} else {
					unblockUI();
					ValidationService.validateAllFormFields(this.forgotForm);
				}	
			}
		});
		
	}//end submitForgotForm()
	
	
	
	/*This function are used for off the login popup */
	clickToOff(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
					this.router.navigate(['/']);	
					$('#forgot').modal('hide');
			}
		});
	}

}
