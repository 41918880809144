import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { isPlatformBrowser , isPlatformServer} from '@angular/common';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fromEvent} from 'rxjs/observable/fromEvent';
import { merge } from 'rxjs/observable/merge';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { mapTo } from 'rxjs/operators';
import {CommonFunctionsService} from '../../../../_services/index';
import { UserService } from '../../../../_services/user.service';
import { SeoService } from '../../../../_services/seo.service';
import { NotificationService } from '../../../notification/service/notification.service';
import { TextSetting } from '../../../../textsetting';
declare var $:any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [CommonFunctionsService,UserService,SeoService,NotificationService]
})
export class HeaderComponent implements OnInit, OnDestroy {
	
	env						=	environment;
	TextSetting 			=   TextSetting;

	SITE_IMAGE_URL				:	any		=	'';
	online$						:   Observable<boolean>;
	userData					:	any		=	[]; 
	photoRequest				:	number	=	0;
	photoRequestAccepted		:	number	=	0;
	photoRequestRejected		:	number	=	0;
	invitationRequestAccepted	:	number	=	0;
	invitationRequestRejected	:	number	=	0;
	invitationRequestReceived	:	number	=	0;
	profileViewed				:	number	=	0;
	shortListedUser				:	number	=	0;
	totalNotification			:	number	=	0;
	specialSearchDataArray		:	any		=	[];

	/**This is used to prevent memory leaks **/
	private ngUnsubscribe: Subject<void> = new Subject<void>();	
	/**This is used to prevent memory leaks **/
	
	constructor(public router: Router, private commonFunctionsService:CommonFunctionsService,public userService:UserService,private seo: SeoService,public NotificationService : NotificationService) {
			 this.SITE_IMAGE_URL = this.env.SITE_IMAGE_URL;
			 this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
				if(isBrowser){
					this.online$ = merge(
						of(navigator.onLine),
						fromEvent(window, 'online').pipe(mapTo(true)),
						fromEvent(window, 'offline').pipe(mapTo(false))
					)
				   this.networkStatus()
				}
			});
	}
	
 
  	ngOnInit() {
		
		 this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
					let that		=	this;
					setTimeout(function(){
							$(".data-img-bg").css('background', function () {
								let bg = ('url(' + $(this).data("image-src") + ') no-repeat');
								return bg;
							});
					},1500);
					
					 $(window).on("load",function(){
						$(".content").mCustomScrollbar();
					 });
					
					$(document).on('click','.closeinfo',function(){
						$(".info_txt").removeClass("open");
						$(".info_txt3").removeClass("open");
						$(".info_txt2").removeClass("open");
					
					});
					$(document).on('click','.navbar-toggler',function(){
						$("body").addClass("menu-open");
					});
					
					$(document).on('click','#closemenu',function(){
						$("body").removeClass("menu-open");
						$("#navbarSupportedContent").removeClass("show");
					});
					
					$(document).on('click','#menu-toggle',function(){
						$("#home-page-search-popup").modal('show');
					});
					
					
					$(window).resize(function() { 
						setTimeout(function(){
							that.resizeequalheight();
						},250);
					});
					setTimeout(function(){
						that.resizeequalheight();
					},250);
				
				
				if(this.userService.getCurrentUser() != 'undefined' && this.userService.getCurrentUser() != null){
						this.getLoginUserData();
						/**This function are used for get the notification counter **/
						this.getNotificationsCounter();
				}
				$(document).on('click','#userDataUpdated',function(){
					that.getLoginUserData();
					that.getNotificationsCounter();
				});
				
				/*function is used for logout user*/
				$(document).on('click','#logout_user',function(){
						that.logoutForcefully();
				});
				
				
			}
		});
	}
	
	 /**This function are used for show the message when network connection is closed **/
   public networkStatus() {
	  this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
				if(isBrowser){
					this.online$.subscribe(value => {
								if(value == false){
									this.networkErrorMessage(this.env.NETWORK_ERROR);
								}else{
									$('.noty_cont').remove();
								}
					})
				}
			});
	}
	
	/**This function are used for check special case api on header **/
	public specialCase(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				let specialArray				=	{slug:this.userData.slug,physical_status:1,search_key:'special_case'};
				this.specialSearchDataArray		=	specialArray; 
				this.router.navigate(['/search-result'],{ queryParams: this.specialSearchDataArray}); 
			}
		});
	}
	
	
	
	/**This function are used for show the message when network connection is closed **/
	public networkErrorMessage(msg){
		noty({
			layout : 'topRight', 
			theme : 'noty_theme_default', 
			type : 'warning',     
			text: msg ,     
			timeout : false,
			closeWith : ['button'],
			closeOnSelfClick: false,
			closeButton:false,
			animation : {
				easing: 'swing',
				speed: 150 // opening & closing animation speed
			}		
		});
	}
	
	/**This function are used for set the resize equal height**/
	public resizeequalheight(){
			this.equalHeight($(".makeequal")); 
			this.equalHeight($(".makeequal1")); 
	} 
	
	
	/**This function are used for set the resize equal height**/
	public equalHeight(group) 
	{ 	
		let tallest = 0; 
		group.height('');
		group.each(function() 
		{ 
			let thisHeight = $(this).height(); 
				if(thisHeight > tallest) { 
					tallest = thisHeight; 
					} 
		}); 
		group.height(tallest); 
	}
	
	
	  
  	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
	}
	
	/**This function are used for get the login user data detail **/
	public getLoginUserData(){
		this.userData				=	this.userService.getCurrentUser();
	}
	
	
	/** function are use to tab wise accourding title change */
	public tabTitle(titleName){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				/**This function are used for set the title of story page **/
				if(titleName=='account_setting'){
					this.seo.generateTags({
						title: this.TextSetting.ACCOUNT_SETTING_PAGE_TITLE
					});
				}else if(titleName=='contact_filter'){
					this.seo.generateTags({
						title: this.TextSetting.CONTACT_FILTER_PAGE_TITLE
					});
				}else if(titleName=='email_alert'){
					this.seo.generateTags({
						title: this.TextSetting.EMAIL_ALERT_PAGE_TITLE
					});
				}else if(titleName=='photos'){
					this.seo.generateTags({
						title: this.TextSetting.PHOTOS_PAGE_TITLE
					});
				}else if(titleName=='privacy_option'){
					this.seo.generateTags({
						title: this.TextSetting.PRIVACY_OPTION_PAGE_TITLE
					});
				}else if(titleName=='hide_profile'){
					this.seo.generateTags({
						title: this.TextSetting.HIDE_PROFILE_PAGE_TITLE
					});
				}else{
					this.seo.generateTags({
						title: this.TextSetting.MY_PLAN_PAGE_TITLE
					});
				}
			}
		});
	}
	
	
	/**This function are used for get the notification counter**/
	public getNotificationsCounter(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
					this.NotificationService.getNotificationLeftCounter(this.userData.slug).then((res: any) => {
							console.log(res);
							this.photoRequest				=	res.data.photo_request;
							this.photoRequestAccepted		=	res.data.photo_request_accepted;
							this.photoRequestRejected		=	res.data.photo_request_rejected;
							this.invitationRequestAccepted	=	res.data.invitation_request_accepted;
							this.invitationRequestRejected	=	res.data.invitation_request_rejected;
							this.invitationRequestReceived	=	res.data.invitation_request_received;
							this.profileViewed				=	res.data.profile_viewed;
							this.shortListedUser			=	res.data.short_listed_user;
							this.totalNotification			=	res.data.total_notification;
					});
			}
		});
	}
	
	
	/**This function are used for forcefully logout**/
	public logoutForcefully(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				let user_name	=	this.userService.getCurrentUser().full_name;
				this.userService.purgeAuth();
				showSuccessMessageTopRight(user_name+', '+environment.LOGOUT_MSG);
				setTimeout(function(){
					$("#userDataUpdated").trigger('click');
				},1000);
				let that		=	this;
				that.router.navigate(['/']);
			}
		});
	}
	
	
}
