import { Injectable }    from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {  FormGroup, Validators,FormControl } from '@angular/forms';
import { environment } from '../../environments/environment';
declare var swal: any;
@Injectable()
export class ValidationService {   
    static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
        let config = {
            'required': 'This field is required.',
            'invalidCreditCard': 'Is invalid credit card number',
            'email': 'Please enter valid email id here.',
            'invalidPassword': 'Please enter valid password.',
			'invalidNumber'	:	'Please enter valid phone number',
			'invalidAdharNumber': 'Please enter valid adhar card number',
			'invalidText'		: 'Please enter only numeric value',
            'minlength': `This field must be ${validatorValue.requiredLength} characters long.`,
            'maxlength': `This field must be ${validatorValue.requiredLength} characters long.`,
            
            'matchPassword': `Passwords do not match.`,
            
            'invalidImage': `The image must be a type of: jpeg, jpg, png, gif.`,
            'requiredCreateProfileBy':`This field is required.`,
            'specialCharMsg':`This field should not contain any special character.`,
        };

        return config[validatorName];
    }


    /* 
	* Function for the match password validation
	*/
	static createProfileByValidator(AC: AbstractControl) {
        const Input = AC.parent;
        if (Input) {
            const createProfileForControl  =   Input.get('create_profile_for'); // to get value in input tag
            const createProfileByControl = Input.get('create_profile_by'); // to get value in input tag
            
            if (createProfileForControl && createProfileByControl) {
                const createProfileFor = createProfileForControl.value;
                const createProfileBy = createProfileByControl.value;
              
                if(createProfileBy ==  ''){
                    if (createProfileFor != 22) {
                        return { 'requiredCreateProfileBy': true };
                    }else{
                        return null;
                    }
                } else {
                    return null;
                }
             }
        }
 
        return null;
     }

    static creditCardValidator(control) {
        // Visa, MasterCard, American Express, Diners Club, Discover, JCB
        if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
            return null;
        } else {
            return { 'invalidCreditCard': true };
        }
    }

    static emailValidator(control) {
	
        // RFC 2822 compliant regex
        if ((typeof(control.value) === 'string') && control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
            return null;
        } else {			
            return { 'invalidEmailAddress': true };
        }
    }
    
    static specialCharValidator(control) {
	
        // RFC 2822 compliant regex
        if ((typeof(control.value) === 'string') && control.value.match(/^[a-zA-Z ]+$/)) {
            return null;
        } else {			
            return { 'specialCharMsg': true };
        }
    }
	

    static passwordValidator(control) {
        // {6,100}           - Assert password is between 6 and 100 characters
        // (?=.*[0-9])       - Assert a string has at least one number
        if ((typeof(control.value) === 'string') && control.value.match(/^(?=.*[0-9])(?=.*[A-Za-z])[a-zA-Z0-9!@#$%^&*]{8,100}$/)) {
            return null;
        } else {
            return { 'invalidPassword': true };
        }
    }
	
	
	
	
	
	/* 
	* Function for the mobile number validation
	*/
	static numberValidator(control) {
		/* if (control.value.match(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/)) {
            return null;
        } else {
            return { 'invalidNumber': true };
        } */
		if ((typeof(control.value) === 'string') && control.value.match(/^[0][1-9]\d{9}$|^[1-9]\d{9}$/)){
            return null;
        } else {
            return { 'invalidNumber': true };
        }
    }
	
	
	
    /**This function are used for only numeric condition **/
    static numericValidator(control) {
        //if (control.value.match(/^[0-9]*$/)) {
        if ((typeof(control.value) === 'string') && control.value.match(/^[0-9]*$/)){
            return null;
        } else {
            return { 'invalidText': true };
        }
    }

    /**This function are used for only numeric condition **/
    static editNumericValidator(control) {
        //if (control.value.match(/^[0-9]*$/)) {
        if(control.value != null){
            if ((typeof(control.value) === 'number')){
                return null;
            } else {
                return { 'invalidText': true };
            }
        } 
    }
	
	/* 
	* Function for the adhar card validation
	*/
	static adharcardNumberValidator(control) {
		if (control.value.match(/^\d{4}\s\d{4}\s\d{4}$/)) {
            return null;
        } else {
            return { 'invalidAdharNumber': true };
        }
    }
	
	
	
	/* 
	* Function for the match password validation
	*/
	static MatchPassword(AC: AbstractControl) {
       const formGroup = AC.parent;
       if (formGroup) {
            const passwordControl = formGroup.get('password'); // to get value in input tag
            const confirmPasswordControl = formGroup.get('confirm_password'); // to get value in input tag

            if (passwordControl && confirmPasswordControl) {
                const password = passwordControl.value;
                const confirmPassword = confirmPasswordControl.value;
                if (password !== confirmPassword) { 
                    return { 'matchPassword': true };
                } else {
                    return null;
                }
            }
       }

       return null;
    }
	
	
	
	
	/**
    *function is used to check file attachment on file change event
	*@params event
    */
	static validateExt(control) {
		let extension = ['png', 'jpeg', 'gif'];
		
		
		return extension.indexOf(control.value)? null : { 'invalidImage':true } 
    }
    

    /*This function are used validate all fields.*/
	static	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
               /* for (let propertyName in control.errors) {
                    alert(propertyName +"---"+ field);  
                    break;                  
                }	*/	
			} else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
           
			}
		});
    }//end validateAllFormFields()
	
	
    
    /*This function are used validate image fields.*/
    static	validateImageField(files) {
        let validation  =   {};
        validation['status']    =   false;
        validation['message']   =   environment.UPLOAD_IMAGE_ERROR_MSG;
        if (files.length > 0) {
            let MAX_IMAGE_SIZE_IN_MB	=	environment.MAX_FILE_UPLOAD_LIMIT;
            let UPLOAD_FILE_SIZE = MAX_IMAGE_SIZE_IN_MB; // file size in MB
            let size = files[0].size.toFixed(2);
            let allowed_size = UPLOAD_FILE_SIZE * 1024 * 1024; 
            if(size > allowed_size){ 
                validation['status']    =   false;
                validation['message']   =   environment.VALID_SIZE_IMAGE_ERROR_MSG+MAX_IMAGE_SIZE_IN_MB+" MB.";
            }else{
                validation['status']    =   true;
           }
        }
        return  validation;
    }//end validateImageField()

	
	
	public confirm(message){
        return swal.fire({
			title: environment.ARE_YOU_SURE,
			text: message,
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#0183cf',
			cancelButtonColor: '#35404e',
			confirmButtonText: 'Yes'
		}).then((result) => {
            return result.value;
        });
    }
		
}

