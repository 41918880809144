import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { CommonFunctionsService } from '../../../../_services/index';
import { FooterService } from "../../../shared/service/footer.service";
import { ValidationService } from '../../../../_services/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../../../_services/user.service';
import { HomeService } from '../../../home/service/home.service';
import { HttpClientModule } from '@angular/common/http';

interface MailChimpResponse {
  result: string;
  msg: string;
}

declare var $:any;

@Component({
	selector: 'dashboard-quick-search',
	templateUrl: './dashboard-quick-search.component.html',
	providers : [CommonFunctionsService,FooterService,ValidationService,UserService]
})

export class DashboardQuickSearchComponent implements OnInit, OnDestroy {
	
	/**This is used to prevent memory leaks **/
		private ngUnsubscribe: Subject<void> = new Subject<void>();	
    /**This is used to prevent memory leaks **/	
	env							=		environment;
	SITE_IMAGE_URL				:		any		=	'';
	userData					:	any		=	[];
	isBrowser			 		:   boolean		=	false;

	homeCountryData					:	any		=	[];
	homeCastData					:	any		=	[];
	homeSubCastData					:	any		=	[];
	genderData						:	any		=	[];
	minAgeData						:	any		=	[];
	maxAgeData						:	any		=	[];
	motherToungueData				:	any		=	[];
	homeStateData					:	any		=	[];
	homeReligionData				:	any		=	[];
	homePageSearchForm				:	FormGroup;	
	
	constructor(public HomeService: HomeService, public router: Router, private formBuilder: FormBuilder,private commonFunctionsService:CommonFunctionsService,private FooterService: FooterService,public userService:UserService){
		 this.SITE_IMAGE_URL = this.env.SITE_IMAGE_URL;
		 this.createHomePageSearchForm()
	}
	
	ngOnInit() {
	   this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.isBrowser		=	true;

				let that	=	this;
				if(this.userService.getCurrentUser() != 'undefined' && this.userService.getCurrentUser() != null){
					this.getLoginUserData();
				}
				
				/*This function are used for the home seraching data */	
				this.getHomeSerachingData();
				
				/**function for looking_for change */
				$(document).on('change','#looking_for_dashboard select',function(){
					let selectval	=	$('#looking_for_dashboard select').val();
					that.homePageSearchForm.get('gender').setValue(selectval);
				});
				/**function for looking_for change */
				
				/**function for minage change */
				$(document).on('change','#minage_home_dashboard select',function(){
					let selectval	=	$('#minage_home_dashboard select').val();
					that.homePageSearchForm.get('minage').setValue(selectval);
					let temp	=	[];
					temp[0]	=	{'id':'','text':'Choose'};
					let counter	=	1;
					for(let i = selectval;i<=80; i++){
						temp[counter]	=	{'id':i,'text':i};
						counter=	counter+1;
					}
					that.maxAgeData	=	temp;
					that.homePageSearchForm.get('maxage').setValue('');
				 });
				/** function for minage change */
				
				/**function for maxheight change */
				$(document).on('change','#maxage_home_dashboard select',function(){
					let selectval	=	$('#maxage_home_dashboard select').val();
					that.homePageSearchForm.get('maxage').setValue(selectval);
				});
				/**function for maxheight change */
				
				/**function for religion value get  */
				$(document).on('change','#religion_id_home_page_dashboard select',function(){
					let selectReligionVal	=	$('#religion_id_home_page_dashboard select').val();
					that.homePageSearchForm.get('religion').setValue(selectReligionVal);			
				});
			}
	   });		
	}
	
	
	/*This function are used for create dashboard left search */
	public createHomePageSearchForm(){
		this.homePageSearchForm	=	this.formBuilder.group({
			'gender'					: [''],
			'minage'					: [''],
			'maxage'  					: [''],
			'religion'					: [''],
			'slug'  					: [''],
		});	
	}
	
	
	/** This function are used for search the partner*/
	public submitSearch(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				$('body').removeClass('modal-open');
				$('.dashboard_quick_serch_popup').modal('hide');
				this.homePageSearchForm.value.slug		=	this.userData.slug;
				this.router.navigate(['/search-result'],{ queryParams: this.homePageSearchForm.value}); 
			}
		});
	}
	
	/**This function is used to prevent memory leaks **/
	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
    }
	
	/**This function are used for get the login user data detail **/
	public getLoginUserData(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.userData				=	this.userService.getCurrentUser();
			}
		});
	}
	
	/**This function are used for search the home page data */
	public getHomeSerachingData(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.HomeService.getHomeSerachData().then((res: any) => {
					
					this.genderData				=	res.data.gender_new_dashboard;
					this.minAgeData				=	res.data.age_range;
					this.maxAgeData				=	res.data.max_age_range;
					this.homeReligionData		=	res.data.religion;
					let religionOther			=	this.env.RELIGION_OTHER_VALUE;
					let motherToungueOther		=	this.env.MOTHER_TONG_OTHER_VALUE;
					const filteredItems 		=   this.homeReligionData.filter(function(item) {
							return item.id !== religionOther
					})
					this.homeReligionData		=	filteredItems;
					unblockUI();
				});
			}
		});
	}

	
	
}
