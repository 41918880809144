import { Injectable } from '@angular/core';
import { Meta,Title } from '@angular/platform-browser';
import {GlobalApp} from '../_helpers/index';
import { environment } from '../../environments/environment' ;


@Injectable()
export class SeoService {
  env			=  environment;
  siteTitle		:  any=  "";
  siteUrl		:  any	=	"";
  
  constructor(private meta: Meta,private title: Title,public sessionUser:GlobalApp) {
	this.siteTitle		=	this.env.SITE_TITLE;
	this.siteUrl		=	this.env.SITE_URL;
  }

  generateTags(config) {
		if(typeof(config['title'])!= 'undefined'){
		  this.sessionUser.setTitle(config['title'],this.siteTitle);
		  this.meta.updateTag({ name: 'twitter:title', content: config['title'] });
		  this.meta.updateTag({ property: 'og:title', content: config['title'] });
		}else{
		  this.sessionUser.setTitle('',this.siteTitle);
		  this.meta.updateTag({ name: 'twitter:title', content: this.siteTitle });
		  this.meta.updateTag({ property: 'og:title', content: this.siteTitle});
		}
		
		if(typeof(config['card'])!='undefined'){
		  this.meta.updateTag({ name: 'twitter:card', content: config['card'] });
		}else{
		  this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
		}
		if(typeof(config['site'])!= 'undefined'){
		  this.meta.updateTag({ name: 'twitter:site', content: config['site'] });
		  this.meta.updateTag({ property: 'og:site_name', content: config['site'] });
		}else{
		  this.meta.updateTag({ name: 'twitter:site', content: this.siteTitle});
		  this.meta.updateTag({ property: 'og:site_name', content: this.siteTitle });
		}
		
		if(typeof(config['description'])!= 'undefined'){
		  this.meta.updateTag({ name: 'description', content: config['description'] });
		  this.meta.updateTag({ name: 'twitter:description', content: config['description'] });
		  this.meta.updateTag({ property: 'og:description', content: config['description'] });
		}else{
		  this.meta.updateTag({ name: 'description', content: this.env.SITE_DESCRIPTION });
		  this.meta.updateTag({ name: 'twitter:description', content: this.env.SITE_DESCRIPTION });
		  this.meta.updateTag({ property: 'og:description', content: this.env.SITE_DESCRIPTION });
		}

		if(typeof(config['keywords'])!= 'undefined'){
		  this.meta.updateTag({ name: 'keywords', content: config['keywords'] });
		  this.meta.updateTag({ name: 'twitter:keywords', content: config['keywords'] });
		  this.meta.updateTag({ property: 'og:keywords', content: config['keywords'] });
		}else{
		  this.meta.updateTag({ name: 'keywords', content: this.env.SITE_KEYWORDS });
		  this.meta.updateTag({ name: 'twitter:keywords', content: this.env.SITE_KEYWORDS });
		  this.meta.updateTag({ property: 'og:keywords', content: this.env.SITE_KEYWORDS });
		}
		if(typeof(config['image']) != 'undefined'){
		  this.meta.updateTag({ name: 'twitter:image', content: config['image'] });
		  this.meta.updateTag({ property: 'og:image', content: config['image'] });
		}else{
		  this.meta.updateTag({ name: 'twitter:image', content: this.env.SITE_URL_WITHOUT_SLASH+this.env.SITE_IMAGE_URL+'logo.png'});
		  this.meta.updateTag({ property: 'og:image', content: this.env.SITE_URL_WITHOUT_SLASH+this.env.SITE_IMAGE_URL+'logo.png' });
		}
		if(typeof(config['type']) != 'undefined'){
		  this.meta.updateTag({ property: 'og:type', content: config['type'] });
		}else{
		  this.meta.updateTag({ property: 'og:type', content: 'Website' });
		}
		
		if(typeof(config['slug']) != 'undefined'){
		  this.meta.updateTag({ property: 'og:url', content: this.env.SITE_URL+config['slug'] });
		}else{
		  this.meta.updateTag({ property: 'og:url', content: this.siteUrl });
		}
	}

}