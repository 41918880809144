import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { FooterService } from "../../../shared/service/footer.service";
import { ValidationService, CommonFunctionsService  } from '../../../../_services/index';
import { Subject } from 'rxjs';
import { UserService } from '../../../../_services/user.service';
import { takeUntil } from 'rxjs/operators';


declare var $:any;

@Component({
  selector: 'success-story',
  templateUrl: './successstory.component.html',
  providers : [CommonFunctionsService,FooterService,ValidationService,UserService]
 
})
export class SuccessStoryComponent implements OnInit, OnDestroy {
	
	/**This is used to prevent memory leaks **/
	private ngUnsubscribe: Subject<void> = new Subject<void>();	
	
	env						=	environment;
	SITE_IMAGE_URL			:   string 	= 	"";
	successStories			:	any		=	[];

  	constructor(public router: Router, private formBuilder: FormBuilder,private commonFunctionsService:CommonFunctionsService,private FooterService: FooterService, public userService : UserService){
		this.SITE_IMAGE_URL = this.env.SITE_IMAGE_URL;
	}

  	ngOnInit() {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.getSuccessStories();
			}
		});
	}//end ngOnInit()

	/*function for get succes sstories*/
	public getSuccessStories(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.commonFunctionsService.getSuccessStories().then((res:any) => { 
					this.successStories	=	res.data;
					let that 	=	this;
					setTimeout(function(){
						that.successStorySlider();
					},1000);
				});
			}
		});
	}//end getSuccessStories()
	

	/**function for successstory slider */
	public successStorySlider(){
		$('.owl-carousel').owlCarousel({
			loop:true,
			margin:0,
			dots: true,
			autoHeight:true,
			responsiveClass:true,
			responsive:{
				0:{
				items:1,
				
				},
				600:{
				items:1,
				
				},
				767:{
				items:1,
				
				},
				1000:{
				items:1,
				
				dots: true,
				}
			}
		});
	}
	
	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
	}
}
