import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';

@Injectable()
export class UserActionService {
	
	/**define valiables */
	SITE_API_URL		:	any		=	'';
	data				:	any		=	{};
	
	/**define constructor */
	constructor(private httpClient:HttpClient,public SharedService : SharedService) {
		this.SITE_API_URL		=	environment.API_URL;
	}
	
	/**function for get blocked user */
	getUsersLeftCounter(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-users-left-counter/'+slug);
	}
	
	/**function for get login user detail */
	getLoggedInUserDetails(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-logged-in-user/'+slug);
	}
	
	/** Function to submit invitation form */
	submitInvitationForm(invitationForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'invite-user',invitationForm);
	}
	
	/**function for get load more result */
	loadMoreResult(url:any){
		return this.SharedService.getRequest(url);
	}
	
	 /*** function for send photo request **/
    sendphotoRequest(sender:any,receiver:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'send-photo-request/'+sender+'/'+receiver);
	}
	
	/**function for rejected member */
	rejectListMember(slug:any,rejectListUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'reject-list-user/'+slug+'/'+rejectListUserSlug);
	}
	
	/**function for get blocked user */
	getBlockedUserResult(slug:any,type:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-block-user-list/'+slug+'/'+type);
	}
	
	/**function for get shortlisted user */
	getShortlistedUserResult(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-short-list-users/'+slug);
	}
	
	getShortlistedUserByHimResult(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-short-list-users-by-him/'+slug);
	}
	
	/**function for get shortlisted user */
	getRejectedUserResult(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-rejected-users/'+slug);
	}
	
	/**function for unblock member */
	unblockMember(slug:any,blockedUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'unblock-user/'+slug+'/'+blockedUserSlug);
	}
	
	/** Function to get query search results */
	unblockMultipleMember(formData:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'unblock-multiple-users',formData);
	}
	
	/**function for unreject member */
	unRejectMember(slug:any,rejectedUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'unreject-user/'+slug+'/'+rejectedUserSlug);
	}
	
	/** Function to get query search results */
	unRejectMultipleMember(formData:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'unreject-multiple-users',formData);	
	}
	
	/**function for shortlisted member */
	shortListMember(slug:any,shortListUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'short-list-user/'+slug+'/'+shortListUserSlug);
	}
	
	/**function for unreject member */
	unShortlistMember(slug:any,shortListUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'unshortlist-user/'+slug+'/'+shortListUserSlug);
	}
	
	/** Function to get query search results */
	unShortlistMultipleMember(formData:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'unshortlist-multiple-users',formData);	
	}
	
	/** Function to submit email form */
	submitEmailForm(emailForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'send-email',emailForm);
	}
	
}
  
  
