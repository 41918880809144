import { Directive, ElementRef , HostListener, Input } from '@angular/core';
import {UserService} from '../_services/user.service';
import { Router} from '@angular/router';
import { environment } from '../../environments/environment';
import { TextSetting } from '../textsetting';
import { InboxService } from '../modules/inbox/service/inbox.service';

declare var $:any;	
declare var bootbox:any;

@Directive({
	selector: '[appRestoreinvitation]',
	providers: [InboxService]
})
export class RestoreinvitationDirective {
	@Input('currentIndex') currentIndex: string;
	@Input('rejectedUserSlug') rejectedUserSlug: string;
	@Input('userDetail') userDetail: any;
	
	TextSetting 						=   TextSetting;

constructor(private el: ElementRef,public router: Router,public userService: UserService,public InboxService: InboxService) { }
  

@HostListener('click') onClick() {
	this.restoreInvitation();
}

	/**function for  delete invitation */
	public restoreInvitation(){
		let userSlug	=	this.userService.getCurrentUser().slug;
		let that	=	this;
		bootbox.confirm(that.TextSetting.RESTORE_INVITATION_MESSAGE_MSG,
			function(result){
				if(result){
					blockUI();
					that.InboxService.restoreInvitation(userSlug,that.rejectedUserSlug).then((res:any)=>{
						if(res.data.success == true){
							showSuccessMessageTopRight(res.data.message);
							that.userDetail.splice(that.currentIndex,1);
							$('#leftCounterChange').trigger('click');
						}else{
							showErrorMessageTopRight(res.data.message);
						}
					});
					unblockUI();
				}
			}
		);
	}//end deleteInvitation()

}
