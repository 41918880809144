import { Component, OnInit,Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RouterModule,Routes,Router } from '@angular/router';
import { environment } from '../environments/environment';
import { UserService } from './_services/user.service';
import {CommonFunctionsService} from './_services/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers : [UserService,CommonFunctionsService]
})
export class AppComponent {
	
	title = '';
  	SITE_IMAGE_URL: string = "";
	
	constructor(public router: Router,private commonFunctionsService:CommonFunctionsService,private userService:UserService,) {
     	this.SITE_IMAGE_URL = environment.SITE_IMAGE_URL;
	}
	
	ngOnInit() {
		this.userService.populate();
	}
	
	/**This function are used for when activte the url then move window.*/
	onActivate(e, outlet){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
				if(isBrowser){
					window.scrollTo(0, 0);
				}
			});
		}
}
