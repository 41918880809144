import { Component, OnInit,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
declare var $:any;
@Component({
  templateUrl: './error404.component.html',
})
export class Error404Component implements OnInit {
  
  /*declare contructor */
  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }
  
  errorTitle          :	  string	=	'';
  errorDescription    :	  string	=	'';
  errorClickHere      :	  string	=	'';

  /*declare initialization */
  ngOnInit() {
    if(isPlatformBrowser(this.platformId)) {
      this.errorTitle         = environment.ERROR_404;
      this.errorDescription   = environment.ERROR_DESCRIPTION;
      this.errorClickHere     = environment.ERROR_CLICK_HERE;
	  $('body .header_section').css('display','none');
	  $('body').addClass('signin_page');
    }
  }
}
