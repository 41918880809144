import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Router, ActivatedRoute, ParamMap, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized, UrlTree, UrlSegmentGroup, UrlSegment, PRIMARY_OUTLET} from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import {CommonFunctionsService} from '../../../../_services/index';
import { FooterService} from '../../service/footer.service';
import { UserService } from '../../../../_services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
declare var $:any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [CommonFunctionsService,FooterService,UserService]
})

export class FooterComponent implements OnInit, OnDestroy {

    /**This is used to prevent memory leaks **/
  private ngUnsubscribe: Subject<void> = new Subject<void>();	
  /**This is used to prevent memory leaks **/		
  	
	env							=	environment;
	SITE_IMAGE_URL			:	any		=	''; 
	SUCCESS_CONSTANT  		:   any		=	'';
	ERROR_CONSTANT  		:   any		=	'';
	footerBlockDataStatus   :   any		=	"";
	footerBlockData   		:   any		=	"";
	socialDataStatus   		:   any		=	"";
	socialData   			:   any		=	"";
	slug 			 	    :   any		=	'';
	userData	:any					=	this.userService.getCurrentUser();

    constructor(private FooterService: FooterService,public router: Router, private formBuilder: FormBuilder,private commonFunctionsService:CommonFunctionsService,public userService : UserService){	
		this.SITE_IMAGE_URL = this.env.SITE_IMAGE_URL;
		this.SUCCESS_CONSTANT = this.env.SUCCESS_CONSTANT;
		this.ERROR_CONSTANT = this.env.ERROR_CONSTANT;
	}

	ngOnInit() {
	   this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
				if(isBrowser){
					this.getFooterBlockData('footer-block-slug');
					this.getSocialLinks();
					let that	=	this;
					$(document).on('click','#userDataUpdated',function(){
						that.getLoginUserData();
					});	
					
					
					/**This function are used for **/
					this.router.events.subscribe((event : Event) => {
						if(event instanceof NavigationStart) {
							if(this.userData != undefined && this.userData != null ){
								if(this.userData.is_deleted == 1 || this.userData.active == 0){
									showErrorMessageTopRight(this.env.VERIFIED_EMAIL_MSG);
									$('#logout_user').trigger('click');
								}
							}
						}
						
						if(event instanceof NavigationEnd) {
							/**update user data for complete step sixth */
							this.userService.updateCurrentUserData();
						}
					});
				}
	   });		
	}

	/**function used to get footer block data from Api */

	public getFooterBlockData(slug) {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
				  
				   this.commonFunctionsService.getBlock(slug).then((res: any) => {
					   this.footerBlockDataStatus		=	res.data.status;
					   if(this.footerBlockDataStatus == this.SUCCESS_CONSTANT){	
						   this.footerBlockData		=	res.data.data;
						    
					   }else{
						   showSuccessCenter(res.data.message);
						   this.router.navigate(['/']);
					   }
				   });
			   }
		});
   }

   /**function used to get footer socail links url from Api */
   public getSocialLinks()
   {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser)=>{
			if(isBrowser){
				this.commonFunctionsService.getSocialLinksUrl().then((res: any) => {
					this.socialDataStatus		=	res.data.status;
					if(this.socialDataStatus == this.SUCCESS_CONSTANT){	
						this.socialData		=	res.data.data;
					}else{
						showSuccessCenter(res.data.message);
						this.router.navigate(['/']);
					}
				});
			}
		});
    }
	
	/**This function are used for get the login user data detail **/
	public getLoginUserData(){
		this.userData				=	this.userService.getCurrentUser();
	}

	
	/**This function is used to prevent memory leaks **/
	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
    }
}
