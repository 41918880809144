import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { SharedService } from "./modules/shared/service/shared.service";

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor{
  constructor(private injector: Injector) { }
  intercept(req,next){
	  
	  let sharedService	=	this.injector.get(SharedService)
	  let tokenizedReq	=req.clone({
		  setHeaders : {
				'api-request-referrer' : `web`,			
				'apiRequestReferrer':`web`,
				'authKey':`web`,
				Authorization : `Bearer ${sharedService.getToken()}`
		  }	
	  })
	  return next.handle(tokenizedReq);
  }
}
