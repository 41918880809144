import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService } from '../../../../_services/index';
import { UserService } from '../../../../_services/user.service';
import {GlobalApp} from '../../../../_helpers/index';
import { isPlatformBrowser } from '@angular/common';
import { TextSetting } from '../../../../textsetting';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SeoService } from '../../../../_services/seo.service';
import {CommonFunctionsService} from '../../../../_services/index';
declare var $:any;

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html'
})
export class ResetpasswordComponent implements OnInit,OnDestroy {
	
	private ngUnsubscribe:Subject<any> = new Subject();	
	
	Resetform						:	FormGroup;
	ResetError					:	any		=	{};
	ResetFormErrorMsg			:	any		=	{};
	RestError					:	any		=	{};
	ResetFormSuccessMessage		:	any		=	{};
	ResetFormStatus				:	any		=	{};
	passwordToolTipText			:	string	=	'';
	validateString				:	string 	=	''; 
	env							=	environment;
	SITE_IMAGE_URL				:   string 	= 	"";
	
	constructor(
		private route: ActivatedRoute,
		public router: Router,
		private formBuilder: FormBuilder,
		public commonFunctionsService : CommonFunctionsService
  ) { 
		this.SITE_IMAGE_URL 	= 	this.env.SITE_IMAGE_URL;
		
  }

  ngOnInit() {
		this.RestForm();
		/*********** variable decleration ************/
			this.commonFunctionsService.isBrowser.subscribe((isBrowser)=>{
				if(isBrowser){
					this.passwordToolTipText		=	this.env.VALID_PASSWORD_VALIDATION_MSG;
					
					/*********** This code are used for tooltip initialization ************/
					setTimeout(function(){
						$('[data-toggle="tooltip"]').tooltip(); 
					},1000);
				}
			});
  }
  
   ngOnDestroy() {
        this.ngUnsubscribe.next();
   }
  
  
  /*This function are used for reset the password */
	RestForm(){
		this.Resetform			=	this.formBuilder.group({ 
			'password'				: ['', [Validators.required,ValidationService.passwordValidator]],
			'confirm_password'		: ['', [Validators.required,ValidationService.MatchPassword]],
			'validate_string'		: [this.validateString],
		});
	}	
	
	/*This function are used for reset the password form submit*/
	onResetFormSubmit(){
		
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
				if(isBrowser){
				blockUI();
				if (this.Resetform.valid) {
					this.Resetform.value.validate_string	=	$("#validate_string_val").val();
					this.commonFunctionsService.resetPassword(this.Resetform.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {	  
						this.ResetFormStatus	= res.data.status;
						if(this.ResetFormStatus == this.env.SUCCESS_CONSTANT)
						{
							this.ResetError			=	'';
							this.Resetform.reset();
							this.ResetFormSuccessMessage		=	res.data.message;
							$('body').removeClass('modal-open');
							$('#reset_password').modal('hide');
							showSuccessMessageTopRight(this.ResetFormSuccessMessage);
							this.router.navigate(['/']);
						}else{
							this.ResetError 	= res.data.errors;
							if(this.ResetError == 'errors'){
								this.ResetError			=	'';
								this.ResetFormErrorMsg	= 	res.data.message;
								showErrorMessageTopRight(this.ResetFormErrorMsg);
							}
						}
						unblockUI();
					});
				} else {
							unblockUI();
							ValidationService.validateAllFormFields(this.Resetform);
				}
			}
		});
	}
	
	
	/*This function are used for off the login popup */
	clickToOff(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
					$('body').removeClass('modal-open');
					$('#reset_password').modal('hide');
					this.router.navigate(['/']);
			}
		});
	}
	
}
