import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform
{
    transform(value:any, args:string[]): any {
        let keys:any[] = [];
		for (var prop in value) {		
			keys.push({key: prop, value: value[prop]});
		} 	
        return keys;
    }

    


}