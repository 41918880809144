import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';

@Injectable()
export class HomeService {
	/**define valiables */
	env	= environment;

	SITE_API_URL		:	any		=	'';
	data				:	any		=	{};

	/**define constructor */
	constructor(private httpClient:HttpClient,public SharedService : SharedService) {
		this.SITE_API_URL		=	environment.API_URL;
	}
	
	
	/*This function are used for detch the ho,e seraching data*/
	getHomeSerachData(){
		return this.SharedService.getRequest(this.SITE_API_URL+'home-page-search-data');
	}

	 /*This function are used for fetch the success story data */
	 successStroyFetchData(){
		let limit	=	this.env.SUCCESS_STORY_SLIDER_LIMIT;
		return this.SharedService.getRequest(this.SITE_API_URL+'get-success-story/'+limit);
	  }

	/*This function are used for get state list in country wise*/
	getStateData(countryId:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-state-home-page/'+countryId);
	}

	/*This function are used for get caste list in relgion wise*/
	getCasteData(relgionId:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-cast-by-religion-home-page/'+relgionId);
	}
	
	/**This function are used for get the sub cast in cast wise **/
	getSubCasteData(castId:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-sub-cast-by-cast-home-page/'+castId);
	}
	
	/*This function are used for get caste list in relgion wise*/
	getCasteDataSignup(relgionId:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-cast-by-religion-signup-page/'+relgionId);
	}
	
	/**This function are used for get the slider data **/
	getSliderData(){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-slider');
	}
	

	/** handler function added **/ 
    private handleError (error: Response | any) {
        console.error(error.message || error);
        return Promise.reject(error.message || error);
    }
	
}
