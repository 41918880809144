import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';

@Injectable()
export class NotificationService {
	
	/**define valiables */
	SITE_API_URL		:	any		=	'';
	data				:	any		=	{};
	private profileUrl 		: any;
	/**define constructor */
	constructor(private httpClient:HttpClient,public SharedService : SharedService) {
		this.SITE_API_URL		=	environment.API_URL;
	}

	/**function for get left inbox counter */
	getNotificationLeftCounter(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-notification-counter/'+slug);
	}
	
	/**function for getNotifications */
	getNotifications(slug:any,type:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-notifications/'+slug+'/'+type);
	}

	/**function for mark as read Notifications */
	markAsRead(notification_id:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'mark-as-read-notification/'+notification_id);
	}
	
	/**function for load more notifications */
	loadMoreResult(url:any){
		return this.SharedService.getRequest(url);
	}

}