import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';
import { TextSetting } from '../../../textsetting';

@Injectable()
export class AccountSettingService {
	
	/**define valiables */
	SITE_API_URL		:	any		=	'';
	data				:	any		=	{};
	TextSetting 						=   TextSetting;
	env 						=   environment;
	
	/**define constructor */
	constructor(private httpClient:HttpClient,public SharedService : SharedService) {
		this.SITE_API_URL		=	environment.API_URL;
	}

	/**function are used for get the state data */
	getStateData(country_id:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-account-state-information/'+country_id);
	}

	submitChangePasswordForm(changePasswordForm:any){
		return this.SharedService.getPost(this.SITE_API_URL+'change-password',changePasswordForm);
	}

	submitaddressForm(submitAddressForm:any){
		return this.SharedService.getPost(this.SITE_API_URL+'save-user-address-details',submitAddressForm);
	}

	/**function are used for get the primary information */
	getProfilePrimaryInformation(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-user-address-details/'+slug);
	}

	/**contact filter services */
	getContactFilterInformation(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-contect-filter-info/'+slug);
	}
	/**contact filter master */
	getContactFilterMaster(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-contact-filter-master/'+slug);
	}

	/**submit contact filter */
	submitContactFilterForm(ContactFilterForm:any){
		return this.SharedService.getPost(this.SITE_API_URL+'save-contact-filter-info',ContactFilterForm);
	}

	/**function for get sms alert options **/
	getSmsAlertOption(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-user-email-alerts/'+slug);
	}

	/** function for used to sms form submiited */
	saveSmsAlert(smsAlertForm:any){
		return this.SharedService.getPost(this.SITE_API_URL+'save-user-email-alerts',smsAlertForm);
	}

	/** function for used to add photo */
	submitAddPhoptoForm(uploadPhotoForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'upload-photo',uploadPhotoForm);
	}

	/** function for used to get login user data */
	getLoginUserPhoto(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-photo/'+slug);
	}

	/** function are used to set mark as profile */
	setMarkAsProfilePhoto(slug:any,imageId:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'set-as-profile/'+slug+'/'+imageId);
	}

	/**function for delete profile photo */
	deleteProfilePhoto(slug:any,imageId:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'delete-photo/'+slug+'/'+imageId);
	}
	
	/**function for get privacy options */
	getPrivacyOptions(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-user-privacy-options/'+slug);
	}

	/**function for save privacy option */
	submitPrivacyOptionForm(privacyOptionForm:any){
		return this.SharedService.getPost(this.SITE_API_URL+'save-user-privacy-options',privacyOptionForm);
	}

	/**function for get hide profile data */
	getHideProfileTime(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'hide-profile-time/'+slug);
	}

	/**function for submit hide profile data */
	submitHideProfileForm(submitHideForm : any){
		return this.SharedService.getPost(this.SITE_API_URL+'hide-profile',submitHideForm);
	}

	/** function for check password profile */
	checkPasswordForDeleteProfile(deleteForm: any){
		return this.SharedService.getPost(this.SITE_API_URL+'check-user',deleteForm);
	}

   /**function for use delete profile form */
	deleteProfileForm(slug:any,reason_id:any,description:any,comment:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'delete-profile/'+slug+'/'+reason_id+'/'+description+'/'+comment);
	}

	/**function for get plan history */
	getPlanHistory(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-purchased-plan-list/'+slug);
	}

	/*This function are used for fetch the parterner preference data */
	getCasteList(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'get-cast-list-from-religion',formData);
	}
	/*This function are used for fetch the parterner preference data */
	getSubCasteList(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'get-sub-cast-list-from-caste',formData);
	}

	/** function for get new matches */
	getNewMatches(slug:any,viewprofileslug:any){
		let limit	=	this.env.RECENT_UPDATE_LIMIT;
		if(viewprofileslug != ''){
			return this.SharedService.getRequest(this.SITE_API_URL+'get-new-matches/'+slug+'/'+limit+'/'+viewprofileslug);
		}else{
			return this.SharedService.getRequest(this.SITE_API_URL+'get-new-matches/'+slug+'/'+limit);
		}
		
	}

}
  
  
