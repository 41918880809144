import { Injectable ,Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';

@Injectable()
export class SharedService {
  /**Define constructor*/	
  constructor(private HttpClient: HttpClient,@Inject(PLATFORM_ID) private platformId: Object) { }
  
  /**Define get request method**/	
  /* public getRequest(requestUrl = ''){
		return this.HttpClient.get(requestUrl).toPromise().then(res => {
			let resp  = Buffer.from(res['response'], 'base64').toString('binary');  
			return  resp; 
		})
		.catch(this.handleError);
  } */
  
  
   /**Define get request method*/	
	public getRequest(requestUrl = ''){
		
			return this.HttpClient.get(requestUrl).toPromise().then(res => {
				return res;
			})
			.catch(this.handleError);
	}
  
   /**Define post request method**/
  /*public getPost(requestUrl = '', data = ''){
	let loginUserSlug	=	'';
	let loginUserRole	=	'';
	if(isPlatformBrowser(this.platformId)) {
		let  currentUser = JSON.parse(localStorage.getItem('userData'));
		if (typeof currentUser != 'undefined' && currentUser != null) {		    			
			loginUserSlug	=	currentUser.slug;
			loginUserRole	=	currentUser.current_role;
		}
	}
	if(typeof(data['data']) != 'undefined'){
		data['data']['slug']			=	loginUserSlug;
		data['data']['current_role']	=	loginUserRole;
	}else{
		data['data']			=	{};
		data['data']['slug']	=	loginUserSlug;
		data['data']['current_role']	=	loginUserRole;
	} 
	
    let request:any       		= {};
	let jsonData				=	JSON.stringify(data);
    request['req']  			= 	Buffer.from(jsonData, 'binary').toString('base64');
    return this.HttpClient.post(requestUrl,request)
        .map(res => {
          let resp  = Buffer.from(res['response'], 'base64').toString('binary');  
          return  JSON.parse(resp); 
        });	 
  }*/
  
   /**Define post request method*/
  public getPost(requestUrl = '', data = ''){
	 
		return this.HttpClient.post(requestUrl,data)
        .map(res => res);	 
  }
  
  
  /**This function are used for get the token on each request **/
  public getToken(){
	  if(isPlatformBrowser(this.platformId)) {
		return JSON.parse(localStorage.getItem('token'));
	  }else{
		return "";
	  }
  }
	
	/**Define post request method*/
	public getPostImage(requestUrl = '', data :any){
		let loginUserSlug	=	'';
		let loginUserRole	=	'';
		if(isPlatformBrowser(this.platformId)) {
			let  currentUser = JSON.parse(localStorage.getItem('userData'));
			if (typeof currentUser != 'undefined' && currentUser != null) {		    			
				loginUserSlug	=	currentUser.slug;
				loginUserRole	=	currentUser.current_role;
			}
		}
		if(typeof(data['data']) != 'undefined'){
			data['data']['slug']			=	loginUserSlug;
			data['data']['current_role']	=	loginUserRole;
		}else{
			data['data']			=	{};
			data['data']['slug']			=	loginUserSlug;
			data['data']['current_role']	=	loginUserRole;
		} 
		
		let requestData     :any    =	{};
		let file			:any	=	'';
		let flagFile		:any	=	{};
		let anthemFile		:any	=	{};
		
		if(data['library_image'] != undefined){
			file					=	data['library_image'];
		}	
		requestData['data']         =	data['data'];
		requestData['method_name']  =	data['method_name'];
		let jsonData				=	JSON.stringify(requestData);
		let req  = Buffer.from(jsonData, 'binary').toString('base64');
		let formData  = new FormData();
		formData.append('req',req);
		if(data['image'] != undefined){
			file					=	data['image'];
			formData.append('image',file);
		}
		if(data['house_flag'] != undefined){
			flagFile					=	data['house_flag'];
			formData.append('house_flag',flagFile);
		}
		if(data['house_anthem'] != undefined){
			anthemFile					=	data['house_anthem'];
			formData.append('house_anthem',anthemFile);
		}
		if(data['library_image'] != undefined){
			if(file.length > 0){				
				for(let i =0; i < file.length; i++){
					formData.append("library_image_"+i, file[i]);
				}
				formData.append("total_attachments", file.length);
			}
		}
		return this.HttpClient.post(requestUrl,formData)
			.map(res => {
			let resp  = Buffer.from(res['response'], 'base64').toString('binary');  
			return  JSON.parse(resp); 
		});	 
	}

  /**handler function added **/
  private handleError (error: Response | any) {
		console.error(error.message || error);
		return Promise.reject(error.message || error);
   }
}


