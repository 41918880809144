import { Directive, ElementRef , HostListener, Input } from '@angular/core';
import {UserService} from '../_services/user.service';
import { UserActionService } from '../modules/user/service/user.service';
import { Router} from '@angular/router';
import { environment } from '../../environments/environment';
import { TextSetting } from '../textsetting';

declare var $:any;	
declare var bootbox:any;

@Directive({
  selector: '[appUnblockmember]',
  providers: [UserService,UserActionService]
})
export class UnblockmemberDirective {
	
@Input('currentIndex') currentIndex: string;
@Input('blockedUserSlug') blockedUserSlug: string;
@Input('userDetail') userDetail: any;
@Input('pageName') pageName: any;

TextSetting 						=   TextSetting;
userData							:any	=	this.userService.getCurrentUser();

 constructor(private el: ElementRef,public router: Router,public userService: UserService,public UserActionService:UserActionService) { }
 
 
@HostListener('click') onClick() {
	this.unblockMember();
}


/**function for unblock member */
	public unblockMember(){
		let userSlug	=	this.userData().slug;
		let that	=	this;
		bootbox.confirm(that.TextSetting.UNBLOCK_MEMBER_MESSAGE,
			function(result){
				if(result){
					blockUI();
					that.UserActionService.unblockMember(userSlug,that.blockedUserSlug).then((res: any) => {
						if(res.data.success == true){
							showSuccessMessageTopRight(res.data.message);
							that.userDetail.splice(that.currentIndex, 1);
							if(that.pageName == 'user_page'){
								$('#block_unblock_multiple').val(that.blockedUserSlug);
								$('#block_unblock_multiple').trigger('click');
							}
						}else{
							showErrorMessageTopRight(res.data.message);
						}
					});
					unblockUI();
				}
			}
		);
	}

}
