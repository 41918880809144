import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService ,CommonFunctionsService } from '../../../../_services/index';
import { SharedService } from "../../service/shared.service";
import { ResetPasswordService } from "../../service/resetpassword.service";
import { UserService } from '../../../../_services/user.service';
import { GlobalApp} from '../../../../_helpers/index';
import { isPlatformBrowser } from '@angular/common';
import { TextSetting } from '../../../../textsetting';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SeoService } from '../../../../_services/seo.service';
import { KeysPipe } 	from '../../../../_pipes/index';

declare var $:any;

@Component({
  selector: 'app-edit-mobile-verification',
  templateUrl: './editmobileverification.component.html',
  providers : [UserService,ResetPasswordService,SharedService]
})
export class EditMobileVerificationComponent implements OnInit,OnDestroy {
	
	private ngUnsubscribe:Subject<any> = new Subject();	
	
	SITE_IMAGE_URL: string = "";
	otpForm						:	FormGroup;	
	otpFormStatus				:	boolean	=	false;
	remain_time					:	string	=	'0:00 min left';
	isTimerOn					:	boolean	=	false;
	resendMailTime				:	number	=	0;
	otpFormError				:	any		=	{};
	user_data					:	string	=	'';
	otpTypeEmail				:	boolean	=	false;

  
	constructor(private userService: UserService, public router: Router,private formBuilder: FormBuilder,private ResetPasswordService: ResetPasswordService,public commonFunctionsService : CommonFunctionsService,) {
		this.SITE_IMAGE_URL 		= environment.SITE_IMAGE_URL;
		this.resendMailTime			=	environment.RESEND_MAIL_TIME_IN_SECOND;
	}

	ngOnInit() {
		/*********** This is used for validate the login form ************/	
		this.createOtpForm();
		let that	=	this;
		
		$(document).on('click','#submit_otp_form_open',function(){
			that.isTimerOn	=	true;
			that.timer(that.resendMailTime);
			let otp_type	=	$("#otp_type").val();
			if(otp_type	==	'email'){
				that.otpTypeEmail	=	true;
			}else{
				that.otpTypeEmail	=	false;
			}
			$('#mobile_verify').modal('show');
			$('body').addClass('modal-open');
		});
		
		$('#resend_otp').off('click').on('click',function(){
				blockUI();
				setTimeout(function(){
					that.resend_otp();
				},200);	
		});
		
		$('#mobile_verify').on('hidden.bs.modal', function () {
			that.isTimerOn	=	false;
		})
	}
  	/**function for create otp form */
	public createOtpForm(){
		this.otpForm					=	this.formBuilder.group({
			'one_time_password'					: ['', Validators.required],
			'user_data'							: [''],
		})

	}
  
  
  	/**function for timer */
	public timer(time:any){
		let currentdate =	new Date().getTime();
		let countDowntime	=	currentdate+(time*1000);
		this.countDownTimer(countDowntime);
	}//end timer
	
	public countDownTimer(countDowntime:any){
		let now =	new Date().getTime();
		// Find the distance between now an the count down date
		let time = countDowntime - now;
		let minut = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
		let sec = Math.floor((time % (1000 * 60)) / 1000);
		if(this.isTimerOn == false){
			time	=	0;
			
		}
		if(time>0){
			this.remain_time	=	minut+':'+sec+' min left';
			
			let that	=	this;
			setTimeout(function(){
				that.countDownTimer(countDowntime);
			},1000);
			
		}else{
			this.remain_time	=	'0:00 min left';
			this.isTimerOn	=	false;
		}
	}
	
	
	/**function for submit OTP*/
	submitOTPForm() {
		if (this.otpForm.valid) {
			blockUI();
			this.otpForm.value.user_data			=	$("#user_data_id").val();
			this.commonFunctionsService.submitOTPFormEditMobile(this.otpForm.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {		
					unblockUI();
					this.otpFormStatus		=	res.data.success;	
					if(this.otpFormStatus == true){
							$('body').removeClass('modal-open');
							$('#mobile_verify').modal('hide');
							this.userService.updateCurrentUserData();
							let otpType	=	$("#otp_type").val();
							if(otpType != 'email'){
								$("#submit_edit_mobile_number").trigger('click');
							}else{
								$("#submit_edit_email").trigger('click');
							}
							showSuccessCenter(res.data.message);
					}else{
						this.otpFormError 	= res.data.errors;
					}
			});
		} else {
			unblockUI();
			ValidationService.validateAllFormFields(this.otpForm);
		}
	}//end submitOTPForm()
	
	
	/**function for resend otp */
	public resend_otp(){
		blockUI();
		/**make object of form and append user id to form */
		let formData        =   new FormData();
		let method_name		=	$("#resend_otp_method_id").val();
		this.user_data		=	$("#user_data_id").val();
		formData.append('user_data',this.user_data);
		formData.append('method_name',method_name);
		this.commonFunctionsService.resendOTPForEditMobile(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
			if(res.data.status == 'success'){
				let otp_type	=	$("#otp_type").val();
				if(otp_type	==	'email'){
					this.otpTypeEmail	=	true;
				}else{
					this.otpTypeEmail	=	false;
				}
				setTimeout(function(){
					$('#mobile_verify').modal('show');
					$('body').addClass('modal-open');
					showSuccessMessageTopRight(res.data.message);
				},1000);
				this.isTimerOn	=	true;
				this.timer(this.resendMailTime);
				
			}
			unblockUI();
		});
	}//end resend_otp()
  
  
 ngOnDestroy() {
        this.ngUnsubscribe.next();
   }


}
