import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import {CommonFunctionsService} from '../../../../_services/index';
import { FooterService } from "../../../shared/service/footer.service";
import { ValidationService } from '../../../../_services/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface MailChimpResponse {
  result: string;
  msg: string;
}

declare var $:any;

@Component({
	selector: 'email-subscribe-form',
	templateUrl: './email-subscribe-form.component.html',
	providers : [CommonFunctionsService,FooterService,ValidationService]
})

export class EmailSubscribeForm implements OnInit, OnDestroy {
	
	/**This is used to prevent memory leaks **/
		private ngUnsubscribe: Subject<void> = new Subject<void>();	
    /**This is used to prevent memory leaks **/	
	env							=		environment;
	SITE_IMAGE_URL				:		any		=	'';
	newsletterForm				: 		FormGroup;
	newsletterFormError			: 		any = {};
	newsletterFormStatus		: 		any = ""; 
	
	submitted = false;
	mailChimpEndpoint = 'https://us12.api.mailchimp.com/3.0/lists/9eabdc372b/members/';
	

	constructor(public router: Router, private formBuilder: FormBuilder,private commonFunctionsService:CommonFunctionsService,private FooterService: FooterService){
	}
	
	ngOnInit() {
	   /**This form are used for send newsletter **/
	   this.createNewsletterForm();
	   this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
				if(isBrowser){
	  
				}
	   });		
	}
	
	/**This function is used to prevent memory leaks **/
	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
    }
	
	/**This form are used for send newsletter **/
	 public createNewsletterForm() {
		this.newsletterForm 		= this.formBuilder.group({
		   'email'					:   ['', [Validators.required, Validators.email]],
		});
    }
	
	
	public onNewsletterSubmit() {	
	   this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
				if(isBrowser){
					  blockUI();
					  if (this.newsletterForm.invalid ) {
						unblockUI();
						ValidationService.validateAllFormFields(this.newsletterForm);
						return;
					  }
      
					  /** Api are used to send newsletter data **/
					  this.FooterService.submitNewsletterForm(this.newsletterForm.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
						  this.newsletterFormStatus = res.data;
						  if (this.newsletterFormStatus.status == this.env.SUCCESS_STATUS) {
								unblockUI();
								this.newsletterForm.reset();
								showSuccessCenter(res.data.message);
								this.router.navigate(['/']);
						  } else {
								this.newsletterForm.reset();
								showErrorCenter(res.data.message);
								unblockUI();
						  }
					  });
					  //Call API
				}
	    });
    }

	

	
}
