import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService } from '../../../../_services/index';
import { UserService } from '../../../../_services/user.service';
import {GlobalApp} from '../../../../_helpers/index';
import { isPlatformBrowser } from '@angular/common';
import { TextSetting } from '../../../../textsetting';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SeoService } from '../../../../_services/seo.service';
import {CommonFunctionsService} from '../../../../_services/index';
declare var $:any;
@Component({
  selector: 'app-mobile-verification',
  templateUrl: './mobileverification.component.html'
})

export class MobileVerificationComponent implements OnInit,OnDestroy {
	
	private ngUnsubscribe:Subject<any> = new Subject();	
	
	/**OTP form variable */
	userData					:any	=	this.userService.getCurrentUser();
	otpFormDashboard			:	FormGroup;
	slug						:	string	=	'';
	otpFormError				:	any		=	{};
	otpFormStatus				:	any	=	'';
	isTimerOn					:	boolean	=	false;
	remain_time					:	string	=	'0:00 min left';
	resendMailTime				:	number	=	0;
	env							=	environment;
	SITE_IMAGE_URL				:   string = "";
	
	/**OTP form variable */

	constructor(
		private route: ActivatedRoute,
		public router: Router,
		private formBuilder: FormBuilder,
		public commonFunctionsService : CommonFunctionsService,
		private userService: UserService
	) {
			this.SITE_IMAGE_URL = this.env.SITE_IMAGE_URL;	
			this.resendMailTime		=	this.env.RESEND_MAIL_TIME_IN_SECOND;			
	}

  	ngOnInit() {
		/*********** This is used for validate the login form ************/	
		this.createDashboardOTPForm();
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
					let that		=	this;
					$("#dashboard_mobile_verification").click(function(){
						that.sendOTP();
					});					
				}
		});
	}
	
	/**This function are used for initialize the otp form **/
	public createDashboardOTPForm(){
		this.otpFormDashboard			=	this.formBuilder.group({ 
			'slug'				:	[''],
			'one_time_password'	:	['', Validators.required],
		});
	}//end createDashboardOTPForm()
	
	
	/**function for send otp */
	public sendOTP(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
				    blockUI();
					let that		=	this;
					this.slug		=	this.userData.slug;
					let formData    =   new FormData();
					formData.append('slug',this.slug);
					this.commonFunctionsService.resendOTP(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
						if(res.data.status ==	this.env.SUCCESS_CONSTANT){
								unblockUI();
								showSuccessMessageTopRight(res.data.message);
								$('#dashboard_mobile_verify').modal('show');
								that.timer(this.resendMailTime);
								this.isTimerOn	=	true;
						}else{
							showErrorMessageTopRight(res.data.message);
							unblockUI();
						}
					});
			   }
		});
	}
	
	
	/**function for resend otp */
	public resend_otp(){
		
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
				    blockUI();
					/**make object of form and append user id to form */
					this.slug		=	this.userData.slug;
					let formData        =   new FormData();
					formData.append('slug',this.slug);
					this.commonFunctionsService.resendOTP(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
						let that	=	this;
						that.timer(this.resendMailTime);
						this.isTimerOn	=	true;
						if(	res.data.status ==	this.env.SUCCESS_CONSTANT){
							showSuccessMessageTopRight(res.data.message);
						}
						unblockUI();
					});
			   }
		});
	}//end resend_otp()
	
	
	/**This function are used for submit otp form **/
	public submitOTPForm() {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
				   let that				=	this;
					if (this.otpFormDashboard.valid) {
						this.otpFormDashboard.value.slug	=	this.userData.slug;
						this.commonFunctionsService.submitOTPForm(this.otpFormDashboard.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
								this.otpFormStatus	= res.data.status;	
								if(this.otpFormStatus == this.env.SUCCESS_CONSTANT)
								{
									this.otpFormError			=	'';
									this.otpFormDashboard.reset();
									$('body').removeClass('modal-open');
									$('#dashboard_mobile_verify').modal('hide');
									showSuccessMessageTopRight(res.data.message);
									setTimeout(function(){
										that.userService.updateCurrentUserData();
										unblockUI();
									},1000);
								}else{
									this.otpFormError 	= res.data.errors;
									unblockUI();
								}
						});
					}else{
						unblockUI();
						ValidationService.validateAllFormFields(this.otpFormDashboard);	
					}
			   }
		});
	}
	
	
	
	/**function for timer */
	public timer(time:any){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
					let currentdate =	new Date().getTime();
					let countDowntime	=	currentdate+(time*1000);
					this.countDownTimer(countDowntime);
			   }
		});
	}//end timer
	
	
	
	public countDownTimer(countDowntime:any){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
					let now =	new Date().getTime();
					// Find the distance between now an the count down date
					let time = countDowntime - now;
					let minut = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
					let sec = Math.floor((time % (1000 * 60)) / 1000);
					if(time>0){
						this.remain_time	=	minut+':'+sec+' min left';
						let that	=	this;
						setTimeout(function(){
							that.countDownTimer(countDowntime);
						},1000);
					}else{
						this.remain_time	=	'0:00 min left';
						this.isTimerOn	=	false;
					}
			   }
		});
	}
	
	
	
	
	ngOnDestroy() {
        this.ngUnsubscribe.next();
    }
}
