import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { CommonFunctionsService } from '../../../../_services/index';
import { FooterService } from "../../../shared/service/footer.service";
import { ValidationService } from '../../../../_services/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../../../_services/user.service';
import { HomeService } from '../../../home/service/home.service';
import { HttpClientModule } from '@angular/common/http';

interface MailChimpResponse {
  result: string;
  msg: string;
}

declare var $:any;

@Component({
	selector: 'home-page-search',
	templateUrl: './home-page-search.component.html',
	providers : [CommonFunctionsService,FooterService,ValidationService,UserService]
})

export class HomePageSearchComponent implements OnInit, OnDestroy {
	
	/**This is used to prevent memory leaks **/
		private ngUnsubscribe: Subject<void> = new Subject<void>();	
    /**This is used to prevent memory leaks **/	
	env							=		environment;
	SITE_IMAGE_URL				:		any		=	'';
	userData					:	any		=	[];
	isBrowser			 		:   boolean		=	false;

	homeCountryData					:	any		=	[];
	homeCastData					:	any		=	[];
	homeSubCastData					:	any		=	[];
	genderData						:	any		=	[];
	minAgeData						:	any		=	[];
	maxAgeData						:	any		=	[];
	motherToungueData				:	any		=	[];
	homeStateData					:	any		=	[];
	homeReligionData				:	any		=	[];
	homePageSearchForm				:	FormGroup;	
	
	constructor(public HomeService: HomeService, public router: Router, private formBuilder: FormBuilder,private commonFunctionsService:CommonFunctionsService,private FooterService: FooterService,public userService:UserService){
		 this.SITE_IMAGE_URL = this.env.SITE_IMAGE_URL;
		 this.createHomePageSearchForm()
	}
	
	ngOnInit() {
	   this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.isBrowser		=	true;

				let that	=	this;
				if(this.userService.getCurrentUser() != 'undefined' && this.userService.getCurrentUser() != null){
					this.getLoginUserData();
				}
				
				/*This function are used for the home seraching data */	
				this.getHomeSerachingData();
				
				/**function for looking_for change */
				$(document).on('change','#looking_for select',function(){
					let selectval	=	$('#looking_for select').val();
					that.homePageSearchForm.get('gender').setValue(selectval);
				});
				/**function for looking_for change */
				
				/**function for minage change */
				$(document).on('change','#minage_home select',function(){
					let selectval	=	$('#minage_home select').val();
					that.homePageSearchForm.get('minage').setValue(selectval);
					let temp	=	[];
					temp[0]	=	{'id':'','text':'Choose'};
					let counter	=	1;
					for(let i = selectval;i<=80; i++){
						temp[counter]	=	{'id':i,'text':i};
						counter=	counter+1;
					}
					that.maxAgeData	=	temp;
					that.homePageSearchForm.get('maxage').setValue('');
				 });
				/** function for minage change */
				
				/**function for maxheight change */
				$(document).on('change','#maxage_home select',function(){
					let selectval	=	$('#maxage_home select').val();
					that.homePageSearchForm.get('maxage').setValue(selectval);
				});
				/**function for maxheight change */
				
				/**function for country value get  */
				$(document).on('change','#country_id select',function(){
					let selectCountryVal	=	$('#country_id select').val();
					that.homePageSearchForm.get('residing_country').setValue(selectCountryVal);
					if(selectCountryVal=='' || selectCountryVal==null){	
						selectCountryVal	=	0;
					}
					that.getstateList(selectCountryVal);
					that.homePageSearchForm.get('residing_state').setValue('');				
				});
				/**function for state change */
				$(document).on('change','#state_id select',function(){
					let selectState	=	$('#state_id select').val();
					that.homePageSearchForm.get('residing_state').setValue(selectState);
				});
				/**function for state change */

				/**function for religion value get  */
				$(document).on('change','#religion_id_home_page select',function(){
					let selectReligionVal	=	$('#religion_id_home_page select').val();
					that.homePageSearchForm.get('religion').setValue(selectReligionVal);
					if(selectReligionVal=='' || selectReligionVal==null){	
						selectReligionVal	=	0;
					}
					that.getCasteList(selectReligionVal);	
					that.homePageSearchForm.get('cast_id').setValue('');			
				});
				
				/**function for caste change */
				$(document).on('change','#caste_id select',function(){
					let selectval	=	$('#caste_id select').val();
					that.homePageSearchForm.get('cast_id').setValue(selectval);
					if(selectval=='' || selectval==null){	
						selectval	=	0;
					}
					that.getSubCasteList(selectval);	
					that.homePageSearchForm.get('sub_cast_id').setValue('');	
				});
				/**function for caste change */
				
				
				/**function for sub caste change */
				$(document).on('change','#sub_caste_id select',function(){
					let selectSubval	=	$('#sub_caste_id select').val();
					that.homePageSearchForm.get('sub_cast_id').setValue(selectSubval);	
				});
				/**function for sub caste change*/
				
				/**function for mother_tongue change */
				$(document).on('change','#mother_tongue_id select',function(){
					let selectval	=	$('#mother_tongue_id select').val();
					that.homePageSearchForm.get('mother_tongue').setValue(selectval);
				});
				/**function for mother_tongue change */
			}
	   });		
	}
	
	
	/*This function are used for create dashboard left search */
	public createHomePageSearchForm(){
		this.homePageSearchForm	=	this.formBuilder.group({
			'gender'				: [''],
			'minage'					: [''],
			'maxage'  					: [''],
			'residing_country'  		: [''],
			'residing_state'  			: [''],
			'religion'					: [''],
			'cast_id'  					: [''],
			'sub_cast_id'				: [''],
			'mother_tongue'  			: [''],
			'slug'  					: [''],
		});	
	}
	
	
	/** This function are used for search the partner*/
	public submitSearch(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				$('body').removeClass('modal-open');
				$('#home-page-search-popup').modal('hide');
				this.homePageSearchForm.value.slug		=	this.userData.slug;
				this.router.navigate(['/search-result'],{ queryParams: this.homePageSearchForm.value}); 
			}
		});
	}
	
	/**This function is used to prevent memory leaks **/
	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
    }
	
	/**This function are used for get the login user data detail **/
	public getLoginUserData(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.userData				=	this.userService.getCurrentUser();
			}
		});
	}
	
	/**This function are used for search the home page data */
	public getHomeSerachingData(){
		blockUI();
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.HomeService.getHomeSerachData().then((res: any) => {
					this.homeCountryData		=	res.data.countries;
					this.homeCastData			=	res.data.cast;
					this.homeSubCastData		=	res.data.sub_cast;
					this.genderData				=	res.data.gender_new;
					this.minAgeData				=	res.data.age_range;
					this.maxAgeData				=	res.data.max_age_range;
					this.motherToungueData		=	res.data.mother_tongue;
					this.homeStateData			=	res.data.state;   
					this.homeReligionData		=	res.data.religion;
					let religionOther			=	this.env.RELIGION_OTHER_VALUE;
					let motherToungueOther		=	this.env.MOTHER_TONG_OTHER_VALUE;
					const filteredItems 		=   this.homeReligionData.filter(function(item) {
							return item.id !== religionOther
					})
					this.homeReligionData		=	filteredItems;
					
					const filteredMotherItems	=	this.motherToungueData.filter(function(item) {
							return item.id !== motherToungueOther
					})
					this.motherToungueData		=	filteredMotherItems;
					unblockUI();
				});
			}
		});
	}

	/**This function are used for get the state date in country wise **/
	public getstateList(countryId:any){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				this.HomeService.getStateData(countryId).then((res: any) => {
					if(res){
						this.homeStateData		=	res.data;
					}
					unblockUI();
				});
			}
		});
	}

	/**This function are used for get caste data in relgion wise **/
	public getCasteList(relgionId:any){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				this.HomeService.getCasteData(relgionId).then((res: any) => {
					if(res){
						this.homeCastData		=	res.data.cast;
					}
					unblockUI();
				});
			}
		});
	}
	
	
	/**This function are used for get the sub cast data in cast wise **/
	public getSubCasteList(castId:any){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				this.HomeService.getSubCasteData(castId).then((res: any) => {
					if(res){
						this.homeSubCastData		=	res.data.subcast;
					}
					unblockUI();
				});
			}
		});
	}
	
	
	public beforeLoginSubmitSearch(){
		$('body').removeClass('modal-open');
		$('#home-page-search-popup').modal('hide');
		setTimeout(function(){
			unblockUI();
			$('#login').modal('show');
		},1000);
	}
	
}
