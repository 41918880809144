import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService } from '../../../../_services/index';
import { UserService } from '../../../../_services/user.service';
import {GlobalApp} from '../../../../_helpers/index';
import { isPlatformBrowser } from '@angular/common';
import { TextSetting } from '../../../../textsetting';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SeoService } from '../../../../_services/seo.service';
import {CommonFunctionsService} from '../../../../_services/index';

import { AuthService } from "angular4-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angular4-social-login";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers : [SeoService,CommonFunctionsService,ValidationService,UserService]
}) 
export class LoginComponent implements OnInit,OnDestroy {
	
  /**This is used to prevent memory leaks **/
  private ngUnsubscribe: Subject<void> = new Subject<void>();	
  /**This is used to prevent memory leaks **/		
  
  form						:	FormGroup;	
  emailError  				:	string	=	'';
  passwordError  			:	string	=	'';
  emailValid				:	boolean	=	true;
  passwordValid				:	boolean	=	true;
  loginFormStatus			:	any 	=	{};
  loginFormErr				:	any 	=	{};	
  loginFormErrorMsg			:	any		=	{};
  loginFormSuccessMsg		:	any		=	{};
  SITE_IMAGE_URL			:   string 	= 	"";
  user						:	any		=	{};
  remeberMeData				:	any		=	{};
  remeberMeValue			:	any		=	{};
  emailValue				:	any		=	{};
  rememberMeValue			:	boolean	=	false;
  returnUrl					:	string	=	'';
  env 						= 	environment;
  TextSetting 				=   TextSetting;
  SITE_TITLE				:	string		=	'';
  mobileVerificationError		:	any		=	{};
  mobileVerificationErrorMsg	:	any		=	{};
  mobileVerificationStatus	:	any		=	{};
  mobileVerificationSuccessMsg :  any		=	{};
  
  
  constructor(private route: ActivatedRoute,public router: Router,private formBuilder: FormBuilder,private userService: UserService,public sessionUser:GlobalApp,private commonFunctionsService:CommonFunctionsService,private seo: SeoService,	private authService: AuthService) {
    this.SITE_IMAGE_URL 	= 	this.env.SITE_IMAGE_URL;
    this.SITE_TITLE 		= 	this.env.SITE_TITLE;
	this.emailError			=	this.env.VALID_EMAIL_ADDRESS_MSG;
	this.passwordError 		=	this.env.VALID_PASSWORD_MSG;
	
	/**This function are used for create login form **/
	 this.createLoginForm();	
		
	}
   
   
  /*********** Facebook Login ************/
	signInWithFB(): void {
		this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
	}
	
	/*********** Google Login ************/
	signInWithGoogle(): void {
		this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
	}
	
	signOut(): void {
		this.authService.signOut();
	}
   

  ngOnInit() {
		/**This function are used for set the title of home page **/
	  
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
		if(isBrowser){
						 if(this.userService.getCurrentUser() != 'undefined' && this.userService.getCurrentUser() != null){
								this.router.navigate(["/"]);
						 }
						
						$('input[type="checkbox"]').click(function(){
							if($(this).is(":checked")){
								$("#remeber_me_change_value").val(1);		
							}else{
								$("#remeber_me_change_value").val('');	
							}
						});
					
						this.remeberMeValue		=	JSON.parse(localStorage.getItem('remeberMeData'));
						if(this.remeberMeValue != '' && this.remeberMeValue != null){
								this.rememberMeValue		=	true;
								$("#remeber_me_change_value").val(1);
						}else{
								this.rememberMeValue		=	false;
								$("#remeber_me_change_value").val('');
						}
					
						/** check remeber me or not **/
						this.remeberMeValue		=	JSON.parse(localStorage.getItem('remeberMeData'));
						if(this.remeberMeValue != '' && this.remeberMeValue != null){
									this.form.value.email		=	this.remeberMeValue.email;
									this.emailValue				=	this.remeberMeValue.email;
									this.rememberMeValue		=	true;
									$("#remeber_me_change_value").val(1);
						}else{
									this.form.value.email		=	'';
									this.emailValue				=	'';
									this.rememberMeValue		=	false;
									$("#remeber_me_change_value").val('');
						}
						
						/**This function are used for open forgot password popup **/
						$(".forget_pass").click(function(){
								$("#forgotModal").modal('show');
						});
						
						/**This function are used for submit the login form as a enter button**/
						let that	=	this;
						$("#loginPassword,#loginEmail").keyup(function(event) {
							if (event.keyCode === 13) {
								that.onSubmit();
							}
						});
						
						/*This function are used for open for forgot password popup */
						$(document).on('click', "#forgot_password" , function(e) {
							blockUI();
								$('body').removeClass('modal-open');
								$('#login').modal('hide');
								setTimeout(function(){
									unblockUI();
									$('#forgot').modal('show');
								},1000);
						});
						
						
						/*********** open login popup ************/
						 $(document).on('click', "#login_from_forgot" , function(e) {
							blockUI();
							$('body').removeClass('modal-open');
							$('#forgot').modal('hide');
								setTimeout(function(){
									unblockUI();
									$('#login').modal('show');
								},1000);
						});
						
						 $(document).on('click', "#sign_up_free" , function(e) {
							blockUI();
							$('body').removeClass('modal-open');
							$('#login').modal('hide');
								setTimeout(function(){
									unblockUI();
									$('#sign_up_one').modal('show');
								},1000);
						});
						
					// For Social Login
					this.authService.authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
						this.user = response;
						if(this.user !=  null){
							let userData:any				=	localStorage.setItem('facebookData',JSON.stringify(this.user));
							/*Check User have an valid or not for login or sigup */
							if(this.userService.getCurrentUser() == null){
								this.checkValidUser();
							}
							/*Check User have an valid or not for login or sigup */
						}	
					});	
				}
		});
	
  }
  
  
  /**This function are used for create login form **/
  public createLoginForm(){
	 /*********** This is used for validate the login form ************/	
	this.form	=	this.formBuilder.group({
		'email'  		: [null],
		'password' 		: [null],
		'remember_me'   : []
	}); 
  }
  
  
  
  
  /**This function are used for destroy the data**/
   ngOnDestroy(){
	this.ngUnsubscribe.next();
  }
  
  /************** This function are used for submit the login form ****************************/
	public onSubmit() {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
		if(isBrowser){
			blockUI();
			let chekedBoxValue		=	$("#remeber_me_change_value").val();
			let that		=	this;
			let email		=	$('#loginEmail').val();
			let password	=	$('#loginPassword').val();
			
			if(email	==	''){
				that.emailValid	=	false;
			}
			if(password	==	''){
				that.passwordValid	=	false;
			}
			that.form.value.email				=	email;
			that.form.value.password			=	password;
			if (that.form.valid && that.emailValid && that.passwordValid) {
				that.commonFunctionsService.submitLoginForm(this.form.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
					if(response.data.status	== this.env.SUCCESS_STATUS){
						if(chekedBoxValue == 1){
							this.remeberMeData['email']			=	that.form.value.email;
							this.remeberMeData['remeber_me']	=	true;
							localStorage.setItem('remeberMeData',JSON.stringify(this.remeberMeData));
						}else{
							localStorage.removeItem('remeberMeData');
						}
						let loginuserData	=	response.data.userData;
						unblockUI();
						let loginUserToken			=	response.data.token;
						let loginUserRefreshToken	=	response.data.refreshToken;
						this.userService.setAuth(loginuserData);
						this.userService.setToken(loginUserToken);
						this.userService.setRefreshToken(loginUserRefreshToken);
						setTimeout(function(){
							$("#userDataUpdated").trigger('click');
						},1000);
						showSuccessMessageTopRight(response.data.message);
						setTimeout(function(){
							that.redirectAfterLogin(loginuserData);
						},2000);
						$('body').removeClass('modal-open');
						$('#login').modal('hide');
						
					}else{
						unblockUI();
						showErrorMessageTopRight(response.data.message);
					}
				});
			} else {
				unblockUI();
				ValidationService.validateAllFormFields(that.form);
			}
		} 
		});
	}
	
	
	
	/************** This function are used for check email fill or not ****************************/
	public changeEmail(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				let that	=	this;
				let email	=	$('#loginEmail').val();
				if(email	==	''){
						that.emailValid	=	false;
				}else{
					if($.isNumeric($('#loginEmail').val())){
							that.emailValid	=	true;	
					}else{
						if( !(that.validateEmail(email))) {	
							that.emailError		=	environment.VALIDATE_EMAIL_ADDRESS_MSG;	
							that.emailValid		=	false;
						}else{
							that.emailValid	=	true;
						}
					}
				}
			}
		});
	}
	
	
	/************** This function are used for check email valid or not ****************************/
	public  validateEmail($email) {
		  var emailReg = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
		  return emailReg.test( $email );
	}
	
	
	/************** This function are used for check password fill or not ****************************/
	public changePassword(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				let that	=	this;
				let password	=	$('#loginPassword').val();
				if(password	==	''){
					that.passwordValid	=	false;
				}else{
					that.passwordValid	=	true;	
				}
			}
		});
		}
	

	/**function is used to redirect after login */
	redirectAfterLogin(userData:any){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
			this.route.queryParams.subscribe(params => {
				if(typeof(params.returnUrl) != 'undefined'){
					this.returnUrl	=	params.returnUrl;
				}
			});
			if(this.returnUrl != ''){
				this.router.navigate([this.returnUrl]);
			}else{
				
				if(userData.profile_completed_step ==	0){
						this.router.navigate(['/profile']);
				}else if(userData.profile_completed_step == 1){
						this.router.navigate(['/profile/profile-two']);
				}else if(userData.profile_completed_step == 2){
					this.router.navigate(['/profile/profile-three']);
				}else if(userData.profile_completed_step == 3){
					this.router.navigate(['/profile/profile-four']);
				}else if(userData.profile_completed_step == 4){
						this.router.navigate(['/profile/profile-five']);
				}else if(userData.profile_completed_step == 5){
						this.router.navigate(['/profile/partner-preference']);
				}else if(userData.profile_completed_step == 6){
						this.router.navigate(['/dashboard']);
				}
			  }
			}
		 });
		}
	
	
	/*This function are used for off the login popup */
	public clickToOff(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
					this.router.navigate(['/']);	
					$('#login').modal('hide');
			}
		});
	}
	
	/**Function are used for go to redirect home page**/
	public goToHomePage(){
		this.router.navigate(['/']);	
	}
	
	
	
	/*This function are used for check social the user valid or not */
	 public checkValidUser(){
		 this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				let that	=	this;
				this.commonFunctionsService.checkValidUser(this.user).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {	
					blockUI();
					this.mobileVerificationStatus	= res.data.success;
					if(this.mobileVerificationStatus == true)
					{	
							/* Only show the first time popup*/
							this.authService.signOut();
							/* Only show the first time popup*/
							
							let loginuserData			=	res.data.userData;
							let loginUserToken			=	res.data.token;
							let loginUserRefreshToken	=	res.data.refreshToken;
							this.userService.setAuth(loginuserData);
							this.userService.setToken(loginUserToken);
							this.userService.setRefreshToken(loginUserRefreshToken);
							setTimeout(function(){
								$("#userDataUpdated").trigger('click');
							},1000);
							showSuccessMessageTopRight(res.data.message);
							setTimeout(function(){
								that.redirectAfterLogin(loginuserData);
							},2000);
							$('body').removeClass('modal-open');
							$('#login').modal('hide');
							/*for login user*/
							unblockUI();
					}else{
						this.mobileVerificationError 	= res.data.errors;
						if(this.mobileVerificationError == 'mobile_unverified'){
							this.mobileVerificationError			=	'';
							this.mobileVerificationErrorMsg			= 	res.data.message;
							showErrorMessageTopRight(this.mobileVerificationErrorMsg);
							
							/* Only show the first time popup*/
							this.authService.signOut();
							/* Only show the first time popup*/
							
							/*for show the popup of mobile verification */
								$('body').removeClass('modal-open');
								$('#login').modal('hide');
							/*for show the popup of mobile verification */
						}
						if(this.mobileVerificationError == 'empty_errors'){
							this.mobileVerificationError			=	'';
							this.mobileVerificationErrorMsg	= 	res.data.message;
							showErrorMessageTopRight(this.mobileVerificationErrorMsg);
						}
						if(this.mobileVerificationError == 'new_user'){
							/* Only show the first time popup*/
							this.authService.signOut();
							/* Only show the first time popup*/
							
							/*for show the popup of mobile verification */
								   $('body').removeClass('modal-open');
									$('#login').modal('hide');
									setTimeout(function(){
										$("#sign_up_one").modal('show');
										$("#fill_up_social_data").trigger('click');
									},500);
							/*for show the popup of mobile verification */
						}
						unblockUI();
					}
			});
			}
		 });
	}
		
	
	
}
