﻿import {Inject, PLATFORM_ID,Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
import { UserService } from "../_services/user.service";
import { isPlatformBrowser } from '@angular/common';
@Injectable()
export class AuthGuard implements CanActivate {
	env		=	environment;
    constructor(private router: Router,private userService: UserService ,@Inject(PLATFORM_ID) private platformId: Object) { }
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if(isPlatformBrowser(this.platformId)) {	 
					let currentUser 	=	this.userService.getCurrentUser();
					if (typeof currentUser != 'undefined' && currentUser != null) {		    			
						return true;
					}else{
						
						showErrorMessageTopRight(this.env.INVALID_ACCESS_URL);
					}
					/** not logged in so redirect to login page with the return url and return false **/
					this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
					return false;
				}
		}
    }