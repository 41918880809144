import { Injectable , Inject, PLATFORM_ID }    from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { HttpClient } from "@angular/common/http";
import {  FormGroup, Validators,FormControl } from '@angular/forms';
import { environment } from '../../environments/environment';
import { SharedService } from "../modules/shared/service/shared.service";
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject ,  ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { Router,ActivatedRoute,ParamMap } from '@angular/router';
@Injectable()

export class CommonFunctionsService {    
	
	private isBrowserSubject = new ReplaySubject<boolean>(0);
	public isBrowser = this.isBrowserSubject.asObservable();
	
	/**define valiables */
	data				:	any		=	{};
	paramsSub			:   any		=	'';
	SITE_API_URL		:	any		=	'';
	env					=	environment;

	/**define constructor */
  	constructor(public route:ActivatedRoute, private httpClient:HttpClient,public SharedService : SharedService,@Inject(PLATFORM_ID) private platformId: Object) { 
		if(isPlatformBrowser(this.platformId)){
			this.isBrowserSubject.next(true);
		}
		this.SITE_API_URL		=	environment.API_URL;
	  }
	  

	/** Funciton used to return the block data  **/	
	getBlock(slug: string){
		return this.SharedService.getRequest(this.SITE_API_URL+'block/'+slug);
	}

	getSocialLinksUrl(){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-social-links');
	}
	
	/** This function are used for submit the login form **/
	submitLoginForm(data:any){
		return this.SharedService.getPost(this.SITE_API_URL+'login',data);
	}
	
	/** This function are used for check valid user **/
	checkValidUser(user:any){
		return this.SharedService.getPost(this.SITE_API_URL+'verify-user',user);
	}
	
	/**This function are used for forgot password **/
	forgotPassword(data:any){
		return this.SharedService.getPost(this.SITE_API_URL+'forgot',data);
	}
	
	/**This function are used for reset password **/
	resetPassword(data:any){
		return this.SharedService.getPost(this.SITE_API_URL+'reset-password',data);
	}
	
	
	/** Funciton used to return the master data  **/	
	getMasterData(){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-signup-master');
	}
	
	/*This function are used for fetch the profile by */
	getProfileByData(profile_for:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-create-profile-by/'+profile_for);
	}
	
	/**This function are used for submit the signup first step **/
	submitSignupFirstForm(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'submit-signup-first',formData);
	}
	
	/**This function are used for submit the signup second step **/
	submitSignupSecondForm(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'submit-signup-second',formData);
	}
	
	
	/**This function are used for resend otp for mobile number**/
	resendOTPForEditMobile(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'resend-otp-for-mobile-number',formData);
	}
	
	/**This function are used for  **/
	submitOTPFormEditMobile(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'submit-otp-for-mobile-number',formData);
	}
	
	/**This function are used for update the phone number **/
	submitUpdateForm(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'update-phone-number',formData);
	}
	
	/**This function are used for update the email address **/
	submitUpdateEmailForm(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'update-email-address',formData);
	}
	
	/**This function are used for get sub cast **/
	getSubCastData(cast:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-sub-cast-signup-page/'+cast);
	}
	
	/**This function are used for mobile verification**/
	submitOTPForm(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'submit-otp-form',formData);
	}
	
	/**This function are used for submit the resend otp form**/
	resendOTP(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'resend-otp',formData);
	}
	
	/**This function are used for account verification **/
	verifyAccount(validateString:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'account-verification/'+validateString);
	}
	
	/**This function are used for resend verification email**/
	resendVerificationMail(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'resend-verification-mail',formData);
	}
	
	/**This function are used for update the local storage **/
	updateCurrentUserData(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'update-current-user/'+slug);
	}

	/**function for get advertisement */
	getAdvertisement(position:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-advertisement/'+position);
	}
	
	/**function for recently updates **/
	getRecentlyUpdates(slug:any){
		let limit	=	this.env.RECENT_UPDATE_LIMIT;
		return this.SharedService.getRequest(this.SITE_API_URL+'get-recent-update/'+slug+'/'+limit);
	}
	
	/** Function for get success stories**/
	getSuccessStories(){
		let limit	=	this.env.SUCCESS_STORY_SLIDER_LIMIT;
		return this.SharedService.getRequest(this.SITE_API_URL+'get-success-story/'+limit);
	}
	
	/*This function are used for get cast list from religion */
	getCasteList(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'get-cast-list-from-religion',formData);
	}
	
	/*This function are used for get cast list from religion */
	getSubCasteList(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'get-sub-cast-list-from-caste',formData);
	}
	
	


}
