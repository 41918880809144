export * from './email-subscribe-form.component';
export * from './dashboard-quick-search.component';
export * from './home-page-search.component';
export * from './login.component';
export * from './forgot.component';
export * from './resetpassword.component';
export * from './signup.component';
export * from './verification.component';
export * from './advertisement.component';
export * from './mobileverification.component';
export * from './recentupdate.component';
export * from './unlock-trust.component';
export * from './successstory.component';
export * from './rightnewmatches.component';
export * from './matchresult.component';
export * from './chat.component';
export * from './editmobileverification.component';
export * from './photo-protect-password.component';
