import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { FooterService } from "../../../shared/service/footer.service";
import { ValidationService, CommonFunctionsService  } from '../../../../_services/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountSettingService } from '../../../accountsetting/service/accountsetting.service';
import { UserService } from '../../../../_services/user.service';
interface MailChimpResponse {
  result: string;
  msg: string;
}

declare var $:any;

@Component({
	selector: 'right-new-matches',
	templateUrl: './rightnewmatches.component.html',
  	providers : [AccountSettingService,UserService,CommonFunctionsService,FooterService,ValidationService]
 
})

export class RightNewMatchesComponent implements OnInit, OnDestroy {
	/**This is used to prevent memory leaks **/
	private ngUnsubscribe: Subject<void> = new Subject<void>();	
	/**This is used to prevent memory leaks **/	
	
	env				=	environment;
	userData		:	any			=	this.userService.getCurrentUser();
	SITE_IMAGE_URL	:	string 		=	"";
	
	/**declare variable */
	newMatches	:	any		=	[];
	resultArray	:	any		=	[];
	

	constructor(public AccountSettingService:AccountSettingService,public router: Router,private route: ActivatedRoute, private formBuilder: FormBuilder,private commonFunctionsService:CommonFunctionsService,private FooterService: FooterService, public userService : UserService){
		this.SITE_IMAGE_URL = this.env.SITE_IMAGE_URL;
	} 

  	ngOnInit() {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				let that		=	this;
				this.getRecentUpdates();
				$('.new_matches_trigger').click(function(){
						that.getRecentUpdates();
				});
			}
   		});		
	}//end ngOnInit()

	/**function for get user privacy option */
	public getRecentUpdates(){
		let viewprofileslug = this.route.snapshot.params['slug'];
		if(typeof(viewprofileslug) == 'undefined'){
			viewprofileslug	=	'';
		}
		this.AccountSettingService.getNewMatches(this.userData.slug,viewprofileslug).then((res:any) => { 
			this.resultArray	=	res.data;
			this.newMatches		=	res.data.search_results;
		});
	}//end getPrivacyOption()

	 
	ngOnDestroy() {
        this.ngUnsubscribe.next();
    }
	
}
