import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';
import { CurrencyPipe } from '@angular/common';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/

@Pipe({name: 'displayPrice'})

export class DisplayPricePipe extends CurrencyPipe implements PipeTransform {
	env = environment;
	
	transform(value: any, args?: any): any {
		return super.transform(value, this.env.CURRENCY_CODE);
	}
}