import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { HomeService } from './service/home.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
/***********Home page routing ************/
const homeRouting = RouterModule.forChild([
    {
        path: '',
        component: HomeComponent
    },
	{
		path: 'reset-password/:validatestring',
		component: HomeComponent
	}
]);


@NgModule({
    imports: [
        homeRouting,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		SharedModule
    ],
    declarations: [
        HomeComponent
    ],
    exports: [RouterModule],
    providers: [HomeService],
    bootstrap: []
})



export class HomeModule {
}
