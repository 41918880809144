import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';

@Injectable()
export class DashboardService {
	
	/**define valiables */
	SITE_API_URL		:	any		=	'';
	data				:	any		=	{};

	/**define constructor */
	constructor(private httpClient:HttpClient,public SharedService : SharedService) {
		this.SITE_API_URL		=	environment.API_URL;
	}

	/*This function are used for fetch the notification list */
	getDashboardCounterStats(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-dashboard-stats/'+slug);
	}

	/**This function are used save the abendon checkouts user*/
	abendonCheckout(planId:any,slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'abendon-checkout/'+planId+'/'+slug);
	}

	/**This function are used for list of primium member that wall plan feature*/
	getPrimimemberForWalls(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-premimum-user-profile-on-wall/'+slug);
	}

	/**This function are used for list of primium member */
	getPrimiummember(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-premimum-user-profile/'+slug);
	}
	
	/**This function are used for list of recent visitors member */
	getRecentVisitorsMember(slug:any,type:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-profile-view/'+slug+'/'+type);
	}
	
	/**This function are used for list of matche member */
	getMatchesMember(slug:any,type:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-user-preffered-matches/'+slug+'/'+type);
	}

	/**function for get blocked user */
	getUsersLeftCounter(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-users-left-counter/'+slug);
	}

	/**function for get left search data */	
	getLeftSerachData(){
		return this.SharedService.getRequest(this.SITE_API_URL+'dashboard-get-left-search-data');
	}

	/**function are used to submit serach form */
	submitSearchForm(submitSearchFormData:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'dashboard-submit-left-search-data',submitSearchFormData);
	}

	/**function are used to search form data */
	submitSearchFormForProfileId(submitSearchFormProfileIdData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'dashboard-submit-left-search-data',submitSearchFormProfileIdData);
	}

	/**function are used to get document type */
	getDocumentTypeInformation(){
		return this.SharedService.getRequest(this.SITE_API_URL+'document-type');
	}

	/**function are document form submit*/
	submitUploadDocumentForm(uploadDocumentForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'upload-document',uploadDocumentForm);
	}
	
	/**This function are used for update the social status**/
	updateSocialStatus(appId:any,slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'verify-social-user/'+appId+'/'+slug);
	}
	
	/*This function are used for fetch the parterner preference data */
	getCasteList(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'get-cast-list-from-religion-dashboard-left',formData);
	}
	
	/*This function are used for fetch the parterner preference data */ 
	getSubCasteList(formData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'get-sub-cast-list-from-caste-dashboard-left',formData);
	}
	
}
  
  
