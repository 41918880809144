import { Directive, ElementRef , HostListener, Input } from '@angular/core';
import {UserService} from '../_services/user.service';
import { Router} from '@angular/router';
import { environment } from '../../environments/environment';
import { TextSetting } from '../textsetting';
import { InboxService } from '../modules/inbox/service/inbox.service';

declare var $:any;	
declare var bootbox:any;

@Directive({
	selector: '[appRemoveinvitation]',
	providers: [InboxService]
})
export class RemoveinvitationDirective {
	@Input('currentIndex') currentIndex: string;
	@Input('invitedUserSlug') invitedUserSlug: string;
	@Input('userDetail') userDetail: any;
	
	TextSetting 						=   TextSetting;

constructor(private el: ElementRef,public router: Router,public userService: UserService,public InboxService: InboxService) { }
  

@HostListener('click') onClick() {
	this.removeInvitation();
}


		/**function for remove invitation */
		public removeInvitation(){
			let userSlug	=	this.userService.getCurrentUser().slug;
			let that		=	this;
			bootbox.confirm(that.TextSetting.DELETE_INVITATION_REQUEST_MSG,
				function(result){
					if(result){
						blockUI();
						that.InboxService.deleteInvitation(that.invitedUserSlug,userSlug).then((res:any)=>{
							if(res.data.success == true){
								showSuccessMessageTopRight(res.data.message);
								let removed_id		=	that.userDetail[that.currentIndex]['id'];
								that.userDetail.splice(that.currentIndex,1);
								$('#leftCounterChange').trigger('click');
								$('#block_unblock_user').val(removed_id);
								$('#block_unblock_user').trigger('click');
							}else{
								showErrorMessageTopRight(res.data.message);
							}
						});
						unblockUI();
					}
				}
			);
		}//end removeInvitation()
}
