import {Inject, PLATFORM_ID,Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../environments/environment';
import { UserService } from "../_services/user.service";
import { isPlatformBrowser } from '@angular/common';
@Injectable()
export class ProfileCompleteUserGuard implements CanActivate {
	env		=	environment;
    constructor(private router: Router,private userService: UserService,@Inject(PLATFORM_ID) private platformId: Object) { }
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		if(isPlatformBrowser(this.platformId)) {	
					 let currentUser 	=	this.userService.getCurrentUser();
					 if (typeof currentUser != 'undefined' && currentUser != null) {
						if(currentUser.profile_completed_step == 6){
							return true;
						}else{
							if(currentUser.is_admin_approved == 2){
								showErrorMessageTopRight(this.env.REJECT_PROFILE_MSG);
							}else{
								showErrorMessageTopRight(this.env.COMPLETE_PROFILE_MSG);
							}
							this.router.navigate(['/profile']);
							return false;		
						}
					 }else{
						 showErrorMessageTopRight(this.env.INVALID_ACCESS_URL);
					 }
					/** not logged in so redirect to login page with the return url and return false **/
					this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
					return false;
				}
		}
    }