import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';

@Injectable()
export class InboxService {
	
	/**define valiables */
	SITE_API_URL		:	any		=	'';
	SITE_URL			:	any		=	'';
	data				:	any		=	{};

	/**define constructor */
	constructor(private httpClient:HttpClient,public SharedService : SharedService) {
		this.SITE_API_URL		=	environment.API_URL;
		this.SITE_URL			=	environment.SITE_URL;
	}

	/**function for get left inbox counter */
	getInboxLeftCounter(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-inbox-counter/'+slug);
	}

	/**function for get success story */
	getInvitationSortByMaster(){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-invitation-sort-by-master');
	}
	
	/**function for get received Invitations */
	getReceivedInvitations(slug:any,type:any,order:any,filter_premium:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-received-invitation/'+slug+'/'+type+'/'+order+'/'+filter_premium);
	}

	/**function for load more result */
	loadMoreResult(url:any){
		return this.SharedService.getRequest(url);
	}

	/** function for submit Invitation Accept form */
	submitInvitationAcceptForm(invitationForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'accept-invitation',invitationForm);
	}

	/** Function to shortlist Multiple Members */
	shortListMultipleMember(shortlistForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'short-list-multiple-users',shortlistForm);
	}

	/** Function to accept multiple invitations */
	acceptMultipleInvitation(acceptForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'accept-multiple-invitation',acceptForm);
	}

	/** Function to accept multiple invitations */
	rejectMultipleInvitation(rejectForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'reject-multiple-invitation',rejectForm);
	}

	/**function for reject invitation */
	rejectInvitation(slug:any,rejectUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'reject-invitation/'+slug+'/'+rejectUserSlug);
	}

	/**function for get sent invitations */
	getSentInvitations(slug:any,type:any,order:any,filter_premium:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-sent-invitation/'+slug+'/'+type+'/'+order+'/'+filter_premium);
	}

	/**function for delete invitation */
	deleteInvitation(slug:any,rejectUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'delete-invitation/'+slug+'/'+rejectUserSlug);
	}

	/** Function to delete multiple invitations */
	deleteMultipleInvitation(rejectForm:any) {  
		return this.SharedService.getPost(this.SITE_API_URL+'delete-multiple-invitation',rejectForm);
	}

	/** Function to remove multiple invitations */
	removeMultipleInvitation(rejectForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'remove-multiple-invitation',rejectForm);
	}

	/** Function to restore multiple invitations */
	restoreMultipleInvitation(rejectForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'restore-multiple-invitation',rejectForm);
		
	}

	/** Function to delete multiple invitations */
	deleteMultipleReceivedInvitation(rejectForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'delete-multiple-received-invitation',rejectForm);
	}

	/**function for restore invitation */
	restoreInvitation(slug:any,rejectUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'restore-invitation/'+slug+'/'+rejectUserSlug);
	}

	/**function for use to acceoted her photo request */
	acceptedByHerPhotoRequest(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-acceptedByher-photorequest/'+slug);
	}

	/**function for resend invitation */
	resendInvitation(slug:any,invitedUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'resend-invitation/'+slug+'/'+invitedUserSlug);
	}

	/** Function to remove multiple invitations */
	resendMultipleInvitation(rejectForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'resend-multiple-invitation',rejectForm);
	}

	/**function for used get recived mesaage **/
	receivedMessageRequest(slug:any,type:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-messages/'+slug+'/'+type);
	}

	/*** function for used to delete message */
	deleteMessages(deleteMessageForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'delete-message',deleteMessageForm);
	}

	/*** function for used to add favourite message */
	favouriteMessages(favouriteMessageForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'favourite-message',favouriteMessageForm);		
	}

	/**function for used to sent message request */
	sentMessageRequest(slug:any,type:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-messages/'+slug+'/'+type);
	}

	/**function for used to message trash request */
	mesaageTrashRequest(slug:any,type:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-messages/'+slug+'/'+type);
	}

	/** function for used to get started message */
	getStarredMeesageRequest(slug:any,type:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-messages/'+slug+'/'+type);
	}

	/** Function to received photo request */
    receivedPhotoRequest(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-received-photorequest/'+slug);		
	}

	 /** Function to accept photo request */
	submitRequestAcceptForm(photRequestForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'accept-photo-rquest',photRequestForm);		
	}

	/** Function to accept multiple photo request */
	acceptMultiplePhotoRequest(acceptForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'accept-multiple-photorequest',acceptForm);		
	}

	/** Function to accept multiple requests */
	rejectMultiplePhotoRequests(rejectForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'reject-multiple-photorequest',rejectForm);
	}

	/*** function for use to recject photo */
	rejectPhotoRequest(slug:any,rejectUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'reject-photo-rquest/'+rejectUserSlug);
	}

	/** Function to sent photo request */
	 sentPhotoRequest(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-sent-photorequest/'+slug);
	}

	/**function for delete invitation */
	deletePhotoRequest(slug:any,rejectUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'delete-photo-request/'+slug+'/'+rejectUserSlug);		
	}
	
	/** Function to delete multiple photo request */
	deleteMultiplePhotoRequest(rejectForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'delete-multiple-photorequest',rejectForm);		
	}

	/**function for revoke photo request */
	revokePhotoRequest(slug:any,rejectUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'revoke-photorequest/'+slug+'/'+rejectUserSlug);		
	}

	/** Function to revoke multiple photo request */
	revokeMultiplePhotoRequest(rejectForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'revoke-multiple-photorequest',rejectForm);
	}

	/**fuction for use to accepted my photo request */
	acceptedByMePhotoRequest(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-acceptedByme-photorequest/'+slug);
	}

	/** Function to get rejected by me */
	rejectedByMePhotoRequest(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-rejectedByme-photorequest/'+slug);
	}

	/** Function to get rejected by me */
	rejectedByherPhotoRequest(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-rejectedByher-photorequest/'+slug);
	}

	/** Function to resend multiple photo request */
	resendMultiplePhotoRequest(resendForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'resend-multiple-photorequest',resendForm);
	}

	 /** Function to resend photo request */
	resendRequest(resendRequestForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'resend-photorequest',resendRequestForm);
	}

	/**This function are used for get plan detail */
	getMessageHistory(relationId:any,slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-user-messages/'+relationId+'/'+slug);
	}

	/**function for use to send message user */
	sendMessage(sendMessageForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'send-message',sendMessageForm);
	}

	/*This function are used for fetch the sub cast */
	getAbuseWords(){
		return this.SharedService.getRequest(this.SITE_URL+'assets/badwords.json');
	}
	
	
	/**Fnction to reject multiple members **/
	rejectMultipleMember(rejectlistForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'reject-list-multiple-users',rejectlistForm);	
	}

}
  
  
