import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap, Router ,Params} from '@angular/router';
import { Title,Meta  }     from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { SharedService } from "../modules/shared/service/shared.service";
@Injectable()
export class GlobalApp {
	env		=	environment;
	constructor(private route: ActivatedRoute,private router: Router,public titleService: Title,private meta: Meta,public sharedService: SharedService) {}
	
	/* 
	* Function to get current_url
	*/
	public getCurrentUrl(){
		let url = this.route.snapshot.params;
		return url;   
    }
   
   /* 
	* Function to set page title
   */
    public setTitle( newTitle: string,siteTitle:string) {
		if(newTitle!=''){
			this.titleService.setTitle(newTitle+" | "+siteTitle);
		}else{
			this.titleService.setTitle(siteTitle);
		}
    }
   
   /**
	This function are used for set the meta keyword & description
   **/
   public setMeta(metaName : string ,content : string){
			this.meta.addTag({name: metaName, content: content});
   }
   
   
   /* 
	* Function to get logged in user detail
	*/
	public getUserData(){
		
		var currentUser = JSON.parse(localStorage.getItem('userData'));
		if (typeof currentUser != 'undefined' && currentUser != null) {		    			
			return currentUser;
	   }
	   else
	   {
			return currentUser;
	   }	
	}
   
}
