import { Directive, ElementRef , HostListener, Input } from '@angular/core';
import {UserService} from '../_services/user.service';
import { Router} from '@angular/router';
import { environment } from '../../environments/environment';
import { TextSetting } from '../textsetting';
import { InboxService } from '../modules/inbox/service/inbox.service';

declare var $:any;	
declare var bootbox:any;

@Directive({
	selector: '[appRejectinvitation]',
	providers: [InboxService]
})
export class RejectinvitationDirective {
	@Input('currentIndex') currentIndex: string;
	@Input('invitedUserSlug') invitedUserSlug: string;
	@Input('userDetail') userDetail: any;
	@Input('pageName') pageName: any;
	
	TextSetting 						=   TextSetting;

constructor(private el: ElementRef,public router: Router,public userService: UserService,public InboxService: InboxService) { }
  

@HostListener('click') onClick() {
	this.rejectInvitation();
}


	public rejectInvitation(){
		let userSlug	=	this.userService.getCurrentUser().slug;
		let that		=	this;
		bootbox.confirm(that.TextSetting.REJECT_INVITATION_MESSAGE,
			function(result){
				if(result){
					blockUI();
					that.InboxService.rejectInvitation(userSlug,that.invitedUserSlug).then((res:any)=>{
						if(res.data.success == true){
							showSuccessMessageTopRight(res.data.message);
							if(that.pageName == 'dashboard'){
								that.userDetail['data'][that.currentIndex]['invited']	=	1;
							}else{
								that.userDetail.splice(that.currentIndex,1);
								$('#leftCounterChange').trigger('click');
							}
						}else{
							showErrorMessageTopRight(res.data.message);
						}
					});
					unblockUI();
				}
			}
		);
	}//end rejectInvitation()
}
