import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';

@Injectable()
export class MatchesService {
	
	/**define valiables */
	SITE_API_URL		:	any		=	'';
	SITE_URL			:	any		=	'';
	data				:	any		=	{};

	/**define constructor */
	constructor(private httpClient:HttpClient,public SharedService : SharedService) {
		this.SITE_API_URL		=	environment.API_URL;
		this.SITE_URL			=	environment.SITE_URL;
	}
	
	/**function for get preferred match */
	getpreferedResult(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'prefferedMatches/'+slug);
	}
	
	

	/**function for get reverse match */
	getReverseMatchResult(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'reverseMatches/'+slug);
	}
	

	/**function for get mutual match */
	getMutualMatchResult(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'mutualMatches/'+slug);
	}
	
	
	/**function for get recomended match */
	getRecomendedResult(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-daily-recommendation/'+slug);
	}
	
	/**function for get mutual match */
	getLoggedInUserDetails(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-logged-in-user/'+slug);
	}
	
	/**function for load more result */
	loadMoreResult(url:any){
		return this.SharedService.getRequest(url);
	}
	
	
	/** Function to get more search result form */
	loadMoreSearchResult(url:any,formData:any) {
		return this.SharedService.getPost(url,formData);
	}
	
	
	/** Function are used for get query result by filter parameter on prefered match **/
	getpreferedResultByFilter(formData:any,slug:any){
		return this.SharedService.getPost(this.SITE_API_URL+'prefferedMatches/'+slug,formData);
	}
	
	/** Function are used for get query result by filter parameter on reverse match **/
	getreverseResultByFilter(formData:any,slug:any){
		return this.SharedService.getPost(this.SITE_API_URL+'reverseMatches/'+slug,formData);
	}
	
	/** Function are used for get query result by filter parameter on mutual match **/
	getmutualResultByFilter(formData:any,slug:any){
		return this.SharedService.getPost(this.SITE_API_URL+'mutualMatches/'+slug,formData);
	}
	
	/** Function to get query search results */
	querySearchResult(formData:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'dashboard-submit-left-search-data',formData);
	}
	
	//handler function added 
    private handleError (error: Response | any) {
        console.error(error.message || error);
        return Promise.reject(error.message || error);
    }
}
  
  
