import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService ,CommonFunctionsService } from '../../../../_services/index';
import { UserActionService } from '../../../user/service/user.service';
import { MatchesService } from '../../../matches/service/matches.service';
import { InboxService } from '../../../inbox/service/inbox.service';
import { DashboardService } from '../../../dashboard/service/dashboard.service';
import { UserService } from '../../../../_services/user.service';
import { GlobalApp} from '../../../../_helpers/index';
import { isPlatformBrowser } from '@angular/common';
import { TextSetting } from '../../../../textsetting';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SeoService } from '../../../../_services/seo.service';
import { KeysPipe } 	from '../../../../_pipes/index';
declare var $:any;
declare var bootbox:any;



@Component({
  selector: 'match-result',
  templateUrl: './matchresult.component.html',
  providers: [UserService,UserActionService,SeoService,CommonFunctionsService,ValidationService,DashboardService,InboxService,MatchesService]
 
})
export class MatchResultComponent implements OnInit,OnDestroy {
	
	private ngUnsubscribe:Subject<any> = new Subject();	
	
	
	/** Dclear the variable constant */
	userData	:any					=	this.userService.getCurrentUser();
	env									=	environment;
	TextSetting 						=   TextSetting;
	SITE_IMAGE_URL						:   string 	=	"";
	isBrowser					 		:   boolean	=	false;
	
	receivedSortBy		:	string	=	'premium';
	invitationForm		:	FormGroup;
	invitationFormError	:	any		=	[];
	searchDataArray		:	any		=	[];
	resultArray       	:	any     =	[];
	sortByMaster		:	any		=	[];
	receivedSearchSelected	:	any		=	[];
	isGridView			:	boolean	=	true;
	isLoadMore			:	boolean	=	false;
	loadMoreUrl			:	string	=	'';
	adPosition			:	number	=	0;
	tempResult			:	any		=	[];
	descriptionLimit	:	number	=	0;
	countryId			:	string	=	'';
	cityId				:	string	=	'';
	isQuery				:	boolean	=	false;
	invitedMemberIndex	:	number	=	0;
	invitedMemberSlug	:	string	=	'';
	loggedInUser		:	any		=	[];
	sortBy				:	any		=	[];
	noResultMessage		:	string	=	'';
	paramsSub			:	any		=	[];
	currentUrl								:		any		=	'';
	
	constructor(public InboxService: InboxService, private commonFunctionsService:CommonFunctionsService,private seo: SeoService,public router: Router,private route: ActivatedRoute,public userService : UserService, private formBuilder: FormBuilder,public PreferredService : MatchesService,public dashboardService : DashboardService,public UserActionService : UserActionService) {
		this.currentUrl = this.router.url.split('?')[0];
		this.SITE_IMAGE_URL = this.env.SITE_IMAGE_URL;
		this.descriptionLimit	=	this.env.DESCRIPTION_LIMIT;
		this.createInvitationForm();
		this.route.queryParams.subscribe(params => {
				this.paramsSub = params;
				if(this.paramsSub != '' && this.paramsSub != null){
					this.getFilterOfSearch();
				}
		});
	}
	
	 
	 ngOnInit() {
		 this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
					let that	=	this;
					/**This function are used for get the search data */
					this.getLoggedInUserDetails();
					if(this.currentUrl	==	'/matches'){
						//this.getPreferedMatchSerachData();
					}else if(this.currentUrl	==	'/matches/reversematches'){
						//this.getRevereseMatchSerachData();
					}else if(this.currentUrl	==	'/matches/mutualmatches'){
						//this.getMutualMatchSerachData();
					}else{
						//this.getQuerySearchResult();
					}
					this.getSortByMaster();
					$(window).resize(function() { 
						setTimeout(function(){
							that.resizeequalheight();
						},250) 
					}); 
					setTimeout(function(){
						that.resizeequalheight();
					},250);
					
					$('.prefered_match_filter_result').click(function(){
						that.getFilterOfSearch();
					});
					
					$('.search_filter').click(function(){
						//that.getQuerySearchResult();
					});
					
					
					
			}
		 });
		
	}//end ngOnInIt()
	
	
	/**This function are used for result by filter **/
	public getFilterOfSearch(){
		let slug							=	this.userData.slug;
		let search_criteria_by_prefered  	=	this.paramsSub;	
		if(this.currentUrl	==	'/matches'){
			this.PreferredService.getpreferedResultByFilter(search_criteria_by_prefered,slug).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any)=>{
				this.getResultOfFilterResult(res.data);
			});
		}else if(this.currentUrl	==	'/matches/reversematches'){
			this.PreferredService.getreverseResultByFilter(search_criteria_by_prefered,slug).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any)=>{
					this.getResultOfFilterResult(res.data);
			});	
		
		}else if(this.currentUrl	==	'/matches/mutualmatches'){
			this.PreferredService.getmutualResultByFilter(search_criteria_by_prefered,slug).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any)=>{
					this.getResultOfFilterResult(res.data);
			});
		}else{
			this.getQuerySearchResult();
		}
	}
	
	
	/** this function are used for get the find result of all seraches **/
	public getResultOfFilterResult(data:any){
		this.tempResult = data.search_results;
		this.resultArray	=	data;
		if(this.resultArray.next_page_url !=	null){
			this.isLoadMore		=	true;
			this.loadMoreUrl	=	this.resultArray.next_page_url;
		}else{
				this.isLoadMore		=	false;
				this.loadMoreUrl	=	'';
		}
		this.setAdPosition();
		let that = this;
		$('.filter_update_on_prefered_match').trigger('click');
		setTimeout(function(){
			that.resultSlider();
		},1000);
		unblockUI();
	}
	
	
	ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
   }
	
	
	
	public resizeequalheight(){ 
		this.equalHeight($(".makeequal")); 
	}
	
	public equalHeight(group) { 
		let tallest = 0; 
		group.height('');
		group.each(function() { 
			let thisHeight = $(this).height(); 
			if(thisHeight > tallest) { 
				tallest = thisHeight; 
			}
		}); 
		group.height(tallest); 
	} 
	
	
	public randomFunction(event, slug){
		 this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
					if(slug != null){
						let checkboxCheck		=	event.target.checked;
						if(checkboxCheck){
							if(this.receivedSearchSelected.length > 0){		
								if(this.receivedSearchSelected.indexOf(slug) > -1){
									showErrorMessageTopRight(this.TextSetting.MEMBER_ALREADY_SELECTED);
								}else{
									this.receivedSearchSelected.push(slug);
								}
							}else{
								this.receivedSearchSelected.push(slug);
							} 
						}else{
							if(this.receivedSearchSelected.length > 0){
								const index: number = this.receivedSearchSelected.indexOf(slug);
								if (index !== -1) {
									this.receivedSearchSelected.splice(index, 1);
								}
							}
							$("#select_all").prop('checked',false);
						}
					}	
			}
		 });			
	}
	
	
	
	public getSortByMaster(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.InboxService.getInvitationSortByMaster().then((res:any)=>{
					this.sortByMaster		=	res.data.sort_by;
				});
			}
		});
	}
	
	/**function for change sort by order */
	public changeReceivedSortBy(sort_by){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.receivedSortBy	=	sort_by;
				this.sortBy			=	this.receivedSortBy;
				let that	=	this;
				$("#sort_by_left").val(that.sortBy);
				$('.sort_by_change').trigger('click');
			}
		});
	}//end changeReceivedSortBy()
	
	
	/**function for change sort by order */
	public changeReceivedSortByInPreferedMatch(sort_by){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				let that		=	this;
				this.receivedSortBy	=	sort_by;
				this.sortBy			=	this.receivedSortBy;
				$("#sort_by_left").val(that.sortBy);
				$('.sort_by_change_in_prefered_match').trigger('click');
			}
		});
	}//end changeReceivedSortBy()
	
	
	

	/**function to get logged in user details */
	public getLoggedInUserDetails(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
					this.PreferredService.getLoggedInUserDetails(this.userData.slug).then((res:any)=>{
						this.loggedInUser	=	res.data;
						this.createInvitationForm();	
					});
			}
		});
	}
	
	
	

	/**function for get result from preferences */
	public getPreferedMatchSerachData(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				this.PreferredService.getpreferedResult(this.userData.slug).then((res:any)=>{
					Array.prototype.push.apply(this.tempResult,res.data.search_results);
					this.resultArray	=	res.data;
					if(this.resultArray.next_page_url !=	null){
						this.isLoadMore		=	true;
						this.loadMoreUrl	=	this.resultArray.next_page_url;
					}
					this.setAdPosition();
					let that = this;
					$('#matchSearchCount').trigger('click');
					setTimeout(function(){
						that.resultSlider();
					},1000);
					setTimeout(function(){
						that.resizeequalheight();
					},250);
					unblockUI();
				});
			}
		});
	}//end getPreferedMatchSerachData()
	
	

	/**function for get serch result for reverse */
	public getRevereseMatchSerachData(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				this.PreferredService.getReverseMatchResult(this.userData.slug).then((res:any)=>{
					Array.prototype.push.apply(this.tempResult,res.data.search_results);
					this.resultArray	=	res.data;
					if(this.resultArray.next_page_url !=	null){
						this.isLoadMore	=	true;
						this.loadMoreUrl	=	this.resultArray.next_page_url;
					}
					this.setAdPosition();
					let that = this;
					$('#matchSearchCount').trigger('click');
					setTimeout(function(){
						that.resultSlider();
					},1000);
					setTimeout(function(){
						that.resizeequalheight();
					},250);
					unblockUI();
				});
			}
		});
	}//end getRevereseMatchSerachData()
	
	
	
	/**function for get serch result for mutual match */
	public getMutualMatchSerachData(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				this.PreferredService.getMutualMatchResult(this.userData.slug).then((res:any)=>{
					Array.prototype.push.apply(this.tempResult,res.data.search_results);
					this.resultArray	=	res.data;
					if(this.resultArray.next_page_url !=	null){
						this.isLoadMore	=	true;
						this.loadMoreUrl	=	this.resultArray.next_page_url;
					}
					this.setAdPosition();
					let that = this;
					$('#matchSearchCount').trigger('click');
					setTimeout(function(){
						that.resultSlider();
					},1000);
					setTimeout(function(){
						that.resizeequalheight();
					},250);
					unblockUI();
				});
			}
		});
	}//end getRevereseMatchSerachData()
	
	
	/**This function are used for get the load more matches **/
	public loadMore(){
		let search_criteria_by_prefered  	=	this.paramsSub;	
		
		if(this.isLoadMore == true && this.loadMoreUrl	!=	''){
		
			if(this.currentUrl	==	'/matches' || this.currentUrl	==	'/matches/mutualmatches' || this.currentUrl	==	'/matches/reversematches' || this.currentUrl	==	'/users/new-matches-members' || this.currentUrl	==	'/users/my-matches-members' || this.currentUrl	==	'/users/blocked-users' || this.currentUrl	==	'/users/recent-visitors-members'){
				if(search_criteria_by_prefered == ''){ 
				
					this.PreferredService.loadMoreResult(this.loadMoreUrl).then((res:any)=>{
						Array.prototype.push.apply(this.tempResult,res.data.search_results);
						this.resultArray	=	res.data;
						if(this.resultArray.next_page_url !=	null){
							this.isLoadMore	=	true;
							this.loadMoreUrl	=	this.resultArray.next_page_url;
						}else{
							this.isLoadMore		=	false;
							this.loadMoreUrl	=	'';
						}
						this.setAdPosition();
						let that = this;
						setTimeout(function(){
							that.resultSlider();
						},1000);
						setTimeout(function(){
							that.resizeequalheight();
						},250);
					});
				}else{
					this.PreferredService.loadMoreSearchResult(this.loadMoreUrl,search_criteria_by_prefered).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any)=>{
					Array.prototype.push.apply(this.tempResult,res.data.search_results);
					this.resultArray	=	res.data;
					if(this.resultArray.next_page_url !=	null){
						this.isLoadMore	=	true;
						this.loadMoreUrl	=	this.resultArray.next_page_url;
					}else{
						this.isLoadMore		=	false;
						this.loadMoreUrl	=	'';
					}
					this.setAdPosition();
					let that = this;
					setTimeout(function(){
						that.resultSlider();
					},1000);
					setTimeout(function(){
						that.resizeequalheight();
					},250);
					});
				}
				
			}else if(this.currentUrl	==	'/users/premium-wall-members' || this.currentUrl	==	'/users'){
				this.PreferredService.loadMoreResult(this.loadMoreUrl).then((res:any)=>{
					Array.prototype.push.apply(this.tempResult,res.data.data);
					this.resultArray	=	res.data;
					if(this.resultArray.next_page_url !=	null){
						this.isLoadMore	=	true;
						this.loadMoreUrl	=	this.resultArray.next_page_url;
					}else{
						this.isLoadMore		=	false;
						this.loadMoreUrl	=	'';
					}
					this.setAdPosition();
					let that = this;
					setTimeout(function(){
						that.resultSlider();
					},1000);
					setTimeout(function(){
						that.resizeequalheight();
					},250);
				});
			}else{
				let search_criteria	=	this.paramsSub;
				this.PreferredService.loadMoreSearchResult(this.loadMoreUrl,search_criteria).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any)=>{
					Array.prototype.push.apply(this.tempResult,res.data.search_results);
					this.resultArray	=	res.data;
					if(this.resultArray.next_page_url !=	null){
						this.isLoadMore	=	true;
						this.loadMoreUrl	=	this.resultArray.next_page_url;
					}else{
						this.isLoadMore		=	false;
						this.loadMoreUrl	=	'';
					}
					this.setAdPosition();
					let that = this;
					setTimeout(function(){
						that.resultSlider();
					},1000);
					setTimeout(function(){
						that.resizeequalheight();
					},250);
					
				});
			}
		}
	}
	
	

	/**function for shortlist member */
	public shortlistMember(shortlistedUserSlug,index){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				let userSlug	=	this.userData.slug;
				let that	=	this;
				bootbox.confirm(that.TextSetting.SHORTLIST_MEMBER_MESSAGE,
					function(result){
						if(result){
							blockUI();
							that.UserActionService.shortListMember(userSlug,shortlistedUserSlug).then((res:any)=>{
								if(res.data.success == true){
									showSuccessMessageTopRight(res.data.message);
									//that.tempResult.splice(index, 1);
									that.tempResult[index]['short_listed']	=	1;
									// let storageData	=	JSON.parse(localStorage.getItem('searchData'));
									// if(storageData != '' && storageData != null && typeof storageData['search_results'][index] != 'undefined'){
										// storageData['search_results'][index]['short_listed']	=	1;
										// localStorage.setItem('searchData',JSON.stringify(storageData));
									// }
									that.getQuerySearchResult();
								}else{
									showErrorMessageTopRight(res.data.message);
								}
							});
							unblockUI();
						}
					}
				);
			}
		});
	}
	
	/**function for rejectList member */
	public rejectlistMember(RejectedUserSlug,index){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				let userSlug	=	this.userData.slug;
				let that	=	this;
				bootbox.confirm(that.TextSetting.IGNORE_MEMBER_MESSAGE,
					function(result){
						if(result){
							blockUI();
							that.UserActionService.rejectListMember(userSlug,RejectedUserSlug).then((res:any)=>{
								if(res.data.success == true){
									that.tempResult		=	[];
									that.resultArray	=	[];
									showSuccessMessageTopRight(res.data.message);
									if(that.router.url	==	'/matches'){
											that.getPreferedMatchSerachData();
									}else if(that.router.url	==	'/matches/reversematches'){
											that.getRevereseMatchSerachData();
									}else if(that.router.url	==	'/matches/mutualmatches'){
											that.getMutualMatchSerachData();
									}else{
											$(".filter_update").trigger('click');
											that.getQuerySearchResult();
									}
								}else{
									showErrorMessageTopRight(res.data.message);
								}
							});
							unblockUI();
						}
					}
				);
			}
		});
	}
	

	/**function for create invitation Form */
	public createInvitationForm(){
		let post_on_wall	=	(this.loggedInUser != '' && this.loggedInUser.post_on_wall_allowed == 1)?true:false;
		this.invitationForm	=	this.formBuilder.group({
			'message'	:	['', Validators.required],
			'post_on_wall':	[post_on_wall],
		});
	}//end createInvitationForm();
	
	
	

	/**function for submit invitation form*/
	public invitationSubmit() {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.invitedMemberSlug	=	$(".invitedMemberSlug").val();
				if (this.invitationForm.valid) {
					blockUI();
					this.invitationForm.value.user_slug		=	this.userData.slug;
					this.invitationForm.value.invited_user_slug	=	this.invitedMemberSlug;
					
					this.UserActionService.submitInvitationForm(this.invitationForm.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any)=>{
						if(res.data.success == true)
						{
							this.invitationFormError			=	'';
							showSuccessMessageTopRight(res.data.message);
							this.createInvitationForm();
							this.tempResult[this.invitedMemberIndex]['invited']	=	1;
							let storageData	=	JSON.parse(localStorage.getItem('searchData'));
							if(storageData != '' && storageData != null && typeof storageData['search_results'][this.invitedMemberIndex] != 'undefined'){
								storageData['search_results'][this.invitedMemberIndex]['invited']	=	1;
								localStorage.setItem('searchData',JSON.stringify(storageData));
							}
							$('#invitationPopup').modal('hide');
						}else{
							if(typeof(res.data.errors) != 'undefined'){
								this.invitationFormError 	= res.data.errors;
							}else{
								showErrorMessageTopRight(res.data.message);
								this.createInvitationForm();
								//this.tempResult[this.invitedMemberIndex]['invited']	=	1;
								$('#invitationPopup').modal('hide');
							}
							
						}
						unblockUI(); 
					});
					
				} else {
					ValidationService.validateAllFormFields(this.invitationForm);
				}
			}
		});
	}//end invitationSubmit()



	public resultSlider(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
			 let owl = $('.myCarousel6');
			  owl.owlCarousel({
				margin:0,
				autoplay:true,
				loop:true,
				nav:true,
				dots:false,
				responsive: {
				  0: {
					items: 1
				  },
				  768: {
					items: 1
				  },
				  992: {
					items:1
				  }
				}
			  })
		    }
		});
	}
	
	/**function for change view grid or list */
	public changeView(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				let that	=	this;
				if(this.isGridView){
					this.isGridView	=	false;
					setTimeout(function(){
						that.resultSlider();
					},200);
				}else{
					this.isGridView	=	true;
					setTimeout(function(){
						that.resultSlider();
					},200);
				}
			}
		});
	}

	/**function for set advertise position */
	public setAdPosition(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				
				let total_search	=	this.tempResult.length;
				
				if(total_search>0){
					
					if(total_search>2){
						let pos	=	Math.ceil(total_search/2);
						if(pos%2 == 0){
							pos=	pos-1;
						}
						this.adPosition	=	pos;
					}else{
						this.adPosition	=	total_search-1;
					}
				}
				this.searchDataArray	=	this.tempResult;
			}
		});
		
	}//end setAdPosition()
	
	/**function for request photo */
	
	public requestPhoto(blockedUserSlug,index){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				let userSlug	=	this.userData.slug;
				let that	=	this;
				bootbox.confirm(that.TextSetting.PHOTO_REQUEST_MEMBER_MSG,
					function(result){
						if(result){
							blockUI();
							that.UserActionService.sendphotoRequest(userSlug,blockedUserSlug).then((res:any)=>{
								if(res.data.success == true){
									showSuccessMessageTopRight(res.data.message);
									that.tempResult[index]['request_sent']		=	1;
									that.searchDataArray[index]['request_sent']	=	1;
								}else{
									showErrorMessageTopRight(res.data.message);
								}
							});
							unblockUI();
						}
					}
				);
			}
		});
	}
	
	
	/**function for check all checkboxes */
	public ckbCheckAll(event){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
					this.receivedSearchSelected	=	[];
					let checkboxCheck		=	event.target.checked;
					if(checkboxCheck){
						for(let i = 0;i<this.tempResult.length;i++){
							this.receivedSearchSelected.push(this.tempResult[i]['slug']);
						}
					}
					$(".checkBoxClass").prop('checked', $("#select_all").prop('checked'));
			}
			});
	}
	
	
	/**function for shortlist multiple member */
	public shortlistMultipleMemberSearch(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				if(this.receivedSearchSelected.length > 0){
					let userSlug	=	this.userData.slug;
					let formData	=	new FormData();
					formData.append('user_slug',userSlug);
					formData.append('shortlist_user',this.receivedSearchSelected.join(','));
					let that	=	this;
					bootbox.confirm(that.TextSetting.MULTIPLE_SHORTLIST_MEMEBER_MESSAGE,
						function(result){
							if(result){
								blockUI();
								that.InboxService.shortListMultipleMember(formData).pipe(takeUntil(that.ngUnsubscribe)).subscribe((res:any)=>{
									if(res.data.success == true){
										that.tempResult		=	[];
										that.resultArray	=	[];
										that.receivedSearchSelected	=	[];
										$("#select_all").prop('checked',false);
										showSuccessMessageTopRight(res.data.message);
										if(that.router.url	==	'/matches'){
											that.getPreferedMatchSerachData();
										}else if(that.router.url	==	'/matches/reversematches'){
											that.getRevereseMatchSerachData();
										}else if(that.router.url	==	'/matches/mutualmatches'){
											that.getMutualMatchSerachData();
										}
									}else{
										showErrorMessageTopRight(res.data.message);
									}
								});
								unblockUI();
							}
						}
					); 
				}else{
					showErrorMessageTopRight(this.TextSetting.MEMBER_SHORTLIST_MSG);
				}
			}
		});
	}//end shortlistMember()
	
	/**function for reject multiple invitation */
	public rejectMultipleMemberSearch(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				if(this.receivedSearchSelected.length > 0){
					let userSlug	=	this.userData.slug;
					let formData	=	new FormData();
					formData.append('user_slug',userSlug);
					formData.append('rejected_user',this.receivedSearchSelected.join(','));
					let that	=	this;
					bootbox.confirm(that.TextSetting.MULTIPLE_IGNORED_MEMBER,
						function(result){
							if(result){
								blockUI();
								that.InboxService.rejectMultipleMember(formData).pipe(takeUntil(that.ngUnsubscribe)).subscribe((res:any)=>{
									if(res.data.success == true){
										that.tempResult		=	[];
										that.resultArray	=	[];
										that.receivedSearchSelected	=	[];
										$("#select_all").prop('checked',false);
										showSuccessMessageTopRight(res.data.message);
										if(that.router.url	==	'/matches'){
											that.getPreferedMatchSerachData();
										}else if(that.router.url	==	'/matches/reversematches'){
											that.getRevereseMatchSerachData();
										}else if(that.router.url	==	'/matches/mutualmatches'){
											that.getMutualMatchSerachData();
										}else{
											$(".filter_update").trigger('click');
										}

									}else{
										showErrorMessageTopRight(res.data.message);
									}
								});
								unblockUI();
							}
						}
					);
				}else{
					showErrorMessageTopRight(this.TextSetting.AT_LEAST_ONE_IGNORED_MEMBER_MESSAGE);
				}
			}
		});
	}//end rejectMultipleInvitaion()
	
	
	/**function for get search result from query string */
	public getQuerySearchResult(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				let search_criteria		=	this.paramsSub;
				this.PreferredService.querySearchResult(search_criteria).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res:any)=>{
					
					this.tempResult = res.data.search_results;
					this.resultArray	=	res.data;
					if(this.resultArray.next_page_url !=	null){
						this.isLoadMore		=	true;
						this.loadMoreUrl	=	this.resultArray.next_page_url;
					}else{
						this.isLoadMore		=	false;
						this.loadMoreUrl	=	'';
					}
					if(typeof(res.data.search_results) != 'undefined'){
						this.setAdPosition();
					}
					$('#searchCounter').val(res.data.total);
					$('#leftCounterId').trigger('click');
					let that = this;
					$('#leftCounterId').trigger('click');
					$('.filter_update').trigger('click');
					setTimeout(function(){
						that.resultSlider();

					},1000);
					setTimeout(function(){
						that.resizeequalheight();
					},250);
					unblockUI();
				});
			}
		});
	}
	
	public upgradePlan(){
	this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				$('#redirectPopup').modal('show');
			}
		});
	}
	
	public navigateClick(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				$('body').removeClass('modal-open');
				$('#redirectPopup').modal('hide');
				this.router.navigate(['/upgrade-plan']);
			}
		});
	}
	
	
	
}
