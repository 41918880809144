import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'reversekeys'})
export class ReverseKeysPipe implements PipeTransform
{
    transform(value:any, args:string[]): any {
        let keys:any[] = [];
		for (var prop in value) {		
			keys.push({key: value[prop],value:prop });
		} 	
        return keys;
    }

}
