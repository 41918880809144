import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { FooterService } from "../../../shared/service/footer.service";
import { ValidationService, CommonFunctionsService  } from '../../../../_services/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from '../../../../_services/user.service';
import { TextSetting } from '../../../../textsetting';
import { DashboardService } from '../../../../modules/dashboard/service/dashboard.service';
import { AuthService } from "angular4-social-login";
import { FacebookLoginProvider } from "angular4-social-login";

interface MailChimpResponse {
  result: string;
  msg: string;
}

declare var $:any;

@Component({
  selector: 'app-unlock-trust',
  templateUrl: './unlock-trust.component.html',
  providers : [CommonFunctionsService,FooterService,ValidationService, UserService, DashboardService,AuthService]
 
})

export class UnlockTrustComponent implements OnInit, OnDestroy {
	/**This is used to prevent memory leaks **/
	private ngUnsubscribe: Subject<void> = new Subject<void>();	
	/**This is used to prevent memory leaks **/	
	
	/** Dclear the variable constant */
	userData	:any					=	this.userService.getCurrentUser();

	env									=	environment;
	TextSetting 						=   TextSetting;

	SITE_IMAGE_URL						:   string 	=	"";
	isBrowser					 		:   boolean	=	false;
	
	loginUserData                  		:   any     =   [];
	documentType                    	:   any     =   [];
	documentVerificationError       	:   any     =   {};
	uploadDocumentFormStatus       		:   any     =   {};
	documentVerificationSuccessMsg 		:   any     =   {};
	documentVerificationErrorMsg 		:   any     =   {};
	image_err                      	 	:   any     =   {};
	isAttachmentInculded            	:   any;
	SocialVerificationStatus       		:   any     =   {};
    SocialVerificationError         	:   any     =   {};
    SocialVerificationErrorMsg      	:   any     =   {};
    SocialVerificationSuccessMsg    	:   any     =   {};
	uploadDocumentForm              	:   FormGroup;
	 user                            :   any     =   [];
	

	constructor(public DashboardService: DashboardService,public router: Router, private formBuilder: FormBuilder,private commonFunctionsService:CommonFunctionsService,private FooterService: FooterService, public userService : UserService ,private authService: AuthService){
		this.SITE_IMAGE_URL = this.env.SITE_IMAGE_URL;
		this.createuploadDocumentForm();
	}

  	ngOnInit() {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.isBrowser		=	true;

				this.createuploadDocumentForm();
				this.getDocumentTypeInformation(); 

				let that	=	this;
				/** This code are used to document value set in drop down */
				$(document).on('change','#document_type_id select',function(){
                    let selectval   =   $('#document_type_id select').val();
                    that.uploadDocumentForm.get('document_type').setValue(selectval);
				});
				
				 // For Social Login
				 this.authService.authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe((user: any) => {
                    this.user = user;
                    if(this.user !=  null){
                        /*Check User have an valid or not for login or sigup */
                        this.checkValidUserForTrust();
                        /*Check User have an valid or not for login or sigup */
                    }
                });
				
			}

   		});
		
	}//end ngOnInit()
	
	
	/*********** Facebook Login ************/
    signInWithFB(): void {
        this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);

    }
    signOut(): void {
        this.authService.signOut();
    }


    /*function for check valid user or not */
    public checkValidUserForTrust(){
        let slug    =   this.userData.slug;
		this.DashboardService.updateSocialStatus(this.user.id,slug).then( (res:any) => {
            blockUI();
            this.SocialVerificationStatus   = res.data.success;
            if(this.SocialVerificationStatus == true)
            {
                this.SocialVerificationSuccessMsg   =   res.data.message;
					showSuccessMessageTopRight(this.SocialVerificationSuccessMsg);
					/* Only show the first time popup*/
					this.authService.signOut();
					/* Only show the first time popup*/
					
					/**update user data for complete step sixth */
					this.userService.updateCurrentUserData();
					
					unblockUI();
                }else{
                this.SocialVerificationError    = res.data.errors;
                if(this.SocialVerificationError == 'errors'){
                    this.SocialVerificationError            =   '';
                    this.SocialVerificationErrorMsg =   res.data.message;
                    showErrorMessageTopRight(this.SocialVerificationErrorMsg);
                    /* Only show the first time popup*/
                    this.authService.signOut();
                    /* Only show the first time popup*/
                }
                unblockUI();
            }
        });
    }
	
	

	/*This function are used for check the document is verified or not*/
	public  document_verified(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				$(".verify_document").val('Upload Document');
				$('body').addClass('upload_document_ipad');
				$("#document_uploaded").modal('show');
				$("#trust_badge_popup").modal('hide');
			}
		});
    }
	
	
	
    /*This function are create documet form*/
	createuploadDocumentForm(){
        this.uploadDocumentForm         =   this.formBuilder.group({
            'document_type'             :   ['',Validators.required],
            'file_upload'               :   [''],
        });
	}//end createuploadDocumentForm()
	
	/**Function are used to get document type */
	public getDocumentTypeInformation(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.DashboardService.getDocumentTypeInformation().then( (res:any) => {
					if(res){
						this.documentType           =   res.data.document_type;
					}
				});
			}
		});
	}

	/**function for use to off modal */
	public clickToOffModel(){
        $('body').removeClass('upload_document_ipad');
	}
	
	/* FUNCTION TO PREPARE FORM BEFOR SUBMIT WITH ALL DATA & IMAGE ALSO */
    private prepareSave(): any {
        let input           = new FormData();
        input.append('slug', this.userData.slug);
		input.append('document_type', this.uploadDocumentForm.get('document_type').value);
		
        if(this.uploadDocumentForm.get('file_upload').value)
        {
            input.append('image', this.uploadDocumentForm.get('file_upload').value);
        }
        return input;
	}
	
	/**function for use to submit form */
	public onSubmit(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				const formModel = this.prepareSave();

				if (this.uploadDocumentForm.valid) {
					this.DashboardService.submitUploadDocumentForm(formModel).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
						this.uploadDocumentFormStatus   = res.data.success;
						if(this.uploadDocumentFormStatus == true)
						{
							this.documentVerificationSuccessMsg =   res.data.message;
							showSuccessMessageTopRight(this.documentVerificationSuccessMsg);
							$('body').removeClass('modal-open');
							$('#document_uploaded').modal('hide');
							setTimeout(function(){
								unblockUI();
							},1000);
						}else{
							this.documentVerificationError  = res.data.errors;
							if(this.documentVerificationError == 'errors'){
								this.documentVerificationError          =   '';
								this.documentVerificationErrorMsg   =   res.data.message;
								showErrorMessageTopRight(this.documentVerificationErrorMsg);
							}
							unblockUI();
						}
					});
				}else{
					ValidationService.validateAllFormFields(this.uploadDocumentForm);
					unblockUI();
				}
			}
		});
	}
	
	public uploadDocument(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				$("#file").trigger('click');
			}
		});
	}
	
	
	public handleFileInput(event) {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				let files = event.target.files;
				if (files.length > 0) {
					let MAX_IMAGE_SIZE_IN_MB    =   this.env.MAX_FILE_UPLOAD_LIMIT;
					let UPLOAD_FILE_SIZE = MAX_IMAGE_SIZE_IN_MB; // file size in MB
					let size = files[0].size.toFixed(2);
					let allowed_size = UPLOAD_FILE_SIZE * 1024 * 1024;
					if(size > allowed_size){
						this.image_err = this.env.VALID_SIZE_IMAGE_ERROR_MSG+ MAX_IMAGE_SIZE_IN_MB+" MB.";
						showErrorMessageTopRight(this.image_err);
						this.isAttachmentInculded   =   false;
						this.uploadDocumentForm.get("file_upload").setValue(null);
						}else{
						this.image_err  =   "";
						this.documentVerificationError['image'] =   '';
						this.isAttachmentInculded   =   true;
						let file = event.target.files[0];
						$(".verify_document").val(file.name);
						this.uploadDocumentForm.get("file_upload").setValue(file);
					}
				}else{
					this.isAttachmentInculded   =   false;
				}
			}
		});
	}
	
	/**function for send otp */
	public sendOTP(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				$('#trust_badge_popup').modal('hide');
				$('#dashboard_mobile_verification').trigger('click');
			}
		});
	}

	ngOnDestroy() {
		this.ngUnsubscribe.next();
		this.ngUnsubscribe.complete();
    }
	
	public openQuickSearchPopup(){
		$(".dashboard_quick_serch_popup").modal('show');
	}
	
	
	
}
