import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { FooterService } from "../../../shared/service/footer.service";
import { ValidationService, CommonFunctionsService  } from '../../../../_services/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

interface MailChimpResponse {
  result: string;
  msg: string;
}

declare var $:any;

@Component({
  selector: 'advertisement',
  templateUrl: './advertisement.component.html',
  providers : [CommonFunctionsService,FooterService,ValidationService]
 
})

export class AdvertisementComponent implements OnInit, OnDestroy {
	/**This is used to prevent memory leaks **/
	private ngUnsubscribe: Subject<void> = new Subject<void>();	
	/**This is used to prevent memory leaks **/	
	
	advertisement	:	any		=	[];

	constructor(public router: Router, private formBuilder: FormBuilder,private commonFunctionsService:CommonFunctionsService,private FooterService: FooterService){
	}

  	ngOnInit() {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.getAdvertisement();  
			}
   		});
		
	}//end ngOnInit()

	/*function for get advertisement*/
	public getAdvertisement(){
		this.commonFunctionsService.getAdvertisement('search-result-page').then((res:any)=>{
			if(res.data.success == true){
				this.advertisement	=	res.data.advertisement;
			}
		});
	}//end getAdvertisement()
	 
	ngOnDestroy() {
        this.ngUnsubscribe.next();
    }
	
}
