import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService ,CommonFunctionsService } from '../../../../_services/index';
import { UserService } from '../../../../_services/user.service';
import { GlobalApp} from '../../../../_helpers/index';
import { isPlatformBrowser } from '@angular/common';
import { TextSetting } from '../../../../textsetting';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SeoService } from '../../../../_services/seo.service';
import { KeysPipe } 	from '../../../../_pipes/index';
import { HomeService } from '../../../home/service/home.service';

declare var $:any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html'
})
export class SignupComponent implements OnInit,OnDestroy {
	
	private ngUnsubscribe:Subject<any> = new Subject();	
	
	form						:	FormGroup; 
	env							=	environment;
	isForSelf					:	boolean	=	false;
	passwordToolTipText			:	string	=	'';	
	dropDownData				:	any		=	[];
	createProfileFor			:	any		=	[];
	createProfileBy				:	any		=	[];
	profileForSelfId			:	number	=	0;
	signupFirstFormError		:	any		=	{};
	signupFirstFormStatus		:	any		=	'';
	masterGender				:	any		=	[];
	masterMaritalStatus			:	any		=	[];
	masterChildrenLivingStatus	:	any		=	[];
	masterCountries				:	any		=	[];
	masterAppreance				:	any		=	[];
	masterCast					:	any		=	[];
	masterSubCast				:	any		=	[];
	masterMotherTongue			:	any		=	[];
	masterCountryCodes			:	any		=	[];
	getAutoLoginData			:	any		=	[];
	
	loginFormStatus				:	any 	=	{};
	loginFormErr				:	any 	=	{};	
	loginFormErrorMsg			:	any		=	{};
	
	/**first step form variable */
	
	/**phone number form variable */
	isVailidNum					:   any   	  = false;
	enteredNum					:	any;	
	/**phone number form variable */
	
	/**second step form variable */
	secondStepForm				:	FormGroup;
	firstFormData				:	any		=	{};
	signupSecondFormError		:	any		=	{};
	signupSecondFormStatus		:	any		=	'';
	unMarriedId					:	number	=	0;
	isUnMarried					:	boolean	=	false;
	isNoOfChild					:	boolean	=	false;
	phoneError					:	string	=	this.env.VALID_PHONE_NUMBER_VALIDATION_MSG;
	confirm_password_match_error:	string	=	this.env.PASSWORD_NOT_MATCH_MSG;
	cpasswordValid				:	boolean	=	true;
	/**second step form variable */

	/**OTP form variable */
	otpForm						:	FormGroup;
	slug						:	string	=	'';
	otpFormError				:	any		=	{};
	otpFormStatus				:	any	=	'';
	isTimerOn					:	boolean	=	false;
	remain_time					:	string	=	'0:00 min left';
	resendMailTime				:	number	=	0;
	/**OTP form variable */
	
	
	loginFormSuccessMsg			 :	any		=	{};
	user						 :	any		=	{};
	mobileVerificationError		 :	any		=	{};
	mobileVerificationErrorMsg	 :	any		=	{};
	mobileVerificationStatus	 :	any		=	{};
	mobileVerificationSuccessMsg :  any		=	{};
	returnUrl					 :	string	=	'';
	isBrowser					 :   boolean		=	false;
	/**define variables */
	homeReligionData			:	any		=	[];
	
	SITE_IMAGE_URL				:   string 	= 	"";
	isFieldRequired				:	boolean	=	false;
	isRadioButtonChecked		:	number  =	1;	
	socialuserdata				:	any		=	[];
	socialId					:	any		=	{};
	provider					:	any		=	{};
	
	horoscopeData				:	any		=	[];
	convenientTimeData			:	any		=	[];
	convenientTimePeriodData	:	any		=	[];
	convenientBeforeAfterData	:	any		=	[];
	

	constructor(
		private route: ActivatedRoute,
		public router: Router,
		private formBuilder: FormBuilder,
		public commonFunctionsService : CommonFunctionsService,
		private userService: UserService,
		private HomeService: HomeService,
	) {
		this.SITE_IMAGE_URL 	= 	this.env.SITE_IMAGE_URL;
		this.resendMailTime		=	this.env.RESEND_MAIL_TIME_IN_SECOND;
    }

  	ngOnInit() {
			this.createSignupFirstForm();
			this.createSignupSecondForm();
			this.createOTPForm();
			this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
				if(isBrowser){
					this.isBrowser		=	true;
					let that	=	this;
					/*********** This code are used for manage date of birth ************/
					$.dobPicker({
						// Selectopr IDs
						daySelector: '#birth_date',
						monthSelector: '#birth_month',
						yearSelector: '#birth_year',
						
						// Default option values
						dayDefault: 'Day*',
						monthDefault: 'Month*',
						yearDefault: 'Year*',
						
						
						// Minimum age
						minimumAge: 18,
					
						// Maximum age
						maximumAge: 80
					});
					/*********** This code are used for manage date of birth ************/
					
					/*********** variable decleration ************/
					this.passwordToolTipText		=	this.env.VALID_PASSWORD_VALIDATION_MSG;
					
					/*********** This code are used for tooltip initialization ************/
					setTimeout(function(){
						$('[data-toggle="tooltip"]').tooltip(); 
					},1000);
					
					
					setTimeout(function(){
							$("#country_phone").intlTelInput({initialCountry: 'in',placeholderNumberType:''});
							
							
							
					},3300);
					
					
					/**function for create_profile_for change */
				$(document).on('change','#create_profile_for select',function(){
					let htmlcreatedBy	=	'';
					let selectval	=	$('#create_profile_for select').val();
					that.form.get('create_profile_for').setValue(selectval);
					let profile_for_id	=	selectval;
					if(profile_for_id	==	that.profileForSelfId){
						that.isForSelf	=	true;
					}else{
						blockUI();
						that.isForSelf	=	false;
						if(profile_for_id	==	''){
							profile_for_id	=	that.profileForSelfId;
						}
					    that.commonFunctionsService.getProfileByData(profile_for_id).then((res: any) => {
						that.createProfileBy		=	res.data.create_profile_by;
						var createdByArray			=	that.createProfileBy;
						that.form.get('create_profile_by').setValue('');
						$.each( createdByArray, function( key, value ) {
							htmlcreatedBy	+=	"<option value='"+value.id+"'>"+value.text+"</option>";
						});
						$("#create_profile_by >select.select2-hidden-accessible").html(htmlcreatedBy);
						unblockUI();
					});
					}
				});
				/**function for create_profile_for change */

				/**function for create_profile_by change */
				$(document).on('change','#create_profile_by select',function(){
					let selectval	=	$('#create_profile_by select').val();
					that.form.get('create_profile_by').setValue(selectval);
				});
				/**function for create_profile_by change */
				
				/**function for marital_status change */
				$(document).on('change','#marital_status select',function(){
					let selectval	=	$('#marital_status select').val();
					that.secondStepForm.get('marital_status').setValue(selectval);

					let marital_status	=	selectval;
					if(marital_status	==	that.unMarriedId){
						that.isUnMarried	=	true;
					}else{
						that.isUnMarried	=	false;
					}
				});
				/**function for marital_status change */
				
				
				
				/*********** Open signup first popup ************/
				$(document).on('click', ".previous" , function(e) {
						blockUI();
						$('body').removeClass('modal-open');
						$('#signup_contn').modal('hide');
							setTimeout(function(){
								unblockUI();
								$('#sign_up_one').modal('show');
							},1000);
				});

				/**This function are used for get the master data  **/
				
				/**function for residing_country change */
				$(document).on('change','#residing_country select',function(){
					let selectval	=	$('#residing_country select').val();
					let phone_code	=	'';
					if(selectval == ''){
						phone_code	=	that.masterCountryCodes[101];
					}else{
						phone_code	=	that.masterCountryCodes[selectval];
					}
					$("#country_phone").intlTelInput('setCountry', phone_code);
					that.phoneNumValidate('country_phone','country_phone_error','UserPhone');
					that.secondStepForm.get('residing_country').setValue(selectval);
					
				});
				/**function for residing_country change */
				
				/*********** open login popup ************/
				$(document).on('click', "#login_popup" , function(e) {
							blockUI();
							$('body').removeClass('modal-open');
							$('#sign_up_one').modal('hide');
								setTimeout(function(){
									unblockUI();
									$('#login').modal('show');
								},1000);
					});
				/*********** open login popup ************/
				
				
				/************** resend verification mail and verify email *******************/
				$(document).on('click', ".login_verification" , function(e) {
					let rel =	$('.login_verification').attr('data-rel');
					if(rel	==	'mobile'){
						that.sendOTP();
					}else if(rel	==	'email'){
						that.resend_verification_mail();
					}else if(rel	==	'both'){
						that.sendOTP();
						that.resend_verification_mail();
					}
				});
				/************** resend verification mail and verify email *******************/
				this.getMasterData();

				/**function for relgion data change accourding to caste and sub caste value change */
				$('.religion_other_value').hide();
				$('.caste_other_value').hide();
				$('.sub_caste_other_value').hide();
				$('.other_mother_toung_class').hide();
				$('.appearance_hide').hide();
				$('.horoscope_div').hide();
				$('.paris_name_div').hide();
				
				$(document).on('change','#religion_id select',function(){
					// blockUI();
					let selectval	=	$('#religion_id select').val();
					if(selectval== environment.RELIGION_OTHER_VALUE){
						$('.religion_other_value').show();
						$('.caste_other_value').show();
						$('.sub_caste_other_value').show();
						$('.caste').hide();
						$('.sub_cast').hide();
						$(".caste_other_value_text").html('Caste Name<i>*</i>:');
					}else{
						$('.religion_other_value').hide();
						$('.caste_other_value').hide();
						$('.sub_caste_other_value').hide();
						$('.caste').show();
						$('.sub_cast').show();
					}

					if(selectval=='' || selectval==null){
						selectval	=	0;
						that.secondStepForm.get('cast').setValue('');
						that.secondStepForm.get('sub_cast').setValue('');
						that.secondStepForm.get('religion_id').setValue('');
						that.religionCasteData(0);
					}else{
						that.secondStepForm.get('cast').setValue('');
						that.secondStepForm.get('sub_cast').setValue('');
						that.secondStepForm.get('religion_id').setValue(selectval);
						that.religionCasteData(selectval)
					}

					/**Condition for use to sikh case apperance show */
					if(selectval== environment.APPERANCE_SIKH_VALUE){
						$('.appearance_hide').show();
						$('.horoscope_div').hide();
						$('.paris_name_div').hide();
						that.commonFunctionsService.getMasterData().then((res: any) => {
							that.masterAppreance			=	res.data.appearance;
						});
						setTimeout(function(){
							$(".appenrence_checked").prop("checked", false);
							that.secondStepForm.get('appearance').setValue('');
						},200);
						
					}else{
						$('.appearance_hide').hide();
						that.commonFunctionsService.getMasterData().then((res: any) => {
							that.masterAppreance			=	res.data.appearance;
						});
						setTimeout(function(){
							$(".appenrence_checked").prop("checked", false);
							that.secondStepForm.get('appearance').setValue('');
						},200);
					}
					
					/**Condition for use to hindhu religion show */
					if(selectval	== environment.RELIGION_HINDHU_VALUE){
						$('.appearance_hide').hide();
						$('.paris_name_div').hide();
						$('.horoscope_div').show();
					}else{
						$('.horoscope_div').hide();
					}
					if(selectval == environment.RELIGION_CHRISTIAN_VALUE){
						$(".sub_cast_text").html('Sub Caste<i></i>:');
						$(".cast_text").html('Denomination<i>*</i>:');
						$('.paris_name_div').show();
						$('.appearance_hide').hide();
						$('.horoscope_div').hide();
						$('.sub_cast').hide();
					}else{
						$(".sub_cast_text").html('Sub Caste<i></i>:');
						if(selectval != environment.RELIGION_HINDHU_VALUE){
							$(".cast_text").html('Caste<i></i>:');
						}else{
							$(".cast_text").html('Caste<i>*</i>:');
						}
						$('.paris_name_div').hide();
					}
					$(document).on('change','#horoscope_id select',function(){
						let horoscopeval	=	$('#horoscope_id select').val();
						if(selectval == environment.RELIGION_HINDHU_VALUE){
							that.secondStepForm.get('horoscope').setValue(horoscopeval);
						}else{
							that.secondStepForm.get('horoscope').setValue('');
						}
					});
					
					/** this function are used to sub caste call on relgion wise*/
					that.commonFunctionsService.getSubCastData(0).then((res: any) => {
						that.masterSubCast	=	res.data.subcast;
					});
					// unblockUI();
				});

				/**function for cast change */
				$(document).on('change','#cast select',function(){
					let selectval	=	$('#cast select').val();
					that.secondStepForm.get('cast').setValue(selectval);
					blockUI();
					let cast	=	selectval;
					if(cast	==	''){
						cast	=	0;
					}
						let religionValue		=	that.secondStepForm.value.religion_id;
						if(selectval == environment.CAST_OTHER_VALUE){
							that.secondStepForm.get('sub_cast').setValue('');
							$('.caste_other_value').show();
							$('.sub_cast').hide();
							if(religionValue == environment.RELIGION_CHRISTIAN_VALUE){
								$(".sub_cast_name_text").html('Sub Caste Name<i></i>:');
								$(".caste_other_value_text").html('Denomination Name<i>*</i>:');
								$('.sub_caste_other_value').hide();
							}else{
								$(".sub_cast_name_text").html('Sub Caste Name<i>*</i>:');
								$(".caste_other_value_text").html('Caste Name<i>*</i>:');
								$('.sub_caste_other_value').show();
							}
						}else{
							that.secondStepForm.get('sub_cast').setValue('');
							$('.caste_other_value').hide();
							$('.sub_caste_other_value').hide();
							$(".sub_cast_name_text").html('Sub Caste Name<i>*</i>:');
							if(religionValue == environment.RELIGION_CHRISTIAN_VALUE){
								$(".caste_other_value_text").html('Denomination Name<i>*</i>:');
								$('.sub_cast').hide();
							}else{
								$(".caste_other_value_text").html('Caste Name<i>*</i>:');
								$('.sub_cast').show();
							}
						}
						that.commonFunctionsService.getSubCastData(cast).then((res: any) => { 
							that.masterSubCast	=	res.data.subcast;
							unblockUI();
						});
				});
				/**function for cast change */
				
				/**function for sub_cast change */
					$(document).on('change','#sub_cast select',function(){
						let selectval	=	$('#sub_cast select').val();
						if(selectval == environment.SUB_CAST_OTHER_VALUE){
							$('.sub_caste_other_value').show();
						}else{
							$('.sub_caste_other_value').hide();
						}
						that.secondStepForm.get('sub_cast').setValue(selectval);
					});
				/**function for sub_cast change */


				/**function for mother_toung change */
				$(document).on('change','#mother_toung select',function(){
					let selectval	=	$('#mother_toung select').val();
					that.secondStepForm.get('mother_toung').setValue(selectval);
					if(selectval==environment.MOTHER_TONG_OTHER_VALUE){
						$('.other_mother_toung_class').show();
					}else{
						$('.other_mother_toung_class').hide();
					}
				});
				
				$(document).on('change','#convenient_time select',function(){
						let convenientTimeval	=	$('#convenient_time select').val();
						that.secondStepForm.get('convenient_time').setValue(convenientTimeval);
				});
					
				$(document).on('change','#convenient_time_period select',function(){
					let convenientTimePeriodval	=	$('#convenient_time_period select').val();
					that.secondStepForm.get('convenient_time_period').setValue(convenientTimePeriodval);
				});
				
				$(document).on('change','#convenient_before_after select',function(){
					let convenientBeforeAfterval	=	$('#convenient_before_after select').val();
					that.secondStepForm.get('convenient_before_after').setValue(convenientBeforeAfterval);
				});    
				
				
				/*This function are used for hidden id click for show the social data*/
				$("#fill_up_social_data").click(function(){
						that.createSignupFirstForm();
				});
			}
		});
	}
	
	/**function for create signup first step form */
	createSignupFirstForm(){
		let	socialData		=	null;
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
					socialData		=	localStorage.getItem('facebookData');
			}
		});
		let	first_name		=	'';	
		let	last_name		=	'';	
		let	email			=	'';	
		if(socialData != null){
			this.socialuserdata		=	JSON.parse(socialData);
			first_name				=	this.socialuserdata.firstName;
			last_name				=	this.socialuserdata.lastName;
			email					=	this.socialuserdata.email;
			this.socialId			=	this.socialuserdata.id;
			this.provider			=	this.socialuserdata.provider;
			localStorage.removeItem('facebookData');
		}else{
			this.socialId		=	'';
			this.provider		=	'';
		}
		
		this.form			=	this.formBuilder.group({ 
			'create_profile_for'	: ['', Validators.required],
			'create_profile_by'		: [''],
			'first_name'			: [first_name,[Validators.required,ValidationService.specialCharValidator]],
			'last_name'				: [last_name,[Validators.required,ValidationService.specialCharValidator]],
			'email'					: [email,[Validators.required, Validators.email]],
			'password'				: ['', [Validators.required,ValidationService.passwordValidator]],
			'confirm_password'		: ['', [Validators.required]],
			'gender'				: ['', Validators.required],
			'birth_date'			: ['', Validators.required],
			'birth_month'			: ['', Validators.required],
			'birth_year'			: ['', Validators.required],
			'social_id'			    : [''],
			'provider'			    : [''],
		});
	}//end createSignupFirstForm()
	
	
	
	/**function for create signup second step form */ 
	createSignupSecondForm(){
		this.isNoOfChild	=	false;		
		this.secondStepForm				=	this.formBuilder.group({ 
			'first_form_data'			:	['',],
			'marital_status'			:	['', Validators.required],
			'no_of_children'			:	[''],
			'children_living_status'	:	[''],
			'religion_id'				:	['', Validators.required],
			'appearance'				:	[''],
			'horoscope'					:	[''],
			'parish_name'				:	[''],
			'cast'						:	[''],
			'sub_cast'					:	[''],
			'mother_toung'				:	['', Validators.required],
			'residing_country'			:	['', Validators.required],
			'phone'						:	['', Validators.required],
			'mobile_number'				:	[''],
			'religion_other_value'		:	[''],
			'caste_other_value'			:	[''],
			'sub_caste_other_value'		:	[''],
			'mother_toung_other_value'	:	[''],
			'convenient_time'			:	['', Validators.required],
			'convenient_time_period'	:	['', Validators.required],
			'convenient_before_after'	:	['', Validators.required],
		});		
	}//end createSignupSecondForm()
	
	 
	/**function for create OTP form */
	createOTPForm(){
		this.otpForm			=	this.formBuilder.group({ 
			'slug'				:	[''],
			'one_time_password'	:	['', Validators.required],
		});
	}//end createSignupSecondForm()
	
	
	
	
	
	/*This function are used for set the value of blank on change day */
	public getBirthDate(){
		this.form.get('birth_month').setValue('');
		this.form.get('birth_year').setValue('');
	}
	
	/*This function are used for set the value of blank on change month */
	public getYearDate(){
		this.form.get('birth_year').setValue('');
	}
	
	public phoneNumValidate(phone_field,error_box_id,valid_msg_id){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				var telInput;
				telInput = $("#"+phone_field);
				var  errorMsg = $("#"+error_box_id);
				var  validfield = $("#"+valid_msg_id);
				var isvalid_number = 0;
				

				var reset = function() {
					telInput.removeClass("error");
					// errorMsg.addClass("hide");
					if(validfield.length) validfield.val("");
				};

				if ($.trim(telInput.val())) {
					
					var tesval = telInput.intlTelInput("isValidNumber");
				
					if(tesval == false){
					this.isVailidNum = false;
						validfield.val("");
						return false;
					}else if(tesval == true){
						let intlTelInputUtils	:	any		=	{};
						var numberType = telInput.intlTelInput("getNumberType");
						if((numberType == 1)  || (numberType == 2) || (numberType == 0)){
							isvalid_number = 1;
						}
						if (isvalid_number==0) {               
							this.isVailidNum = false;
							validfield.val("");
							return false;
						}
						if(validfield.length)
							validfield.val(telInput.intlTelInput("getNumber"));
						this.isVailidNum = true;
						this.enteredNum = telInput.intlTelInput("getNumber");
						$(".not_valid_mobile").html('');
						return true;
							
					}
				}
				// on keyup / change flag: reset
				telInput.on("keyup change", reset);
			}
		});
	}
	
	
	
	
	
	
	/*function for get change password*/
	public changeSignupPassword(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
				if(isBrowser){
					let that	=	this;
					let password	=	$('#signupPassword').val();
					let cpassword	=	$('#signupcPassword').val();
					if(password	!= '' && cpassword	!= '' && password != cpassword){
						that.cpasswordValid	=	false;
					}else{
						that.cpasswordValid	=	true;
					}
				}
		});
	}
	
	/*function for get change confirm password*/
	public changecPassword(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
				if(isBrowser){
					let that	=	this;
					let password	=	$('#signupPassword').val();
					let cpassword	=	$('#signupcPassword').val();
					if(password	!= '' && cpassword != '' && password != cpassword){
						that.cpasswordValid	=	false;
					}else{
						that.cpasswordValid	=	true;
					}
				}
		});
	}
	
    /**function for get master data from Api */
	public getMasterData(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
				   this.commonFunctionsService.getMasterData().then((res: any) => {
						this.dropDownData 				=	res.data;
						this.createProfileFor			=	res.data.create_profile_for;
						this.createProfileBy			=	res.data.create_profile_by;
						this.profileForSelfId			=	res.data.profile_for_self_id;
						this.masterGender				=	res.data.gender;
						this.masterMaritalStatus		=	res.data.marital_status;
						this.masterChildrenLivingStatus	=	res.data.children_living_status;
						this.masterCountries			=	res.data.countries;
						this.masterAppreance			=	res.data.appearance;
						this.masterCast					=	res.data.cast;
						this.masterSubCast				=	res.data.sub_cast;
						this.masterMotherTongue			=	res.data.mother_tongue;
						this.unMarriedId				=	res.data.unmarried_id;
						this.masterCountryCodes			=	res.data.country_codes;
						this.homeReligionData			=	res.data.religion;
						this.horoscopeData				=	res.data.horoscope;
						this.convenientTimeData			=	res.data.convenient_time;
						this.convenientTimePeriodData	=	res.data.convenient_time_period;
						this.convenientBeforeAfterData	=	res.data.convenient_before_after;
					});
			   }
		});
	}
	
	
	/**function for submit signup form first step*/
	public submitSignupFirstForm() {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
				   blockUI();
				    /*This va;ue assigned for social id*/
						this.form.value.social_id		=	this.socialId;
						this.form.value.provider		=	this.provider;
					/*This va;ue assigned for social id*/
					if (this.form.valid && this.cpasswordValid) {
						this.commonFunctionsService.submitSignupFirstForm(this.form.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
							this.signupFirstFormStatus	= res.data.status;
								if(this.signupFirstFormStatus == this.env.SUCCESS_CONSTANT)
								{
									this.signupFirstFormError			=	'';
									this.firstFormData					=	res.data.signup_first_data;
									$('body').removeClass('modal-open');
									$('#sign_up_one').modal('hide');
									setTimeout(function(){
										$('#signup_contn').modal('show');
										unblockUI();
									},1000);
								}else{
									this.signupFirstFormError 	= res.data.errors;
									unblockUI();
								}
						});
					}else{
						  unblockUI();
						  ValidationService.validateAllFormFields(this.form);	
					}
			   }
		});
	}
	
	
	/**function for submit signup form first step*/
	public submitSignupSecondForm() {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();

				if (this.secondStepForm.valid && this.isVailidNum) {
					/**This local storage are used for auto login */
						localStorage.setItem('AutoLoginData',this.firstFormData);
					/**This local storage are used for auto login */
					
					this.secondStepForm.value.first_form_data	=	this.firstFormData;
					this.secondStepForm.value.phone				=	this.enteredNum;	
					this.secondStepForm.value.mobile_number		=	$("#country_phone").val();
					
					this.commonFunctionsService.submitSignupSecondForm(this.secondStepForm.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
						this.signupSecondFormStatus	= res.data.status;
						if(this.signupSecondFormStatus == this.env.SUCCESS_CONSTANT)
						{
							let that	=	this;
							this.signupSecondFormError			=	'';
							this.slug						=	res.data.slug;
							this.createSignupFirstForm();
							this.createSignupSecondForm();
							showSuccessMessageTopRight(res.data.message);
							$('body').removeClass('modal-open');
							$('#signup_contn').modal('hide');
							setTimeout(function(){
								$('#mobile_verify').modal('show');
							},1000);
							that.timer(this.resendMailTime);
							this.isTimerOn	=	true;
							unblockUI();
						}else{
							this.signupSecondFormError 	= res.data.errors;
							unblockUI();
						}
					});
				}else{
					ValidationService.validateAllFormFields(this.secondStepForm);	
					unblockUI();
				}
			}
		});
	}//end submitSignupSecondForm()
	
	
	/**This function are used for submit otp form **/
	public submitOTPForm() {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				if (this.otpForm.valid) {
					this.otpForm.value.slug	=	this.slug;
					this.commonFunctionsService.submitOTPForm(this.otpForm.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
						this.otpFormStatus	= res.data.status;	
						if(this.otpFormStatus == this.env.SUCCESS_CONSTANT)
						{
							this.otpFormError			=	'';
							this.otpForm.reset();
							$('body').removeClass('modal-open');
							$('#mobile_verify').modal('hide');
							setTimeout(function(){
								$('#verified').modal('show');
								unblockUI();
							},1000);
						}else{
							this.otpFormError 	= res.data.errors;
							unblockUI();
						}
					});
				}else{
					unblockUI();
					ValidationService.validateAllFormFields(this.otpForm);	
				}
			}
		});
	}
	
	
	/**function for send otp */
	public sendOTP(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				let that	=	this;
				this.slug	=	$('.login_verification').attr('data-url');
				let formData    =   new FormData();
				formData.append('slug',this.slug);
				this.commonFunctionsService.resendOTP(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
					if(res.data.status ==	this.env.SUCCESS_CONSTANT){
							unblockUI();
							showSuccessMessageTopRight(res.data.message);
							$('#login').modal('hide');
							$('#mobile_verify').modal('show');
							that.timer(this.resendMailTime);
							this.isTimerOn	=	true;
					}else{
						showErrorMessageTopRight(res.data.message);
						unblockUI();
					}
				});
			}
		});
	}
	
	/**function for send otp */
	public resend_verification_mail(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				this.slug		=	$('.login_verification').attr('data-url');
				let formData    =   new FormData();
				formData.append('slug',this.slug);
				this.commonFunctionsService.resendVerificationMail(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
					if(	res.data.status	==	this.env.SUCCESS_CONSTANT){
						showSuccessMessageTopRight(res.data.message);
						$('#login').modal('hide');
					}else{
						showErrorMessageTopRight(res.data.message);
					}
					unblockUI();
				});
			}
		});
	}
	
	
	
	
	/**function for resend otp */
	public resend_otp(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				/**make object of form and append user id to form */
				let formData        =   new FormData();
				formData.append('slug',this.slug);
				this.commonFunctionsService.resendOTP(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
					let that	=	this;
					that.timer(this.resendMailTime);
					this.isTimerOn	=	true;
					if(	res.data.status ==	this.env.SUCCESS_CONSTANT){
						showSuccessMessageTopRight(res.data.message);
					}
					unblockUI();
				});
			}
		});
	}//end resend_otp()
	
	
	/**function for skip mobile verification */
	public skipVerification(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				let that		=	this;
				showSuccessMessageTopRight(this.env.AFTER_SKIP_MSG);
				$('body').removeClass('modal-open');
				$('#mobile_verify').modal('hide');
			}
		});
	}//end skipVerification()
	
	
	/**function for close mobile verified success popup and redirect to index */
	public verifiedContinue(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
					/**This variable are used for get the auto login usr data*/
					this.getAutoLoginData	=	JSON.parse(localStorage.getItem('AutoLoginData'));
					/**This variable are used for get the auto login usr data*/
					
					if(this.getAutoLoginData != ''){
						const formModel	= this.prepareSave();
						/* This code are used for submit the login form */ 
						this.commonFunctionsService.submitLoginForm(formModel).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {			
						unblockUI();
						this.loginFormStatus	= response.data.status;
						if(this.loginFormStatus == this.env.SUCCESS_CONSTANT)
						{
							/**This is an used for remove the local storage data */
								localStorage.removeItem('AutoLoginData');
							/**This is an used for remove the local storage data */
							
							$('body').removeClass('modal-open');
							$('#verified').modal('hide');
							let loginuserData	=	response.data.userData;
							
							let loginUserToken			=	response.data.token;
							let loginUserRefreshToken	=	response.data.refreshToken;
							this.userService.setAuth(loginuserData);
							this.userService.setToken(loginUserToken);
							this.userService.setRefreshToken(loginUserRefreshToken);
							let that		=	this;
							setTimeout(function(){
								$("#userDataUpdated").trigger('click');
							},1000);
							showSuccessMessageTopRight(response.data.message);
							setTimeout(function(){
								that.redirectAfterLogin(loginuserData);
							},2000);
							unblockUI();
						}else{
							$('body').removeClass('modal-open');
							$('#verified').modal('hide');
							this.loginFormErr 	 = response.data.errors;
							if(this.loginFormErr == 'errors'){
								this.loginFormErr			=	'';
								this.loginFormErrorMsg	= 	response.data.message;
								showErrorMessageTopRight(this.loginFormErrorMsg);
								unblockUI();
							}
						}
					});
				}
			}
		});
	}//end verifiedContinue()
	
	
	
	
	/* FUNCTION TO PREPARE FORM BEFOR SUBMIT WITH ALL DATA & IMAGE ALSO */
	private prepareSave(): any {
		let input 			= new FormData();
		input.append('email', this.getAutoLoginData.email);	
		input.append('password', this.getAutoLoginData.password);		
		return input;
	}
	
	
	
	/**function is used to redirect after login */
	redirectAfterLogin(userData:any){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
			this.route.queryParams.subscribe(params => {
				if(typeof(params.returnUrl) != 'undefined'){
					this.returnUrl	=	params.returnUrl;
				}
			});
			if(this.returnUrl != ''){
				this.router.navigate([this.returnUrl]);
			}else{
				if(userData.profile_completed_step ==	0){
						this.router.navigate(['/profile']);
				}else if(userData.profile_completed_step == 1){
						this.router.navigate(['/profile/profile-two']);
				}else if(userData.profile_completed_step == 2){
					this.router.navigate(['/profile/profile-three']);
				}else if(userData.profile_completed_step == 3){
					this.router.navigate(['/profile/profile-four']);
				}else if(userData.profile_completed_step == 4){
						this.router.navigate(['/profile/profile-five']);
				}else if(userData.profile_completed_step == 5){
						this.router.navigate(['/profile/partner-preference']);
				}else if(userData.profile_completed_step == 6){
						this.router.navigate(['/dashboard']);
				}
			  }
			}
		 });
		}
	
	
	
	
	
	
	
	/*This function are used for mobile number field as numeric check.*/
	public keyPress(event: any) {
		const pattern = /[0-9\+\-\ ]/;
		let inputChar = String.fromCharCode(event.charCode);
		if (event.keyCode != 8 && !pattern.test(inputChar)) {
			event.preventDefault();
		}
	}//end keyPress
	
	
	
	/*This function are used for no of children field as numeric check.*/
	public keyPressChildren(event: any) {
		const pattern = /[0-9\ ]/;
		let inputChar = String.fromCharCode(event.charCode);
		if (event.keyCode != 8 && !pattern.test(inputChar)) {
			event.preventDefault();
		}
	}//end keyPressChildren()
	
	
	/*This function are used for no of children field as numeric check.*/
	public kayupChildren(event: any) {
		let childValue	=	this.secondStepForm.value.no_of_children
		childValue	=	parseInt(childValue);
		if(childValue > 0){
			this.isNoOfChild	=	true;
		}else{
			this.isNoOfChild	=	false;
		}
	}//end keyPressChildren()
	
	
		hasErrorNew(){
			 if(!this.isVailidNum){
				return {required : true}
			}
	   }

	  ismobNotvalid(){
		return this.isVailidNum;

	  }
	  hasError(obj){
		this.isVailidNum = obj;
	  }
	  getNumber(obj){
		this.enteredNum = obj
	  }
	  
	  
	  
	/**function for timer */
	public timer(time:any){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
					let currentdate =	new Date().getTime();
					let countDowntime	=	currentdate+(time*1000);
					this.countDownTimer(countDowntime);
			   }
		});
	}//end timer
	
	
	
	public countDownTimer(countDowntime:any){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			   if(isBrowser){
					let now =	new Date().getTime();
					// Find the distance between now an the count down date
					let time = countDowntime - now;
					let minut = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
					let sec = Math.floor((time % (1000 * 60)) / 1000);
					if(time>0){
						this.remain_time	=	minut+':'+sec+' min left';
						let that	=	this;
						setTimeout(function(){
							that.countDownTimer(countDowntime);
						},1000);
					}else{
						this.remain_time	=	'0:00 min left';
						this.isTimerOn	=	false;
					}
			   }
		});
	}
	
	/**This function are used for get caste data in relgion wise **/
	public religionCasteData(relgionId:any){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				blockUI();
				this.HomeService.getCasteDataSignup(relgionId).then((res: any) => {
					if(res){
						this.masterCast				=	res.data.cast;
						/* if(relgionId == this.env.RELIGION_CHRISTIAN_VALUE){
							let cast_other_value			=	this.env.CAST_OTHER_VALUE;
							const filteredItems 					=   this.masterCast.filter(function(item) {
								return item.id !== cast_other_value
							})
							console.log(filteredItems);
							this.masterCast		=	filteredItems;
						} */
					}
					unblockUI();
				});
			}
		});
	}
	
   ngOnDestroy() {
        this.ngUnsubscribe.next();
   }
  
  
	
}
