import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { FooterService } from "../../../shared/service/footer.service";
import { ValidationService, CommonFunctionsService  } from '../../../../_services/index';
import { Subject } from 'rxjs';
import { UserService } from '../../../../_services/user.service';

import { takeUntil } from 'rxjs/operators';

declare var $:any;

@Component({
  selector: 'recent-update',
  templateUrl: './recentupdate.component.html',
  providers : [CommonFunctionsService,FooterService,ValidationService,UserService]
 
})


export class RecentUpdateComponent implements OnInit, OnDestroy {
	/**This is used to prevent memory leaks **/
	private ngUnsubscribe: Subject<void> = new Subject<void>();	
	
	env	=	environment;

	recentUpdate		:	any		=	[];
	SITE_IMAGE_URL		:   string 	= 	"";

	constructor(public router: Router, private formBuilder: FormBuilder,private commonFunctionsService:CommonFunctionsService,private FooterService: FooterService, public userService : UserService){
		this.SITE_IMAGE_URL = this.env.SITE_IMAGE_URL;
	}

  	ngOnInit() {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.getRecentUpdates();
			}
   		});
		
	}//end ngOnInit()

	
	/**function for get user privacy option */
	public getRecentUpdates(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.commonFunctionsService.getRecentlyUpdates(this.userService.getCurrentUser().slug).then((res:any) => { 
					this.recentUpdate	=	res.data;
				});
			}
		});
	}//end getPrivacyOption()
	 
	ngOnDestroy() {
        this.ngUnsubscribe.next();
    }
	
}
