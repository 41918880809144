export const TextSetting = {
	/********************************GLOBAL TEXTS ***************************************/
		NO_RECORD_FOUND							:	'No Record Found',
		SET_PROFILE_IMAGE_MSG					:	'Your image has been successfully set as a profile image.',
		DELETE_PROFILE_IMAGE_MSG				:	'Your profile image has been deleted successfully.',
		VALID_IMAGE_ERROR_MSG	    			:	'Please upload valid image.',
		DELETE_PROFILE_MSG						:	'Are you sure, you want to delete this profile image. ?',
		PLEASE_SELECT_DAYS	    				:	'Please select days',
		HIDE_PROFILE_MSG						:	'Are you sure, you want to hide your profile.',
		UNHIDE_PROFILE_MSG						:	'Are you sure, you want to unhide your profile.',
		USER_PROFILE_DELETE						:	'Are you sure, you want to delete your profile.',
		REQUEST_PHOTO_MESSAGE					:	'Are you sure, you want to photo request from this member?',
		MEMBER_ALREADY_SELECTED					:	'This member is already selected.',
		MULTIPLE_SHORTLIST_MEMEBER_MESSAGE		:	'Are you sure, you want to shortlist these member?',
		ACCEPT_SELECTED_INVITATION_MSG			:	'Are you sure, you want to accept selected invitations ?',
		REJECT_SELECTED_INVITATION_MSG			:	'Are you sure, you want to reject selected invitations ?',
		ONE_SELECT_INVITATION_ACCEPT_MSG		:	'Please select atleast one invitation for accept.',
		ONE_SELECT_INVITATION_REJECT_MSG		:	'Please select atleast one invitation for reject.',
		SHORTLIST_MEMBER_MESSAGE				:	'Are you sure, you want to shortlist this member?',
		AT_LEAST_SHOTRTLIST_MESSAGE				:	'Please select atleast one member for shortlist.',
		REJECT_INVITATION_MESSAGE				:	'Are you sure, you want to reject this invitation?',
		DELETE_INVITATION_REQUEST_MSG			:	'Are you sure, you want to delete this invitation request ?',
		INVITATION_ALREADY_MSG					:	'This invitation is already selected.',
		LIMIT_EXHAUSTED_MESSAGE					:	'You have exhausted limit of send email or view contact.',
		
	/********************************PAGE_TITLE TEXTS ***************************************/
		HOME_PAGE_TITLE					:	'Overseas Indian Matrimony | Overseas Matrimony',
		HOME_PAGE_DESCRIPTION			:	'Looking for an NRI bride or groom for marriage? OCI Matrimonial provides matchmaking services globally for Non-Resident Indians (NRI). Register now to find your soulmate!',
		LOGIN_PAGE_TITLE				:   'Login',
		CONTACT_US_PAGE_TITLE			: 	'Contact Us',
		CONTACT_US_PAGE_SEO_TITLE		: 	'Overseas Indian Matrimony| Contact Us',
		CONTACT_US_PAGE_DESCRIPTION		:   'Welcome to the scintillating realm of online match making. Where you will not just find brides and grooms but families too.',
		CMS_PAGE_TITLE					:	'Overseas Matrimonial Website | About Us – Ocimatrimonial',
        CMS_PAGE_DESCRIPTION			:   'Welcome to the Overseas Indian matrimonial website, an online platform dedicated to finding your perfect partner.',
		PROFILE_ONE_PAGE_TITLE			:   'Profile one',
		PROFILE_TWO_PAGE_TITLE			:   'Profile two',
		PROFILE_THREE_PAGE_TITLE		:   'Profile three',
		PROFILE_FOUR_PAGE_TITLE			:   'Profile four',
		PROFILE_FIVE_PAGE_TITLE			:   'Profile five',	
		PARTNER_PREFERENCE_PAGE_TITLE	:   'Partner Preference',
		STORY_PAGE_TITLE				:   'Search',
		STORY_DETAILS_PAGE_TITLE		:   'Success Story',
		DASHBOARD_PAGE_TITLE    	    :	'Dashboard',
		DASHBOARD_PROFILE_HELLO   	    :	'Hello!',
		UPGRADE_PLAN_PAGE_TITLE   	    :	'Plan',
		MY_PROFILE_PAGE_TITLE			:	'My Profile',
		MY_ACCOUNT_PAGE_TITLE			:	'My Account',
		ACCOUNT_SETTING_PAGE_TITLE		:	'Account Setting',
		CONTACT_FILTER_PAGE_TITLE		:	'Contact Filter',
		EMAIL_ALERT_PAGE_TITLE			:	'Email Alert',
		PHOTOS_PAGE_TITLE				:	'My Photos',
		PRIVACY_OPTION_PAGE_TITLE		:	'Privacy Option',
		HIDE_PROFILE_PAGE_TITLE			:	'Hide Profile',
		MY_PLAN_PAGE_TITLE				:	'My Plan',
		FAQ_PAGE_TITLE					:	'Faq',
		CHECKOUT_PAGE_TITLE				:	'Checkout',
		INBOX_PAGE_TITLE				:	'Inbox',
		NOTIFICATION_PAGE_TITLE			:	'Notification',
		PREIMIUM_MEMBER_TITLE			:	'Premium-Member',
		BLOCKED_MEMBER					:	'Blocked Members',
		MATCHES							:	'Matches',
		NEWMATCHES						:	'New Matches',
		RECENTVISITOR					:	'Recent Visitor',
		REJECTUSER						:	'Rejected User',
		SHORTLISTUSER					:	'Shortlist User',
		VIEWEDPROFILE					:	'Viewed Profile',
		PREFERED_MATCHES				:	'Preferred Matches',
		DAILY_RECOMMENDATION			:	'Daily Recommendation',
		SEARCH							:	'Search',
		SEARCH_RESULT					:	'Search-Result',
		
	/********************************REQUIRED TEXTS ***************************************/
		REQUIRED_FIELD_MSG				:	'This field is required.',
		VALID_PASSWORD_VALIDATION_MSG	:	'Password must have minimum 8 character and should contain atleast 1 alphabet and 1 number.',

	/********************************DASHBOARD TEXTS ***************************************/	
		UPGRADE_PLAN_MSG				:	'Please choose at least one plan for upgarde.',

	/********************************** Text Settings ****************************************/
	
	AUTHENTICATION_PROFILE_MESSAGE			:	'You are not allowed to perform this action.Please complete your profile first.',
	AUTHENTICATION_MESSAGE					:	'You are not allowed to perform this action.',
	ALREADY_INVITED_MESSAGE					:	'This member has been already invited.',
	BLOCK_MEMEBER_MESSAGE					:	'Are you sure, you want to block this member?',
	UNBLOCK_MEMBER_MESSAGE					:	'Are you sure, you want to unblock this member?',
	CURRENTLY_UNAVIALABLE_MESSAGE			:	'This profile is currently unavailable.',
	IGNORE_MEMBER_MESSAGE					:	'Are you sure, you want to ignore this member?',
	MULTIPLE_IGNORED_MEMBER					:	'Are you sure, you want to ignore selected member ?',	
	AT_LEAST_REJECT_MESSAGE					:	'Please select at least one member for reject.',
    REMOVE_SHORTLIST_MEMBER_MESSAGE			:	'Are you sure, you want to remove this member from shortlist?',	
	UNSHORT_LIST_MULTIPLE_MESSAGE			:	'Are you sure, you want to remove selected member from shortlist ?',
	AT_LEAST_ONE_MEMBER_REMOVE_MESSAGE		:	'Please select at least one member for remove.',
	AT_LEAST_ONE_IGNORED_MEMBER_MESSAGE		:	'Please select at least one member for ignore.',		
	VALID_SIZE_IMAGE_ERROR_MSG				:	'Please upload valid size image. Maximum uploaded file size',
	ACCOUNT_VERIFIED_MESSAGE				:	'Your account is not verified by admin. So you are not allowed to perform this action. Please contact to site admin for more detail.',
	CONTACT_NAME_ERROR_MSG	    			:	'Please enter your name.',
	CONTACT_EMAIL_ERROR_MSG	    			:	'Please enter your valid email address.',
	CONTACT_PHONE_ERROR_MSG	    			:	'Please enter your valid phone number.',
	CONTACT_SUBJECT_ERROR_MSG				:	'Please enter your subject.',
	CONTACT_MESSAGE_ERROR_MSG				:	'Please enter your message.',
	CONTACT_CONTACTUS_ERROR_MSG				:	'Contact Us',
	COMPLETE_PROFILE_MSG					:	'You are not allowed to perform this action.Please complete your profile first.',
	ONE_SELECT_INVITATION_REMOVE_MSG		:	'Please select atleast one invitation for remove.',
	ONE_SELECT_REVOKE_MSG					:	'Please select atleast one photo request for revoke.',
	REQUEST_ALREADY_SELECT					:	'This request is already selected.',
	ONE_MESSAGE_REMOVE_SELECT				:	'Please select atleast one message for remove.',
	ONE_START_SELECT						:	'Please select atleast one message for star.',
	SEND_MESSAGE_UPGRADE_PLAN				:	'You are not allowed to send message to this user. Please upgrade your plan first.',
	ONE_SELECT_INVITATION_RESTORE_MSG		:	'Please select atleast one invitation for restore.',
	INVITATION_DELETE_MSG					:	'Please select atleast one invitation for delete.',
	ONE_PHOTO_REQUEST_ACCEPT_MSG			:	'Please select atleast one photo request for accept.',
	ACTIONS_MEMBERS_MSG						:	'You can not perform any action to these members.',
	ONE_SELECT_INVITATION_RESEND_MSG		:	'Please select atleast one invitation for resend.',
	ONE_PHOTO_DELETE_MSG					:	'Please select atleast one photo request for delete.',
	CHAT_STATUS_MSG							:	'Your chat status is offline.Please change your chat status for chating',
	VALID_EMAIL_ADDRESS_MSG					:	'Please enter valid email address.',
	VALID_PASSWORD_MSG						:	'Please enter password.',
	VALID_PHONE_MSG							:	'Please enter your phone number.',
	VALID_PHONE_NUMBER_VALIDATION_MSG		:	'Please enter valid phone no.',
	PASSWORD_NOT_MATCH_MSG					:	'Passwords do not match.',
	VERIFIED_EMAIL_MSG						:	'Either your email or mobile not verified. Please verify them to continue.',
	INTERNET_CONNECTION_MSG					:	'Please check your internet connection.',
	LOGOUT_MSG								:	'you have successfully logged out.',
	MAZ_MIN_SALARY							:	'Please select maximum annual salary greater than or equal to minimum salary.',
	CURRENCY_MSG							:	'Please select currency.',
	MAXIMUM_INCOME_MSG						:	'Please select maximum income.',
	MARITAL_STATUS_MSG						:	'Please select marital status.',
	CHILDREN_STATUS_MSG						:	'Please select children status.',
	ALL_FIELD_MARKS							:	'Please fill all the fields marked by Asterisk (*) in order to proceed further.',
	CURRENCY_TYPE_MSG						:	'Please select income currency type.',
	ENTER_INCOME_MSG						:	'Please enter income.',
	SEARCH_RECORD_DELETE_MSG				:	'Are you sure, you want to delete this saved search.',
	SEARCH_LIMIT_MSG						:	'Your saved search limit has been exceeded. If you want to save this search, please remove the previous saved search.',
	PHOTO_REQUEST_MEMBER_MSG				:	'Are you sure, you want to photo request this member?',
	MEMBER_SHORTLIST_MSG					:	'Please select at least one member for shortlist.',
	MEMBER_REJECT_MSG						:	'Are you sure, you want to reject this member?',
	SELECT_MEMBER_REJECT_MSG				:	'Are you sure, you want to reject selected member ?',
	MEMBER_UNBLOCK_MSG						:	'Are you sure, you want to unblock these member?',
	ONE_MEMBER_UNBLOCK_MSG					:	'Please select atleast one member to unblock.',
	MEMBER_IGNORE_MSG						:	'Are you sure, you want to remove this member from ignore list?',
	ONE_MEMBER_IGNORE_MSG					:	'Please select atleast one member for ignore.',
	PAYMENT_CANCEL_MSG						:	'Are you sure, you want to cancel the payment.',
	REMOVE_SELECT_INVITATION__MSG			:	'Are you sure, you want to remove selected invitations ?',
	REVOKE_PHOTO_REQUEST_MSG				:	'Are you sure, you want to revoke this photo request ?',
	REVOKE_SELECTED_PHOTO_REQUEST_MSG		:	'Are you sure, you want to revoke selected photo requested ?',
	REMOVE_SELECTED_MESSAGE_MSG				:	'Are you sure, you want to remove selected messages ?',
	STARTRED_SELECTED_MESSAGE_MSG			:	'Are you sure, you want to starred selected messages ?',
	RESTORE_INVITATION_MESSAGE_MSG			:	'Are you sure, you want to restore this invitation request ?',
	RESTORE_SELECTED_INVITATION_MESSAGE_MSG	:	'Are you sure, you want to restore selected invitations ?',
	DELETE_SELECTED_INVITATION_MESSAGE_MSG	:	'Are you sure, you want to delete selected invitations ?',
	ACCEPT_SELECTED_PHOTO_REQUEST_MSG		:	'Are you sure, you want to accept selected photo request ?',
	DELETE_MSG								:	'Are you sure, you want delete the message ?',
	ACCEPT_PHOTO_REQUEST_MSG				:	'Are you sure, you want to accept this photo request?',
	REJECT_PHOTO_REQUEST_MSG				:	'Are you sure, you want to reject this photo request?',
	REJECT_SELECTED_PHOTO_REQUEST_MSG		:	'Are you sure, you want to reject selected photo requests ?',
	RESEND_INVITATION_REQUEST_MSG			:	'Are you sure, you want to resend this invitation request ?',
	RESEND_SELECTED_INVITATION_REQUEST_MSG	:	'Are you sure, you want to resend selected invitations ?',
	RESEND_SELECTED_PHOTO_REQUEST_MSG		:	'Are you sure, you want to resend selected photo requested ?',
	DELETE_PHOTO_REQUEST_MSG				:	'Are you sure, you want to delete this photo request ?',
	DELETE_SELECTED_PHOTO_REQUEST_MSG		:	'Are you sure, you want to delete selected photo requested ?',
	ERROR_404								:	'ERROR 404',
	ERROR_DESCRIPTION						:	'Oopss... this is embarassing, either you tried to access a non existing page, or our server has gone crazy.',
	ERROR_CLICK_HERE						:	'<a href="#">Click Here</a> to go back home',
	MAILTOTEXTSTART							:	'mailto:?subject=Please check this profile on iosmatrimoni.com&body=Hi,%0D%0APlease check the below profile on iosmatrimoni.com.%0D%0A',	
	MAILTOTEXTEND							:	'%0D%0ARegards,%0D%0A%0D%0A',	
};
