import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from './modules/shared/shared.module';
import { HomeModule } from './modules/home/home.module';
import { Select2Module } from 'ng2-select2';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './token-interceptor.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedService } from "./modules/shared/service/shared.service";
import { CommonFunctionsService } from './_services/commonfunctions.service';
import { Error404Component } from './modules/error404/error404.component';
import { GlobalApp } from './_helpers/index';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { UserService } from './_services/user.service';
import { SocialLoginModule, AuthServiceConfig } from "angular4-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angular4-social-login";

import * as bootstrap from "bootstrap";
import * as $ from "jquery";

import { AuthGuard,ProfileCompleteUserGuard } from './guards/index';



/***********Home page routing ************/
const rootRouting: ModuleWithProviders = RouterModule.forRoot([
	  { path: 'pages', loadChildren: './modules/cms/cms.module#CmsModule'},
	  { path: 'checkout', loadChildren: './modules/checkout/checkout.module#CheckoutModule' },
	  { path: 'contact-us',loadChildren: './modules/contact/contact.module#ContactModule' },
	  { path: 'profile', loadChildren: './modules/profile/profile.module#ProfileModule' },
	  { path: 'story', loadChildren: './modules/story/story.module#StoryModule' },
	  { path: 'dashboard', loadChildren: './modules/dashboard/dashboard.module#DashboardModule' },
	  { path: 'upgrade-plan', loadChildren: './modules/plan/plan.module#PlanModule' },
	  { path: 'my-profile', loadChildren: './modules/userprofile/userprofile.module#UserProfileModule' },
	  { path: 'account-setting', loadChildren: './modules/accountsetting/accountsetting.module#AccountSettingModule' },
	  { path: 'faq', loadChildren: './modules/faq/faq.module#FaqModule'},
	  { path: 'inbox', loadChildren: './modules/inbox/inbox.module#InboxModule'},
	  { path: 'notification', loadChildren: './modules/notification/notification.module#NotificationModule'},
	  { path: 'users', loadChildren: './modules/user/user.module#UserModule' },
	  { path: 'matches', loadChildren: './modules/matches/matches.module#MatchesModule' },
	  { path: 'search', loadChildren: './modules/search/search.module#SearchModule' },
	  { path: 'search-result', loadChildren: './modules/searchresult/searchresult.module#SearchResultModule' },
      { path: '**', component: Error404Component },
]);


let socialconfig = new AuthServiceConfig([
	{
		id: GoogleLoginProvider.PROVIDER_ID,
		provider: new GoogleLoginProvider("244219274577-ccgj2clkqfs75nvehe33k5gbiggi51p5.apps.googleusercontent.com")
	 },
	{
		id: FacebookLoginProvider.PROVIDER_ID,
		provider: new FacebookLoginProvider("174609603933046")
		
	}
]);

export function provideConfig() {
	return socialconfig;
  }

@NgModule({
  declarations: [
    AppComponent,
	Error404Component,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    SharedModule,
    HomeModule,
    rootRouting,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
	BrowserAnimationsModule,
	Select2Module,
    CommonModule,
	Ng2TelInputModule,
	SocialLoginModule,
	GooglePlaceModule
  ],
  providers: [
    GlobalApp,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
	{
		provide: AuthServiceConfig,
	    useFactory: provideConfig
	},
    SharedService,CommonFunctionsService,UserService,
	AuthGuard,ProfileCompleteUserGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
