// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const SITE_BASE_URL		=	"https://www.overseasindianmatrimony.com/";
export const environment = {
  production: false,
  API_URL									            : "https://admin.overseasindianmatrimony.com/api/",
  SITE_TITLE 						    	        : 'Ocimatrimonial', 
  SITE_DESCRIPTION							 	    : 'Ocimatrimonial',
  SITE_KEYWORDS								    	: 'Ocimatrimonial',
  SITE_URL									        : SITE_BASE_URL,
  SITE_URL_WITHOUT_SLASH								: "https://www.overseasindianmatrimony.com",
  NODE_URL											:	"https://chat.overseasindianmatrimony.com",
  CHAT_AUDIO_URL									:	SITE_BASE_URL+'assets/ring.mp3',
  CHAT_ALLOWED_WINDOW_COUNT_WEB						:	3,
  CHAT_STATUS_MSG									:	'Your chat status is offline.Please change your chat status for chating',
  PAGE_TITLES							            : {'HOME':'Home'},
  SITE_IMAGE_URL						  	      : "/assets/images/",
  ERROR_404								            : 'ERROR 404',
  ERROR_DESCRIPTION						        : 'Oopss... this is embarassing, either you tried to access a non existing page, or our server has gone crazy.',
  ERROR_CLICK_HERE						        : '<a href="#">Click Here</a> to go back home',
  UPLOAD_IMAGE_ERROR_MSG				      : 'Please upload image.',
  MAX_FILE_UPLOAD_LIMIT					      : 100,
  VALID_SIZE_IMAGE_ERROR_MSG			    : 'Please upload valid size image. Maximum uploaded file size',
  ARE_YOU_SURE								        : 'Are You Sure',
  DISPLAY_DATE_FORMATE					      : 'MM-dd-yyyy',
  CURRENCY_CODE		    					      : 'USD',
  NETWORK_ERROR								        : 'Please check your internet connection.',
  SUCCESS_STATUS							        : 'success',
  CONTACTUS_PAGE_TITLE						    : 'Contact-Us',
  INVALID_ACCESS_ERROR						    : 'invalid_access_error',
  SUCCESS_CONSTANT         		   	    : 'success',
  ERROR_CONSTANT                 	    : 'error',
  VALID_EMAIL_ADDRESS_MSG 				    : "Please enter email address.",
  VALID_PASSWORD_MSG   						    : "Please enter password.",
  VALIDATE_EMAIL_ADDRESS_MSG			    : 'Please enter valid email address.',
  VALID_PASSWORD_VALIDATION_MSG		    :  'Password must have minimum 8 character and should contain atleast 1 alphabet and 1 number.',
  RESEND_MAIL_TIME_IN_SECOND			    :  90,
  VALID_PHONE_NUMBER_VALIDATION_MSG		:  'Please enter valid phone no.',
  PASSWORD_NOT_MATCH_MSG					    :  'Passwords do not match.',
  LOGOUT_MSG							            : 'you have successfully logged out.',
  REQUIRED_FIELD_MSG						      : 'This field is required.',
  CURRENCY_TYPE_MSG							      : 'Please select income currency type.',
  ENTER_INCOME_MSG						        : 'Please enter income.',
  AUTHENTICATION_MESSAGE					    :  'You are not allowed to perform this action.',
  PROFILE_ONE_URL						  	      :	'/profile',
  PROFILE_TWO_URL							        :	'/profile/profile-two',
  PROFILE_THREE_URL							      :	'/profile/profile-three',
  PROFILE_FOUR_URL							      :	'/profile/profile-four',
  PROFILE_FIVE_URL							      :	'/profile/profile-five',
  PARTNER_PREFERENCE_URL					    : '/profile/partner-preference',
  MAZ_MIN_SALARY							        :	'Please select maximum annual salary greater than or equal to minimum salary.',
  CURRENCY_MSG								        :	'Please select currency.',
  MAXIMUM_INCOME_MSG						      :	'Please select maximum income.',
  MARITAL_STATUS_MSG						      :	'Please select marital status.',
  CHILDREN_STATUS_MSG						      :	'Please select children status.',
  ALL_FIELD_MARKS						        	:	'Please fill all the fields marked by Asterisk (*) in order to proceed further.',
  LOAD_MORE_LIMIT_SUCCESS_STORY				:	6,
  STORY_TITLE_TEXT_LENGTH             : 20,
  STORY_CONTENT_TEXT_LENGTH           : 30,
  SUCCESS_STORY_SLIDER_LIMIT	        :	5,
  VALID_IMAGE_EXTENSION_MSG	          :	'Please select valid image.valid extension are jpeg,jpg and png.Please upload the image of 1200*675.',
  INVALID_ACCESS_URL				          : 'You are not allowed to perform this action.Please login first.',
  COMPLETE_PROFILE_MSG				        : 'You are not allowed to perform this action.Please complete your profile first.',
  REJECT_PROFILE_MSG						:	'Your profile is rejected due to some reasons, which had been shared via email. Please fill your details once again in order to continue.',
  FREE_PLAN_ID                        : 12,
  SILVER_PLAN_ID                      : 13,
  GOLD_PLAN_ID                        : 14,
  PLATINUM_PLAN_ID                    : 15,
  ELITE_SERVICES_PLAN_ID              : 16,
  RECENT_UPDATE_LIMIT			            : 2,
  ACCOUNT_VERIFIED_MESSAGE			      : 'Your account is not verified by admin. So you are not allowed to perform this action. Please contact to site admin for more detail.',
  PAYMENT_CANCEL_MSG				          : 'Are you sure, you want to cancel the payment.',
  PYPAL_API_KEY         						  :	'AZH-idEcfO6ncWslSlTAUp_JPIt7I1PL-cp8pmNThoR7qak01kYa0AuJJ2wjbXfdHWGcylXXiL9aEuuU',
  DEFAULT_CURRENCY			        	 	  : 'USD',
  DEFAULT_PAYPAL_CURRENCY			          :'AUD',
  RELIGION_OTHER_VALUE                		  : 120,
  CAST_OTHER_VALUE                    		  : 23,
  SUB_CAST_OTHER_VALUE                		  : 23,
  MOTHER_TONG_OTHER_VALUE             		  : 127,
  APPERANCE_SIKH_VALUE                		  : 119,
  DESCRIPTION_LIMIT					   		  :70,
  RELIGION_HINDHU_VALUE						  :114,
  RELIGION_CHRISTIAN_VALUE					  :113,
  OCCUPATION_OTHER_VALUE					  :36,
  EDUCATION_OTHER_VALUE						  :145,
   AFTER_SKIP_MSG							  :'You need to Login again with your Login crednetials.Thank you!!!.', 
   VERIFIED_EMAIL_MSG						  :'Either your email or mobile not verified. Please verify them to continue.', 
   DELETE_PROFILE_RESONS					  : [{id:'',text:'Please select reason'},{id :'1', text : 'We have not found any matching profile.'},{id :'2', text : 'We found an alliance through this web site and would like to give a testimonial/review.'}], 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
