import { Component, OnInit,EventEmitter,Inject, PLATFORM_ID,OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { RouterModule, Routes, Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ValidationService } from '../../../../_services/index';
import { UserService } from '../../../../_services/user.service';
import {GlobalApp} from '../../../../_helpers/index';
import { isPlatformBrowser } from '@angular/common';
import { TextSetting } from '../../../../textsetting';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SeoService } from '../../../../_services/seo.service';
import {CommonFunctionsService} from '../../../../_services/index';

declare var $:any;

@Component({
  selector: 'app-verification',
  template: '',
})

export class VerificationComponent implements OnInit,OnDestroy {
	
	private ngUnsubscribe:Subject<any> = new Subject();	
	
	/*Dclear the variable constant */
	verificationStatus			:	any		=	{};
	validateString				:	string 	=	''; 
	env							=	environment;

	constructor(
		private route: ActivatedRoute,
		public router: Router,
		private formBuilder: FormBuilder,
		public commonFunctionsService : CommonFunctionsService,
		private userService: UserService
	) { }

  	ngOnInit() {
		/*********** This is used for validate the login form ************/	
		this.verifyEmail();
  	}
	
	/**function for submit forgot password*/
	public verifyEmail(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
				if(isBrowser){
					blockUI();
					this.validateString = this.route.snapshot.params['validatestring'];
					 this.commonFunctionsService.verifyAccount(this.validateString).then((res: any) => {
						if(res.data.status == this.env.SUCCESS_CONSTANT){
							showSuccessMessageTopRight(res.data.message);
						}else{
							showErrorMessageTopRight(res.data.message);
						}
						unblockUI();
						 if(this.userService.getCurrentUser() != 'undefined' && this.userService.getCurrentUser() != null){
								this.userService.updateCurrentUserData();
								this.router.navigate(['/dashboard']);
						 }else{
							this.router.navigate(['/']);
						}
					});
				}
		});
	}
	
	ngOnDestroy() {
        this.ngUnsubscribe.next();
    }
}
