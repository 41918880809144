import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/map';

@Injectable()
export class ResetPasswordService {
	
  /**define valiables */
	SITE_API_URL			:	any		=	'';
	data					:	any		=	{};
	forgotPasswordOtpUrl    :	any		=	{};
	siteSettingUrl			:	any		=	{};
	submitOtpUrl			:	any		=	{};
	resendOtpUrl			:	any		=	{};
	
  /**Define constructor*/	
  constructor(private httpClient:HttpClient,public SharedService : SharedService) { 
	this.SITE_API_URL		=	environment.API_URL;
  }
  
  /**This function are used for submit the get OTP for reset password **/
  getOtpForResetPassword(formData:any){
	  this.forgotPasswordOtpUrl						=	this.SITE_API_URL;
	  this.data['method_name']						 = "forgotPassword";
	  this.data['data'] 							 =  formData;
	  return this.SharedService.getPost(this.forgotPasswordOtpUrl,this.data);	
  }
  
  /** This function are used for submit the otp form **/
  submitOTPForm(formData:any,method_name:string){
		this.submitOtpUrl							=	this.SITE_API_URL;
		this.data['method_name']					=	 method_name;
		this.data['data'] 							=   formData;
	    return this.SharedService.getPost(this.submitOtpUrl,this.data);	
  }
  
  
   /** This function are used for resend otp **/
   resendOTP(formData:any,method_name:string){
		this.resendOtpUrl							=	this.SITE_API_URL;
		this.data['method_name']					=	 method_name;
		this.data['data'] 							=   formData;
		return this.SharedService.getPost(this.resendOtpUrl,this.data);	 
   }
  
  /* 
	* Function to submit resetPassword form on server & get response
	* @params user :- form instance
	*/
	resetPassword(formData:any) { 
		this.resendOtpUrl							=	this.SITE_API_URL;
		this.data['method_name']					=	'resetPassword';;
		this.data['data'] 							=   formData;
		return this.SharedService.getPost(this.resendOtpUrl,this.data);	
	}
	
	
	/**This function are used for newsletter unsubsciber **/
	newsletterUnSubscriber(validateString:any){
		this.data['method_name']					=	'newsletterUnsubscribe';
		this.data['data'] 							=   validateString;
		return this.SharedService.getPost(this.SITE_API_URL,this.data);	
	}
  
  
	
  /**handler function added **/ 
  private handleError (error: Response | any) {
		console.error(error.message || error);
		return Promise.reject(error.message || error);
   }
}


