import { Injectable,Inject, PLATFORM_ID  } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject ,  ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map ,  distinctUntilChanged } from 'rxjs/operators';
import { User } from '../core/user.model';
import { environment } from '../../environments/environment';
import { SharedService } from "../modules/shared/service/shared.service";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {CommonFunctionsService} from '../_services/index';

declare var $:any;


@Injectable()
export class UserService {
	private ngUnsubscribe: Subject<void> = new Subject<void>();	
	private currentUserSubject = new BehaviorSubject<User>({} as User);
	public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  
	private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
	public isAuthenticated = this.isAuthenticatedSubject.asObservable();

	
  SITE_API_URL: string 		= "";
  data						:	any		=	{};
  env						=	environment;
  private profileUrl 		: any;

  constructor (
     private SharedService : SharedService,
    private http: HttpClient,
	public commonFunctionsService : CommonFunctionsService,
	@Inject(PLATFORM_ID) private platformId: Object
  ) {
	  this.SITE_API_URL		=	environment.API_URL;
	  
	  
  }
  

	/* 
	* Function to get logged in user detail
	*/
	getCurrentUser() {
		//return this.currentUserSubject.value;
		if(isPlatformBrowser(this.platformId)) {
			let  currentUser = JSON.parse(localStorage.getItem('userData'));
			if (typeof currentUser != 'undefined' && currentUser != null) {		    			
				return currentUser;
				}
			else
			{
				return currentUser;
			}	
		}else{
			let currentUser = [];
			return currentUser;
		}
	}
  	/* 
	* Function to update loggedin user details
	*/
	
  /* 
	* Function to update loggedin user details
	*/
  	updateCurrentUserData(){
		let currentUser = JSON.parse(localStorage.getItem('userData'));
		if (typeof currentUser != 'undefined' && currentUser != null) {
			let slug					=	currentUser['slug'];
			this.commonFunctionsService.updateCurrentUserData(slug).then((res: any) => {
				if(res.data.status == this.env.SUCCESS_STATUS)
				{
					let loginuserData	=	res.data.userData;
					localStorage.setItem('userData',JSON.stringify(loginuserData));
					setTimeout(function(){
						$('#userDataUpdated').trigger('click');
					},1000);
				}
			}); 
		}
    }
	
	
	 
   /** Function to get Loggedin User data */
   getLoginUserData(){
	  if(this.getCurrentUser() != 'undefined' && this.getCurrentUser() != null){
		let loginUserSlug	=	this.getCurrentUser().slug;
		this.data["method_name"]		=  'getProfileDetail';
		this.data["data"]				=	{};
		this.data["data"]['slug']		=	loginUserSlug;
		return this.SharedService.getPost(this.SITE_API_URL,this.data);
		}
		
	}
	
	
	
	
	// Verify JWT in localstorage with server & load user's info.
	// This runs once on application startup.
	populate() {
		// If JWT detected, attempt to get & store user's info
		if(isPlatformBrowser(this.platformId)) {
			let  currentUser = JSON.parse(localStorage.getItem('userData'));
			if (typeof currentUser != 'undefined' && currentUser != null) {		    			
				this.setAuth(currentUser);
				
			} else {
				// Remove any potential remnants of previous auth states
				this.purgeAuth();
			}
		}
	}

	/**function is used to set local storage */
    setAuth(user: User) {
		localStorage.setItem('userData',JSON.stringify(user));
		// Set current user data into observable
		this.currentUserSubject.next(user);
		// Set isAuthenticated to true
		this.isAuthenticatedSubject.next(true);
	}
	/**function is used to remove local storage */
	purgeAuth() {
		// Remove user data from localstorage
		localStorage.removeItem('userData');
		localStorage.removeItem('token');
		localStorage.removeItem('refreshToken');
		// Set current user to an empty object
		this.currentUserSubject.next({} as User);
		// Set auth status to false
		this.isAuthenticatedSubject.next(false);
	}
	  
	  
	/**This function are used for change the password **/
	submitUpdateForm(data:any){
		return this.SharedService.getPost(this.SITE_API_URL,data);
	}
	
	/**This function are used for change the password **/
	getUserdata(slug:any){
		this.data.slug = slug;
		this.data.method_name = "getUserInfo";
		return this.SharedService.getPost(this.SITE_API_URL,this.data);
	}
	
	/**This function are used for upadte the profile image **/
	updateProfileImage(data:any){
		data['method_name']	=	"editUserProfileImage";
		return this.SharedService.getPostImage(this.SITE_API_URL,data);
	}
	
	/**function is used to set token on local storage */
	setToken(token: any){
		localStorage.setItem('token',JSON.stringify(token));	
	}
		
	/**function is used to set refresh token on local storage */
	setRefreshToken(refreshToken: any){
		localStorage.setItem('refreshToken',JSON.stringify(refreshToken));	
	}	

	 /*** function for send photo request **/
	sendphotoRequest(sender:any,receiver:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'send-photo-request/'+sender+'/'+receiver);
	}

	/**function for shortlisted member */
	shortListMember(slug:any,shortListUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'short-list-user/'+slug+'/'+shortListUserSlug);
	}

	/** Function to submit invitation form */
	submitInvitationForm(invitationForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'invite-user',invitationForm);
	}

	/** Function to submit email form */
	submitEmailForm(emailForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'send-email',emailForm);
	}

	/** function for submit Invitation Accept form */
	submitInvitationAcceptForm(invitationAcceptForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'accept-invitation',invitationAcceptForm);
	}


	/**function for reject invitation */
	rejectInvitation(slug:any,rejectUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'reject-invitation/'+slug+'/'+rejectUserSlug);
	}
	
	/**function for checking photo access password */
	submitPhotoAccessPassword(formData: any){
		return this.SharedService.getPost(this.SITE_API_URL+'protect-photo-password',formData);
	}

	forgotProtectPhotoPassword(sender:any, receiver:any){
			return this.SharedService.getRequest(this.SITE_API_URL+'forgot-protect-photo-password/'+sender+'/'+receiver);
	}

	  
}
