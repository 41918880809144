import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';
import 'rxjs/add/operator/map';

@Injectable()
export class FooterService {
	
  	/**define valiables */
	SITE_API_URL		:	any		=	'';
	data				:	any		=	{}; 
	SITE_URL			:	any		=	'';
  	/**Define constructor*/	
  	constructor(private httpClient:HttpClient,public SharedService : SharedService) { 
		this.SITE_API_URL		=	environment.API_URL;
		this.SITE_URL		=	environment.SITE_URL;
  	}
  
  	/** Function to submit Newsletter form */
  	submitNewsletterForm(formData:any) {
		this.data['method_name'] = "newsletterSubscription";
		this.data		 =  formData;
		

		return this.SharedService.getPost(this.SITE_API_URL+"newsletter-subscribe",formData);
	} 


	  
	/** Function to submit Newsletter form */
	getNotificationList(slug:any) {
		this.data['method_name'] 	= "getNotificationCountAndList";
		this.data['data'] 		 	=  {};
		this.data['data']['slug'] 	=  slug;
		return this.SharedService.getPost(this.SITE_API_URL,this.data);
	}
	
	/** Function to submit Newsletter form */
	getMessageList(slug:any) {
		this.data['method_name'] 	= "getNotificationCountAndList";
		this.data['data'] 		 	=  {};
		this.data['data']['slug'] 	=  slug;
		return this.SharedService.getPost(this.SITE_API_URL,this.data);
	} 
  
	
	
  	/**handler function added **/
  	private handleError (error: Response | any) {
		console.error(error.message || error);
		return Promise.reject(error.message || error);
   }
   
   /*This function are used for fetch the master data */
    getChatUserData(slug:any){
        return this.SharedService.getRequest(this.SITE_API_URL+'get-chat-users/'+slug);
    }


    /*This function are used for get online chat user */
    getOnlineChatUserData(slug:any){
        return this.SharedService.getRequest(this.SITE_API_URL+'get-online-chat-users/'+slug);
    }

    getChatUserProfileData(slug:any,loginUserSlug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-profile-chat-users/'+slug+'/'+loginUserSlug);
	}
	
	/*This function are used for fetch the sub cast */
	getUserBasicInfo(userid:any,loginUser:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-user-basic-info-for-chat/'+userid+'/'+loginUser);
	}
	/*This function are used for fetch the sub cast */
	getChatInitiated(relation:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-chat-initiated/'+relation);
	}
	
	/*This function are used for fetch the sub cast */
	getAbuseWords(){
		return this.SharedService.getRequest(this.SITE_URL+'assets/badwords.json');
	}

	updateOnlineStatusData(slug:any,selected_value:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'update-online-status/'+slug+'/'+selected_value);
	}
	
	/** Function to submit contact form */
	getUserIds(form:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'get-user-ids',form);
	}
}


