import { Directive, ElementRef , HostListener, Input } from '@angular/core';
import {UserService} from '../_services/user.service';
import { UserActionService } from '../modules/user/service/user.service';
import { Router} from '@angular/router';
import { environment } from '../../environments/environment';
import { TextSetting } from '../textsetting';


declare var $:any;
declare var bootbox:any;

@Directive({
  selector: '[appRejectmember]',
   providers: [UserService,UserActionService]
})
export class RejectmemberDirective {
	
TextSetting 								=   TextSetting;
userData							:any	=	this.userService.getCurrentUser();
	
@Input('currentIndex') currentIndex: string;
@Input('RejectedUserSlug') RejectedUserSlug: string;
@Input('userDetail') userDetail: any;
@Input('pageName') pageName: any;

 constructor(private el: ElementRef,public router: Router,public userService: UserService,public UserActionService:UserActionService) { }
  
  
@HostListener('click') onClick() {
	this.rejectlistMember();
}

/**function for rejectList member */
	public rejectlistMember(){
		let userSlug	=	this.userData.slug;
		let that	=	this;
		bootbox.confirm(that.TextSetting.IGNORE_MEMBER_MESSAGE,
			function(result){
				if(result){
					blockUI();
					that.UserActionService.rejectListMember(userSlug,that.RejectedUserSlug).then((res: any) => {
						if(res.data.success == true){
							showSuccessMessageTopRight(res.data.message);
							if(that.pageName == 'user_page'){
									that.userDetail[that.currentIndex]['rejected']	=	1;
							}else{
									that.userDetail.splice(that.currentIndex,1);
							}
							$('#leftCounterChange').trigger('click');
						}else{
							showErrorMessageTopRight(res.data.message);
						}
					});
					unblockUI();
				}
			}
		);
	}
}
